<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Pricelistitem>
  onSubmit: (event: FormSubmitEvent<Partial<Pricelistitem>>) => void
  title?: string
  disabledFields?: (keyof Pricelistitem | string)[]
  pricelistType: Pricelist['type']
  optionsItemsFiltered?: Option[]
  readonly?: boolean
}>()

const route = useRoute()
const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Pricelistitem>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getItem,
  getDropdownItems,
} = usePricelistitems()

const utils = usePricelistitemsUtils({})

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const onSubmitPricelistitem = async (event: FormSubmitEvent<Partial<Pricelistitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.pricelistitems"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitPricelistitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            v-if="['products'].includes(pricelistType)"
            label="Item (producto)"
            name="productitemId"
            help="Selecciona un item aun en la lista de precios"
            :required="isRequired(schema, 'productitemId')"
          >
            <SelectMenuBaseInfo
              v-model="state.productitemId"
              :options="optionsItemsFiltered"
              autofocus
              :disabled="disabledFields?.includes('productitemId')"
            />
          </UFormGroup>
          <UFormGroup
            v-else-if="['services'].includes(pricelistType)"
            label="Item (servicio)"
            name="serviceitemId"
            help="Selecciona un item aun en la lista de precios"
            :required="isRequired(schema, 'serviceitemId')"
          >
            <SelectMenuBaseInfo
              v-model="state.serviceitemId"
              :options="optionsItemsFiltered"
              :disabled="disabledFields?.includes('serviceitemId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Moneda"
            name="currency"
            :required="isRequired(schema, 'currency')"
          >
            <SelectMenuBaseInfo
              v-model="state.currency"
              :options="utils.options.currency"
              :disabled="disabledFields?.includes('currency')"
            />
          </UFormGroup>

          <UFormGroup
            label="Precio base"
            name="basePrice"
            :required="isRequired(schema, 'basePrice')"
          >
            <InputCurrency
              v-model="state.basePrice"
              icon
              :disabled="disabledFields?.includes('basePrice')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `pricelistitems/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppPricelistitemsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.pricelistitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
