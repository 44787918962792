<script setup lang="ts">
import type { BlobObject } from '@nuxthub/core'

const props = defineProps<{
  file: BlobObject
  label?: string // overrides default label
  hideLabel?: boolean
  onRemove?: (file: BlobObject) => void | Promise<void> // close button shown if props.onRemove is defined
}>()

const fileProps = getFileProps(props.file)
</script>

<template>
  <UButton
    variant="solid"
    color="gray"
    size="2xs"
    v-bind="{ ...fileProps, label: hideLabel ? undefined : fileProps.label }"
    :title="fileProps.label"
    class="w-fit max-w-full"
  >
    <template
      v-if="onRemove && !hideLabel"
      #trailing
    >
      <UButton
        color="red"
        variant="ghost"
        size="2xs"
        icon="i-mdi-close"
        class="py-0 pr-0"
        @click.stop="onRemove(file)"
      />
    </template>
  </UButton>
</template>
