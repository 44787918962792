<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { parseISO } from 'date-fns'

const props = defineProps<{
  data: Partial<Fulfillmentorder>
  onSubmit: (event: FormSubmitEvent<Partial<Fulfillmentorder>>) => void
  title?: string
  disabledFields?: (keyof Fulfillmentorder | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Fulfillmentorder>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileFulfillmentorderPdf,
} = useFulfillmentorders()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $productionorders = await useFetch<Productionorder[]>('/api/db/productionorders', {
  query: { },
  default: () => [],
})
const $salesorders = await useFetch<Salesorder[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useFulfillmentordersUtils({ $accounts, $branches, $contacts, $productionorders, $salesorders, $users })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.fulfillmentorders,
      defaultOpen: true,
    },
    {
      slot: 'delivery',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateSalesorderId = async (id: string) => {
  const salesorder = utils.maps.salesorderId.value.get(id)
  if (salesorder) {
    const {
      type,
      isDatetime,
      date,
      ...delivery
    } = salesorder.delivery

    state.value.name = salesorder.name
    state.value.accountId = salesorder.accountId
    state.value.branchId = salesorder.branchId
    state.value.contactId = salesorder.contactId
    state.value.delivery = delivery
  }
}

const informationSalesorder = computed(() => {
  const salesorder = utils.maps.salesorderId.value.get(state.value.salesorderId)
  if (!salesorder) return []
  const user = salesorder?.user?.name
  const account = salesorder?.account?.name
  const localDate = parseISO(salesorder.delivery?.date)
  const delivery = !salesorder.delivery?.isDatetime ? localDate.toLocaleDateString() : localDate.toLocaleString()
  return [
    ['Vendedor', user],
    ['Cliente', account],
    ['Fecha de entrega', delivery],
  ]
})

watch(state.value.type, () => {
  state.value.salesorderId = undefined
  state.value.accountId = undefined
  state.value.branchId = undefined
  state.value.contactId = undefined
  state.value.delivery = {
    incoterm: null,
    useAccountAddress: false,
    address: null,
    text: null,
  }
})

const loading = ref(false)

const onSubmitFulfillmentorder = async (event: FormSubmitEvent<Partial<Fulfillmentorder>>) => {
  loading.value = true
  if (event.data.type !== 'external') delete event.data.salesorderId
  await props.onSubmit(event)
  router.push(`/${moduleId.value}/fulfillmentorders/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.fulfillmentorders"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitFulfillmentorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'external'"
            label="Orden de venta"
            name="salesorderId"
            :required="isRequired(schema, 'salesorderId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.salesorderId"
                  :options="utils.options.salesorderId.value"
                  :disabled="disabledFields?.includes('salesorderId')"
                  class="flex-1"
                  @update:model-value="onUpdateSalesorderId"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
              <ButtonRefresh
                toast="Los campos relacionados a la orden de venta se han actualizado correctamente."
                @click="onUpdateSalesorderId(state.salesorderId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.type === 'external' && state.salesorderId"
            title="Información asociada a la orden de venta"
            :default-open="true"
            :entries="informationSalesorder"
          />

          <UFormGroup
            label="Orden de producción"
            name="productionorderId"
            :required="isRequired(schema, 'productionorderId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-if="state.type === 'external'"
                v-model="state.productionorderId"
                :options="utils.maps.productionordersBySalesorderId.value.get(state.salesorderId)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('productionorderId') || !state.salesorderId"
                class="flex-1"
              />
              <SelectMenuBase
                v-else
                v-model="state.productionorderId"
                :options="utils.maps.productionordersByType.value.get(state.type)?.map(toOption) ?? []"
                :disabled="disabledFields?.includes('productionorderId')"
                class="flex-1"
              />
              <UButton icon="i-mdi-plus" disabled />
            </UButtonGroup>
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(schema, 'dateStart')"
            :is-required-end-date="isRequired(schema, 'dateEnd')"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `fulfillmentorders/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #delivery>
          <FieldsetDeliveryAddress
            v-model="state"
            :disabled="!state.accountId"
            v-bind="{ utils, disabledFields, schema, openNested, slideoverOpenEdit }"
          />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :cols="2"
          />
        </template>

        <template #data>
          <AppFulfillmentordersFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.fulfillmentorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
