import { AppActivitiesSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppActivitiesSlideoverProps = ComponentProps<typeof AppActivitiesSlideover>

export type ActivitiesUtils = ReturnType<typeof useActivitiesUtils>

// view:

export const VIEW_SETTINGS_ACTIVITIES: ViewSettings<Activity> = {
  tableName: 'activities',
  views: optionsShared.views.filter(view => ['table', 'calendar'].includes(view.value)),
  selectedView: 'calendar',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fin',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dealId',
      label: 'Negocio',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'contactIds',
      label: 'Contactos',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userIds',
      label: 'Usuarios',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsActivities.type },
    { label: 'Subtipo', value: 'subtype', options: optionsActivities.subtype },
    { label: 'Estado', value: 'status', options: optionsActivities.status },
    { label: 'Prioridad', value: 'priority', options: optionsActivities.priority },
  ],
  group: 'subtype',
}

export const useActivities = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Activity>('activities')

  const slideoverOpenCreate = (props: AppActivitiesSlideoverProps) => {
    return new Promise<Activity>((resolve, reject) => {
      const data = getDefaultActivity(props.data)
      slideover.open(AppActivitiesSlideover, {
        title: 'Registrar actividad',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppActivitiesSlideoverProps) => {
    return new Promise<Activity>((resolve, reject) => {
      const data = getDefaultActivity(props.data)
      slideover.open(AppActivitiesSlideover, {
        title: 'Editar actividad',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    subtype: z.string(),
    status: z.string(),
    name: z.string(),
    dealId: z.string(),
    accountId: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Activity, refresh: () => Promise<void>, utils: ActivitiesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['type'],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'activities', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
      {
        label: 'Ver archivos (negocio)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.dealId && row.deal.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.deal, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsActivities.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('activities', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useActivitiesUtils = ({ $contacts, $deals, $accounts, $branches, $users }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsActivities,
    contactId: computed(() => $contacts.data.value.map(toOption)),
    dealId: computed(() => $deals.data.value.map(toOption)),
    accountId: computed(() => $accounts.data.value.map(toOption)),
    branchId: computed(() => $branches.data.value.map(toOption)),
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsActivities.type, 'value'),
    subtype: toMapByKey(optionsActivities.subtype, 'value'),
    status: toMapByKey(optionsActivities.status, 'value'),
    priority: toMapByKey(optionsActivities.priority, 'value'),
    contactId: computed(() => toMapByKey($contacts.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals.data.value, 'id')),
    accountId: computed(() => toMapByKey($accounts.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches.data.value, 'id')),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts.data.value, 'accountId')),
  }

  return { options, maps }
}
