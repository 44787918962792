import { AppSalesordersSlideover, FilesSlideover, SlideoverPreview } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'
import type { Salesorder } from '~~/server/utils/db.types'

// types:

type AppSalesordersSlideoverProps = ComponentProps<typeof AppSalesordersSlideover>

export type SalesordersUtils = ReturnType<typeof useSalesordersUtils>

// view:

export const VIEW_SETTINGS_SALESORDERS: ViewSettings<Salesorder> = {
  tableName: 'salesorders',
  views: optionsShared.views.filter(view => ['table', 'kanban'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'fileSalesorderPdf',
      label: 'PDF',
      class: '',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Recepción',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Vendedor',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'dealId',
      label: 'Negocio',
    },
    {
      key: 'quoteId',
      label: 'Cotización',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'delivery.incoterm',
      label: 'Incoterm',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'delivery.date',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'delivery.address',
      label: 'Address',
      class: 'min-w-[200px]', // for <th>
    },
    {
      key: 'delivery.text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'payment.satPaymentMethod',
      label: 'Método',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.conditions',
      label: 'Condiciones',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.exchangeRate.value',
      label: 'Tipo cambio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.amount',
      label: 'Subtotal',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorderitems.total',
      label: 'Total',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    // {
    //   key: 'productionorder',
    //   label: 'Orden de producción',
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    // {
    //   key: 'productionorder.stage',
    //   label: 'Etapa (OP)',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    // {
    //   key: 'productionorder.status',
    //   label: 'Estado (OP)',
    //   sortable: true,
    //   class: 'text-center', // for <th>
    //   rowClass: 'text-center', // for <td>
    // },
    // {
    //   key: 'productionorder.userId',
    //   label: 'Responsable (OP)',
    // },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsSalesorders.type },
    { label: 'Etapa', value: 'stage', options: optionsSalesorders.stage },
    { label: 'Estado', value: 'status', options: optionsSalesorders.status },
  ],
  group: 'stage',
}

export const useSalesorders = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Salesorder>('salesorders')

  const slideoverOpenCreate = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.open(AppSalesordersSlideover, {
        title: 'Registrar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppSalesordersSlideoverProps) => {
    return new Promise<Salesorder>((resolve, reject) => {
      const data = getDefaultSalesorder(props.data)
      slideover.open(AppSalesordersSlideover, {
        title: 'Editar orden de venta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    status: z.string(),
    stage: z.string(),
    type: z.string(),
    name: z.string(),
    date: z.string(),
    payment: z.object({
      satCfdiUse: z.string(),
      satPaymentMethod: z.string(),
      satPaymentForm: z.string(),
      conditions: z.string(),
      currency: z.string(),
      exchangeRate: z.object({
        value: z.number(),
        date: z.string().datetime(),
      }),
    }),
    delivery: z.object({
      incoterm: z.string(),
      date: z.string(),
      useAccountAddress: z.boolean(),
      address: z.object({
        street: z.string(),
        numberExterior: z.string(),
        city: z.string(),
        state: z.string(),
        country: z.string(),
        postalCode: z.string(),
      }),
    }),
    accountId: z.string(),
    dealId: z.string(),
    quoteId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const createUidSalesorders = (data: Salesorder) => {
    return createUid(new Date(data.date), 'OV')
  }

  const getDropdownItems = (row: Salesorder, refresh: () => Promise<void>, utils: SalesordersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'salesorders', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
      {
        label: 'Ver archivos (negocio)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.dealId && row.deal.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.deal, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    [
      {
        label: 'Copiar contraseña',
        icon: 'i-mdi-asterisk',
        disabled: !row.pdfSettings.password.enabled,
        click: () => copyToClipboard(row.pdfSettings.password.value),
      },
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideover.open(SlideoverPreview, {
          src: toUrl(`/api/blob/salesorders/${row.id}/salesorder.pdf`).href,
          title: `Orden de venta: ${row.name}`,
        }),
      },
    ],
    // Status Actions
    optionsSalesorders.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('salesorders', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: Salesorder, utils: SalesordersUtils) => {
    const user = await $fetch<User>(`/api/db/users/${data.userId}`)
    const documentDefinition = await createPdfmakeDocumentDefinitionSalesorders(data, utils, user)
    const pdf = usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the salesorder.files array (to be used after POST/PATCH)
  async function createFileSalesorderPdf(data: Salesorder, utils: SalesordersUtils) {
    const [existingData] = await $fetch<Salesorder[]>(`/api/db/salesorders`, {
      query: { id: data.id, $with: relationsSalesorders },
    }) // findOne instead of get to allow passing query.$with
    const newData = { ...existingData, ...data }

    const pdf = await createPdf(newData, utils)

    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'salesorder.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `salesorders/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    createUidSalesorders,
    getDropdownItems,
    createPdf,
    createFileSalesorderPdf,
  }
}

export const useSalesordersUtils = ({ $accounts, $branches, $contacts, $deals, $quotes, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $quotes?: Awaited<ReturnType<typeof useFetch<Quote[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsSalesorders,
    productionorder: {
      ...optionsProductionorders,
    },
    fulfillmentorder: {
      ...optionsFulfillmentorders,
    },
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    quoteId: computed(() => $quotes?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsSalesorders.type, 'value'),
    stage: toMapByKey(optionsSalesorders.stage, 'value'),
    status: toMapByKey(optionsSalesorders.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      cfdiType: toMapByKey(optionsSAT.cfdiType, 'value'),
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    payment: {
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
    },
    delivery: {
      useAccountAddress: toMapByKey(optionsQuotes.delivery.useAccountAddress, 'value'),
      incoterm: toMapByKey(optionsQuotes.delivery.incoterm, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    quoteId: computed(() => toMapByKey($quotes?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
    dealByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    quoteByAccountId: computed(() => toMapByGroupKey($quotes?.data.value, 'accountId')),
    quoteByDealId: computed(() => toMapByGroupKey($quotes?.data.value, 'dealId')),
  }

  return { options, maps }
}
