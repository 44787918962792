const createAuthorizationError = (message = "Unauthorized", statusCode = 403) => {
  return new AuthorizationError(message, statusCode);
};
class AuthorizationError extends Error {
  statusCode;
  constructor(message, statusCode) {
    super(message);
    this.statusCode = statusCode;
  }
}

function normalizeAuthorizationResponse(result) {
  if (typeof result === "boolean") {
    return { authorized: result };
  }
  return result;
}
async function allows(ability, user, ...args) {
  const response = await ability.execute(user, ...args);
  return normalizeAuthorizationResponse(response).authorized;
}
async function denies(ability, user, ...args) {
  const response = await ability.execute(user, ...args);
  return !normalizeAuthorizationResponse(response).authorized;
}
async function authorize(ability, user, ...args) {
  const response = await ability.execute(user, ...args);
  const normalized = normalizeAuthorizationResponse(response);
  if (!normalized.authorized) {
    throw createAuthorizationError(normalized.message, normalized.statusCode);
  }
}

function defineAbility(authorizerOrOptions, authorizer) {
  if (typeof authorizerOrOptions === "function") {
    return {
      allowGuest: false,
      original: authorizerOrOptions,
      execute(user, ...args) {
        if (user === null) {
          return { authorized: false };
        }
        return this.original(user, ...args);
      }
    };
  }
  return {
    allowGuest: authorizerOrOptions?.allowGuest || false,
    original: authorizer,
    execute(user, ...args) {
      if (user === null && !this.allowGuest) {
        return { authorized: false };
      }
      return this.original(user, ...args);
    }
  };
}
function allow() {
  return {
    authorized: true
  };
}
function deny(options = {}) {
  return {
    authorized: false,
    ...options
  };
}

export { AuthorizationError, allow, allows, authorize, createAuthorizationError, defineAbility, denies, deny, normalizeAuthorizationResponse };
