import { merge } from 'lodash-es'

export const getDefaultNote = (data: Partial<Note>) => merge({
  text: '',
}, data)

export const optionsNotes = {} satisfies Options

export const relationsNotes: Record<string, boolean> = {
  user: true,
}
