<script setup lang="ts">
import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

const model = defineModel<BlobObject[]>()

const props = defineProps<{
  accept?: string // comma-separated list of MIME types e.g. 'image/svg+xml, image/png'
  icon?: string
  query?: BlobPutOptions
}>()

const { renameFile, uploadFile, removeFile } = useApiBlob()

const onChange = async (fileList: FileList) => {
  const files = Array.from(fileList)

  // DISABLED: do not enforce file maxSize limit
  // const filesIncluded = files.filter(file => file.size <= 1e6)
  // const filesExcluded = files.filter(file => file.size > 1e6)

  // const data = await Promise.all(filesIncluded.map(file => uploadFile(file, props.query)))
  // model.value = [...(model.value ?? []), ...data]

  // if (filesExcluded.length) {
  //   alert(filesExcluded.length > 1
  //     ? `${filesExcluded.length} archivos exceden el tamaño máximo permitido de 1MB`
  //     : 'El archivo excede el tamaño máximo permitido de 1MB',
  //   )
  // }

  const data = await Promise.all(files.map(file => uploadFile(file, props.query)))
  model.value = [...(model.value ?? []), ...data]
}

const onRemove = async (file: BlobObject) => {
  model.value = model.value?.filter(f => f.pathname !== file.pathname)
  await removeFile(file)
}
</script>

<template>
  <UInput
    type="file"
    name="file"
    multiple
    :accept="accept"
    @change="onChange"
  >
    <div v-if="model?.length" class="w-full flex flex-wrap gap-1 items-center mt-1">
      <ButtonFile
        v-for="(file, i) in model"
        :key="`${file.pathname}-${i}`"
        :file="file"
        :on-remove="onRemove"
      />
    </div>
  </UInput>
</template>
