import { format } from 'date-fns'
import { merge } from 'lodash-es'
import { createId, createUid, type Options } from '#netzo/utils/app/index'

const uid = createUid(new Date(), 'SOL')

export const getDefaultInquiry = (data: Partial<Inquiry>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'new',
  status: 'pending',
  uid: uid,
  name: uid,
  isDatetime: false,
  date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  tags: [],
  files: [],
}, data)

export const optionsInquiries = {
  type: [
    { value: 'new', label: 'Cliente Nuevo', description: 'La consulta proviene de un cliente nuevo que todavía no está registrado en nuestra base de datos.', icon: 'i-mdi-plus-circle', color: 'blue' },
    { value: 'existing', label: 'Cliente Existente', description: 'La consulta proviene de un cliente existente que ya está registrado en nuestra base de datos.', icon: 'i-mdi-office-building', color: 'green' },
  ],
  status: optionsSharedApp.statusProcess,
  source: [
    { value: 'website', label: 'Página web', description: 'Consulta recibida a través de nuestra página web.', color: 'blue' },
    { value: 'email', label: 'Email', description: 'Consulta recibida por correo electrónico.', color: 'purple' },
    { value: 'call', label: 'Llamada', description: 'Consulta realizada mediante una llamada telefónica.', color: 'blue' },
    { value: 'videocall', label: 'Videollamada', description: 'Consulta efectuada a través de una videollamada.', color: 'orange' },
    { value: 'meeting', label: 'Reunión', description: 'Consulta surgida durante una reunión programada.', color: 'purple' },
    { value: 'whatsapp', label: 'WhatsApp', description: 'Consulta realizada a través de WhatsApp.', color: 'green' },
    { value: 'other', label: 'Otro', description: 'Consulta que no encaja en las categorías anteriores.', color: 'gray' },
  ],
} satisfies Options

export const relationsInquiries: Record<string, boolean> = {
  account: true,
  branch: true,
  campaign: true,
  contact: true,
  deal: true,
}
