import { AppTasksSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppTasksSlideoverProps = ComponentProps<typeof AppTasksSlideover>

export type TasksUtils = ReturnType<typeof useTasksUtils>

// view:

export const VIEW_SETTINGS_TASKS: ViewSettings<Task> = {
  tableName: 'tasks',
  views: optionsShared.views.filter(view => ['table', 'kanban', 'calendar'].includes(view.value)),
  selectedView: 'kanban', // TODO: change to 'calendar' when ready
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'dateStart',
      label: 'Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fin',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Estado', value: 'status', options: optionsTasks.status },
    { label: 'Prioridad', value: 'priority', options: optionsTasks.priority },
  ],
  group: 'status',
}

export const useTasks = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Task>('tasks')

  const slideoverOpenCreate = (props: AppTasksSlideoverProps) => {
    return new Promise<Task>((resolve, reject) => {
      const data = getDefaultTask(props.data)
      slideover.open(AppTasksSlideover, {
        title: 'Crear tarea',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppTasksSlideoverProps) => {
    return new Promise<Task>((resolve, reject) => {
      const data = getDefaultTask(props.data)
      slideover.open(AppTasksSlideover, {
        title: 'Editar tarea',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    status: z.string(),
    priority: z.string(),
    name: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Task, refresh: () => Promise<void>, utils: TasksUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'tasks', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Resource Actions
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('tasks', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useTasksUtils = () => {
  const options = {
    ...optionsTasks,
  }

  const maps = {
    status: toMapByKey(optionsTasks.status, 'value'),
    priority: toMapByKey(optionsTasks.priority, 'value'),
  }

  return { options, maps }
}
