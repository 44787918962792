import { AppTransactionsSlideover, AppTransactionsSlideoverReconcile, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppTransactionsSlideoverProps = ComponentProps<typeof AppTransactionsSlideover>

type AppTransactionsSlideoverReconcileProps = ComponentProps<typeof AppTransactionsSlideoverReconcile>

export type TransactionsUtils = ReturnType<typeof useTransactionsUtils>

// view:

export const VIEW_SETTINGS_TRANSACTIONS: ViewSettings<Transaction> = {
  tableName: 'transactions',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'reference',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'financialaccountId',
      label: 'Cuenta financiera',
    },
    {
      key: 'accountingDate',
      label: 'Fecha contable',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'valueDate',
      label: 'Fecha valor',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'financialaccount.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'amount',
      label: 'Monto',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'transactionFee',
      label: 'Comisión',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'netAmount',
      label: 'Monto neto',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const VIEW_SETTINGS_TRANSACTION_RECONCILIATIONS: ViewSettings<Transaction> = {
  tableName: 'transaction-reconciliations',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'reference',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
    },
    {
      key: 'accountingDate',
      label: 'Fecha contable',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'valueDate',
      label: 'Fecha valor',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'transactionFee',
      label: 'Comisión',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'netAmount',
      label: 'Monto neto',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'financialaccountId',
      label: 'Cuenta financiera',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
export const useTransactions = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Transaction>('transactions')

  const slideoverOpenCreate = (props: AppTransactionsSlideoverProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideover, {
        title: 'Crear transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppTransactionsSlideoverProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideover, {
        title: 'Editar transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenReconcile = (props: AppTransactionsSlideoverReconcileProps) => {
    return new Promise<Transaction>((resolve, reject) => {
      const data = getDefaultTransaction(props.data)
      slideover.open(AppTransactionsSlideoverReconcile, {
        title: 'Conciliar transacción',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    reference: z.string(),
    accountingDate: z.string(),
    amount: z.number(),
    netAmount: z.number(),
    financialaccountId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Transaction, refresh: () => Promise<void>, utils: TransactionsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'transactions', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Resource Actions
    [
      // Custom Resource Actions
      // Resource Actions
      [
      // Custom Resource Actions
        ...dropdownItemsAction,
      ],
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('transactions', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  const getDropdownItemsReconcile = (row: Transaction, refresh: () => Promise<void>, utils: TransactionsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Reconciliar',
        icon: 'i-mdi-pencil',
        disabled: row.$inmutable,
        click: () => {
          slideoverOpenReconcile({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'transactions', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Resource Actions
    [
      // Custom Resource Actions
      // Resource Actions
      [
      // Custom Resource Actions
        ...dropdownItemsAction,
      ],
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('transactions', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    slideoverOpenCreate,
    slideoverOpenEdit,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenReconcile,
    modal,
    modalOpenDelete,
    schema,
    getDropdownItems,
    getDropdownItemsReconcile,
  }
}

export const useTransactionsUtils = ({ $financialaccounts, $accounts, $branches, $productionorders, $cfdi, $invoices }: {
  $financialaccounts?: Awaited<ReturnType<typeof useFetch<Financialaccount[]>>>
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $productionorders?: Awaited<ReturnType<typeof useFetch<Productionorder[]>>>
  $cfdi?: Awaited<ReturnType<typeof useFetch<Cfdi[]>>>
  $invoices?: Awaited<ReturnType<typeof useFetch<Invoice[]>>>
} = {}) => {
  const options = {
    ...optionsTransactions,
    financialaccountId: computed(() => $financialaccounts.data.value.map(toOption)),
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    productionorderId: computed(() => $productionorders?.data.value.map(toOption)),
    cfdiId: computed(() => $cfdi?.data.value.map(toOption)),
    invoiceId: computed(() => $invoices?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsTransactions.type, 'value'),
    category: toMapByKey(optionsTransactions.category, 'value'),
    subcategory: toMapByKeyFromObject(optionsTransactions.subcategory, 'value'),
    currency: toMapByKey(optionsSAT.currency, 'value'),
    method: toMapByKey(optionsTransactions.method, 'value'),
    flagType: toMapByKey(optionsTransactions.flagType, 'value'),
    flagStatus: toMapByKeyFromObject(optionsTransactions.flagStatus, 'value'),
    financialaccountId: computed(() => toMapByKey($financialaccounts.data.value, 'id')),
  }

  return { options, maps }
}
