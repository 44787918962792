import { AppProductsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppProductsSlideoverProps = ComponentProps<typeof AppProductsSlideover>

export type ProductsUtils = ReturnType<typeof useProductsUtils>

// view:

export const VIEW_SETTINGS_PRODUCTS: ViewSettings<Product> = {
  tableName: 'products',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'categoryId',
      label: 'Categoría',
    },
    {
      key: 'supplierId',
      label: 'Proveedor',
    },
    {
      key: 'manufacturerId',
      label: 'Fabricante',
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useProducts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Product>('products')

  const slideoverOpenCreate = (props: AppProductsSlideoverProps) => {
    return new Promise<Product>((resolve, reject) => {
      const data = getDefaultProduct(props.data)
      slideover.open(AppProductsSlideover, {
        title: 'Crear producto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppProductsSlideoverProps) => {
    return new Promise<Product>((resolve, reject) => {
      const data = getDefaultProduct(props.data)
      slideover.open(AppProductsSlideover, {
        title: 'Editar producto',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    fiscalData: z.object({
      satProductServiceKey: z.string().optional(),
    }).required(),
    categoryId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Product, refresh: () => Promise<void>, utils: ProductsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'products', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsProducts.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    // Resource Actions
    [
      {
        label: 'Crear item',
        icon: ICONS.productitems,
        click: () => {
          const productitems$ = useProductitems()
          productitems$.slideoverOpenCreate({
            data: { type: row.type, productId: row.id },
            disabledFields: ['type', 'productId'],
          }).then(refresh)
        },
      },
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('products', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useProductsUtils = ({ $accounts, $applications, $categories }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $applications?: Awaited<ReturnType<typeof useFetch<Application[]>>>
  $categories?: Awaited<ReturnType<typeof useFetch<Category[]>>>
} = {}) => {
  const options = {
    ...optionsProducts,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    applicationId: computed(() => $applications?.data.value.map(toOption)),
    categoryId: computed(() => $categories?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsProducts.type, 'value'),
    status: toMapByKey(optionsProducts.status, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    categoryId: computed(() => toMapByKey($categories?.data.value, 'id')),
    applicationId: computed(() => toMapByKey($applications?.data.value, 'id')),
  }

  return { options, maps }
}
