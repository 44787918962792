import { AppInquiriesSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppInquiriesSlideoverProps = ComponentProps<typeof AppInquiriesSlideover>

export type InquirysUtils = ReturnType<typeof useInquiriesUtils>

// view:

export const VIEW_SETTINGS_INQUIRIES: ViewSettings<Inquiry> = {
  tableName: 'inquiries',
  views: optionsShared.views.filter(view => ['table', 'inbox'].includes(view.value)),
  selectedView: 'inbox',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'source',
      label: 'Origen',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'campaignId',
      label: 'Campaña',
    },
    {
      key: 'accountId',
      label: 'Cuenta',
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'contactId',
      label: 'Contacto',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useInquiries = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Inquiry>('inquiries')

  const slideoverOpenCreate = (props: AppInquiriesSlideoverProps) => {
    return new Promise<Inquiry>((resolve, reject) => {
      const data = getDefaultInquiry(props.data)
      slideover.open(AppInquiriesSlideover, {
        title: 'Registrar consulta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppInquiriesSlideoverProps) => {
    return new Promise<Inquiry>((resolve, reject) => {
      const data = getDefaultInquiry(props.data)
      slideover.open(AppInquiriesSlideover, {
        title: 'Editar consulta',
        ...props,
        disabledFields: ['type'],
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    source: z.string(),
    name: z.string(),
    date: z.string(),
    text: z.string(),
    campaignId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Inquiry, refresh: () => Promise<void>, utils: InquiriesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['type'],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'inquiries', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
      {
        label: 'Ver archivos (campaña)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.campaignId && row.campaign?.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.campaign, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsInquiries.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    [
      {
        label: 'Crear cliente',
        icon: ICONS.accounts,
        disabled: !!row.accountId,
        click: () => {
          const accounts$ = useAccounts()
          accounts$.slideoverOpenCreate({
            data: {
              type: 'customer',
              name: row.company ?? '',
            },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              const account = await accounts$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, accountId: account!.id })
              accounts$.slideover.close()
            },
          }).then(refresh)
        },
      },
      {
        label: 'Crear contacto',
        icon: ICONS.contacts,
        disabled: !!row.contactId,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: {
              type: 'customer', status: 'active', name: row.name, phone: row.phone, email: row.email, accountId: row.accountId },
            onSubmit: async (event) => {
              const contact = await contacts$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, contactId: contact!.id })
              contacts$.slideover.close()
            },
          }).then(refresh)
        },
      },
      {
        label: 'Crear negocio',
        icon: ICONS.deals,
        disabled: !row.accountId || row.status === 'completed',
        click: () => {
          const deals$ = useDeals()
          deals$.slideoverOpenCreate({
            data: {
              type: 'inbound',
              accountId: row.accountId ?? undefined,
              branchId: row.branchId ?? undefined,
              campaignId: row.campaignId ?? undefined,
              inquiryId: row.id,
              files: row.files,
            },
            disabledFields: ['type', 'accountId'],
            onSubmit: async (event) => {
              await deals$.onSubmitCreate(event.data)
              await onSubmitUpdate({ ...row, status: 'completed' })
              deals$.slideover.close()
            },
          }).then(refresh)
        },
      },
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Llamar',
        icon: 'i-mdi-phone-outgoing',
        disabled: !row.phone,
        click: () => open(`tel:${row.phone}`),
      },
      {
        label: 'Enviar correo',
        icon: 'i-mdi-email-arrow-right',
        disabled: (!row.email && !row.contactId) || row.status === 'completed',
        click: () => {
          const emails$ = useEmails()
          emails$.slideoverOpenCreate({
            data: { to: [
              {
                label: utils.maps.contactId.value.get(row.contactId)?.name ?? row.email,
                value: utils.maps.contactId.value.get(row.contactId)?.email ?? row.email,
              },
            ],
            subject: `Re Consulta: ${row.name}`,
            },
            disabledFields: ['type'],
            accountId: row.accountId ?? undefined,
          })
        },
      },
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('inquiries', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useInquiriesUtils = ({ $accounts, $branches, $campaigns, $contacts, $deals }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $campaigns?: Awaited<ReturnType<typeof useFetch<Campaign[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
} = {}) => {
  const options = {
    ...optionsInquiries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    campaignId: computed(() => $campaigns?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsInquiries.type, 'value'),
    status: toMapByKey(optionsInquiries.status, 'value'),
    source: toMapByKey(optionsInquiries.source, 'value'),
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    campaignId: computed(() => toMapByKey($campaigns?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
