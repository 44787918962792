<script setup lang="ts">
import { useSortable } from '@vueuse/integrations/useSortable'

const model = defineModel<string[]>({ default: () => [] })

const props = defineProps<{
  placeholder?: string
}>()

// NOTE: useSortable requires having a unique id for each item which
// cannot be the index since it changes when sorting, so we generate one
const toNote = (value: string) => ({ value, id: Math.random().toString(36).substr(2, 9) })

const notes = ref(model.value?.map(toNote) ?? [])

const removeAtIndex = (index: number) => {
  notes.value = notes.value.filter((_, i) => i !== index)
}

watch(notes, (value) => {
  model.value = value.map(({ value }) => value)
}, { deep: true })

const onInputLast = async (event) => {
  const value = event.target?.value?.trim()
  if (value) {
    notes.value.push(toNote(value)) // Add the new entry
    await nextTick() // Wait for the DOM to update
    const index = notes.value.length - 1 // Get the index of the new entry
    document.getElementById(`entries-value-${index}`)?.focus() // Focus on the new entry
    document.getElementById('last-entry')!.value = '' // Clear the last entry input
  }
}

// sortable:

const el = ref<HTMLElement | null>(null)
useSortable(el, notes, { handle: '.handle', animation: 100 })
</script>

<template>
  <fieldset>
    <div ref="el">
      <div
        v-for="(note, index) in notes"
        :key="`entry-${note.id}`"
        class="flex mb-2 text-sm"
      >
        <UTextarea
          :id="`entries-value-${index}`"
          v-model="note.value"
          resize
          :rows="2"
          class="flex-1"
        />
        <div class="flex flex-col items-center">
          <UButton
            variant="ghost"
            icon="i-mdi-drag"
            size="xs"
            color="gray"
            class="handle cursor-move"
          />
          <UButton
            variant="ghost"
            color="red"
            size="xs"
            icon="i-mdi-close"
            @click="removeAtIndex(index)"
          />
        </div>
      </div>
    </div>
    <UTextarea
      id="last-entry"
      :rows="1"
      resize
      :placeholder="placeholder ?? 'Ingresa una nueva nota'"
      class="col-span-2"
      @input="onInputLast"
    />
  </fieldset>
</template>
