import { AppSalesorderitemsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppSalesorderitemsSlideoverProps = ComponentProps<typeof AppSalesorderitemsSlideover>

export type SalesorderitemsUtils = ReturnType<typeof useSalesorderitemsUtils>

// view:

export const VIEW_SETTINGS_SALESORDERITEMS: ViewSettings<Salesorderitem> = {
  tableName: 'salesorderitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantity',
      label: 'Cantidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'unitPrice',
      label: 'Precio unitario',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'discount.amount',
      label: 'Descuento',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'amount',
      label: 'Importe',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'satTaxObject',
      label: 'Objeto de impuestos',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'total',
      label: 'Total',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useSalesorderitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Salesorderitem>('salesorderitems')

  const slideoverOpenCreate = (props: AppSalesorderitemsSlideoverProps) => {
    return new Promise<Salesorderitem>((resolve, reject) => {
      const data = getDefaultSalesorderitem(props.data)
      slideover.open(AppSalesorderitemsSlideover, {
        type: 'POST',
        title: 'Agregar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppSalesorderitemsSlideoverProps) => {
    return new Promise<Salesorderitem>((resolve, reject) => {
      const data = getDefaultSalesorderitem(props.data)
      slideover.open(AppSalesorderitemsSlideover, {
        title: 'Editar item',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    type: z.string(),
    subtype: z.string(),
    fiscalData: z.object({
      satProductServiceKey: z.string(),
      satUnitKey: z.string(),
      satTaxObject: z.string(),
    }),
    quantity: z.number(),
    unitPrice: z.number(),
    amount: z.number(),
  }).required()

  // actions:

  const getDropdownItems = (row: Salesorderitem, refresh: () => Promise<void>, utils: SalesorderitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          row.id = createId() // override id
          await onSubmitCreate(row)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'salesorderitems', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('salesorderitems', row) },
      },
      {
        label: `${row.$inmutable ? 'Habilitar' : 'Deshabilitar'}`,
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.salesorder?.$inmutable,
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useSalesorderitemsUtils = ({ $products, $productitems, $services, $serviceitems }: {
  $products?: Awaited<ReturnType<typeof useFetch<Product[]>>>
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $services?: Awaited<ReturnType<typeof useFetch<Service[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsSalesorderitems,
    productId: computed(() => $products?.data.value.map(toOption)),
    productitemId: computed(() => $productitems?.data.value.map(productitemToOption)),
    serviceId: computed(() => $services?.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems?.data.value.map(serviceitemToOption)),
  }

  const maps = {
    type: toMapByKey(optionsSalesorderitems.type, 'value'),
    subtype: toMapByKeyFromObject(optionsSalesorderitems.subtype, 'value'),
    fiscalData: {
      satUnitKey: toMapByKey(optionsSAT.productUnitId, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    taxTypes: toMapByKey(optionsSAT.taxTypes, 'value'),
    taxFactor: toMapByKey(optionsSAT.taxFactor, 'value'),
    productId: computed(() => toMapByKey($products?.data.value, 'id')),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceId: computed(() => toMapByKey($services?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
    productsByType: computed(() => toMapByGroupKey($products?.data.value, 'type')),
    servicesByType: computed(() => toMapByGroupKey($services?.data.value, 'type')),
    productitemsByType: computed(() => toMapByGroupKey($productitems?.data.value, 'type')),
    serviceitemsByType: computed(() => toMapByGroupKey($serviceitems?.data.value, 'type')),
    productitemsByProductId: computed(() => toMapByGroupKey($productitems?.data.value, 'productId')),
    serviceitemsByServiceId: computed(() => toMapByGroupKey($serviceitems?.data.value, 'serviceId')),
  }

  return { options, maps }
}
