<script setup lang="ts">
import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

const model = defineModel<BlobObject | null>()

const props = defineProps<{
  accept?: string // comma-separated list of MIME types e.g. 'image/svg+xml, image/png'
  query?: BlobPutOptions
}>()

const { renameFile, uploadFile, removeFile } = useApiBlob()

const onChange = async (fileList: FileList) => {
  const file = fileList[0]!

  // DISABLED: do not enforce file maxSize limit
  // if (file.size > 1e6) return alert('El archivo excede el tamaño máximo permitido de 1MB')

  if (model.value) await removeFile(model.value) // remove existing
  model.value = await uploadFile(file, props.query)
}

const onRemove = async () => {
  if (model.value) {
    await removeFile(model.value)
    model.value = undefined
  }
}
</script>

<template>
  <UInput
    type="file"
    name="file"
    :accept="accept"
    @change="onChange"
  >
    <div v-if="model" class="w-full flex items-center mt-1">
      <ButtonFile
        :file="model"
        :on-remove="onRemove"
      />
    </div>
  </UInput>
</template>
