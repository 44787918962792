<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Financialaccount>
  onSubmit: (event: FormSubmitEvent<Partial<Financialaccount>>) => void
  title?: string
  disabledFields?: (keyof Financialaccount | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Financialaccount>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useFinancialaccounts()

const $banks = await useFetch<Contact[]>('/api/db/banks', {
  query: { $columns: ['id', 'status', 'name', 'address'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useFinancialaccountsUtils({ $banks, $users })

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'cash',
      label: 'Detalles de Caja',
      icon: 'i-mdi-wallet',
      defaultOpen: true,
      disabled: !state.value.type,
      show: ['cash'], // Bank-related details
    },
    {
      slot: 'bank',
      label: 'Detalles Bancarios',
      icon: 'i-mdi-bank',
      defaultOpen: true,
      disabled: !state.value.type && !state.value.bankId,
      show: ['bank'], // Bank-related details
    },
    {
      slot: 'credit',
      label: 'Detalles de Crédito',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
      disabled: !state.value.type && !state.value.bankId,
      show: ['credit'], // Only for credit accounts
    },
    {
      slot: 'loan',
      label: 'Detalles de Préstamo',
      icon: 'i-mdi-cash-usd',
      defaultOpen: true,
      disabled: !state.value.type && !state.value.bankId,
      show: ['loan'], // Only for loan accounts
    },
    {
      slot: 'other',
      label: 'Detalles',
      icon: 'i-mdi-cash-usd',
      defaultOpen: true,
      disabled: !state.value.type && !state.value.bankId,
      show: ['other'], // Only for other accounts
    },
  ].filter((item) => {
    let show = true
    if (item.slot !== 'general') {
      show = item.show?.includes(state.value?.type) ?? false
    }
    return show
  })
})

const generateFinancialAccountName = async () => {
  const subtype = utils.maps.subtype[state.value!.type!]?.get(state.value!.subtype!)?.label
  const currency = utils.maps.currency.get(state.value!.currency!)?.value as string
  const bank = state.value.bankId ? utils.maps.bankId.value.get(state.value.bankId)?.name : ''
  const user = state.value.userId ? utils.maps.userId.value.get(state.value.userId)?.name : ''

  let creditType = ''
  if (state.value.type === 'credit' || state.value.type === 'loan') {
    creditType = utils.maps.creditType.get(state.value.creditType!)?.label ?? ''
  }

  const parts = [
    ...(bank ? bank.split(' ') : []),
    ...(subtype ? subtype.split(' ') : []),
    ...(creditType ? creditType.split(' ') : []),
    ...(user ? user.split(' ') : []),
    ...(currency ? currency.split(' ') : []),
  ].filter(Boolean) // Remove empty strings

  state.value.name = parts?.length ? parts.join(' ').toLocaleUpperCase() : ''
}

const loading = ref(false)

const onSubmitFinancialaccount = async (event: FormSubmitEvent<Partial<Financialaccount>>) => {
  loading.value = true

  const metaFields = ['createdAt', 'updatedAt', 'deletedAt', 'createdBy', 'updatedBy', 'deletedBy']
  const fieldsToKeep: (keyof Financialaccount)[] = ({
    bank: ['id', 'type', 'subtype', 'name', 'status', 'currency', 'bankId', 'accountNumber', 'routingNumber', 'clabe', 'iban', 'balanceMin', 'balanceMax', 'files', ...metaFields],
    cash: ['id', 'type', 'subtype', 'name', 'status', 'currency', 'userId', 'balanceMin', 'balanceMax', 'files', ...metaFields],
    credit: ['id', 'type', 'subtype', 'name', 'status', 'currency', 'bankId', 'userId', 'accountNumber', 'creditType', 'interestRateType', 'interestRate', 'creditLine', 'issuedDate', 'expiryDate', 'paymentInterval', 'paymentDay', 'files', ...metaFields],
    loan: ['id', 'type', 'subtype', 'name', 'status', 'currency', 'bankId', 'accountNumber', 'interestRateType', 'interestRate', 'creditLine', 'issuedDate', 'expiryDate', 'paymentInterval', 'paymentDay', 'files', ...metaFields],
    other: ['id', 'type', 'subtype', 'name', 'status', 'currency', 'bankId', 'accountNumber', 'balanceMin', 'balanceMax', 'files', ...metaFields],
  })?.[event.data.type!]

  Object.keys(event.data as Financialaccount).forEach((key) => {
    if (fieldsToKeep.includes(key)) return
    else event.data[key] = undefined
  })

  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.financialaccounts"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitFinancialaccount"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsFinancialaccounts.type"
              autofocus
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="optionsFinancialaccounts.subtype[state.type] ?? []"
              :disabled="disabledFields?.includes('subtype') || !state.type"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsFinancialaccounts.status ?? []"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Moneda"
            name="currency"
            :required="isRequired(schema, 'currency')"
          >
            <SelectMenuBase
              v-model="state.currency"
              :options="optionsSAT.currency"
              :disabled="disabledFields?.includes('currency')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="!['cash', 'other'].includes(state.type!) && state.type"
            label="Banco"
            name="bankId"
            required
          >
            <UButtonGroup class="flex w-full">
              <SelectMenuBase
                v-model="state.bankId"
                :options="utils.options.bankId.value"
                :disabled="disabledFields?.includes('bankId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('bankId')"
                @click="openNested(() => banks$.slideoverOpenCreate({
                  data: {
                    status: 'active',
                    accountId: state.bankId,
                  },
                  onSubmit: async (event) => {
                    const bank = await banks$.onSubmitCreate(event.data)
                    state.bankId = bank!.id
                    await $banks.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            v-if="['credit-card', 'cash-employee'].includes(state.subtype!) && state.subtype"
            label="Empleado"
            name="userId"
            required
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString
                :disabled="disabledFields?.includes('name') || !state.type || !state.subtype || !state.currency"
                @click="generateFinancialAccountName"
              />
            </UButtonGroup>
          </UFormGroup>
        </template>

        <template #cash>
          <UFormGroup
            label="Balance Mínimo"
            name="balanceMin"
          >
            <UInput
              v-model="state.balanceMin"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMin')"
            />
          </UFormGroup>

          <UFormGroup
            label="Balance Máximo"
            name="balanceMax"
          >
            <UInput
              v-model="state.balanceMax"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMax')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `financialaccounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #bank>
          <UFormGroup
            label="Número de cuenta"
            name="accountNumber"
            required
          >
            <UInput
              v-model="state.accountNumber"
              :disabled="disabledFields?.includes('accountNumber')"
            />
          </UFormGroup>
          <UFormGroup
            v-if="['ESTADOS UNIDOS'].includes(utils.maps.bankId.value.get(state.bankId)?.address.country)"
            label="Número de Ruta"
            name="routingNumber"
            required
          >
            <UInput
              v-model="state.routingNumber"
              :disabled="disabledFields?.includes('routingNumber')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="['MÉXICO'].includes(utils.maps.bankId.value.get(state.bankId)?.address.country)"
            label="CLABE"
            name="clabe"
            required
          >
            <UInput
              v-model="state.clabe"
              :disabled="disabledFields?.includes('clabe')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="!['ESTADOS UNIDOS', 'MÉXICO'].includes(utils.maps.bankId.value.get(state.bankId)?.address.country)"
            label="Código IBAN"
            name="iban"
            required
          >
            <UInput
              v-model="state.iban"
              :disabled="disabledFields?.includes('iban')"
            />
          </UFormGroup>

          <UFormGroup
            label="Balance Mínimo"
            name="balanceMin"
          >
            <UInput
              v-model="state.balanceMin"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMin')"
            />
          </UFormGroup>

          <UFormGroup
            label="Balance Máximo"
            name="balanceMax"
          >
            <UInput
              v-model="state.balanceMax"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMax')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `financialaccounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #credit>
          <UFormGroup
            label="Número de cuenta"
            name="accountNumber"
            required
          >
            <UInput
              v-model="state.accountNumber"
              :disabled="disabledFields?.includes('accountNumber')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo de crédito"
            name="creditType"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.creditType"
              :options="optionsFinancialaccounts.creditType"
              required
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo de tasa interés"
            name="interestRateType"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.interestRateType"
              :options="optionsFinancialaccounts.interestRateType"
              :disabled="disabledFields?.includes('interestRateType')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tasa de interés"
            name="interestRate"
            required
          >
            <UInput
              v-model="state.interestRate"
              type="number"
              trailing-icon="i-mdi-percent"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('interestRate')"
            />
          </UFormGroup>

          <UFormGroup
            label="Línea de crédito"
            name="creditLine"
            required
          >
            <UInput
              v-model="state.creditLine"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('creditLine')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.issueDate"
            v-model:date-end="state.expiryDate"
            :is-required-start-date="isRequired(schema, 'issueDate')"
            :is-required-end-date="isRequired(schema, 'expiryDate')"
            :start-date-label="'Fecha de emisión'"
            :end-date-label="'Fecha de vencimiento'"
            :disabled-fields="['isDatetime']"
          />

          <UFormGroup
            label="Frencuencia de Pago"
            name="paymentFrecuency"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.paymentInterval"
              :options="optionsFinancialaccounts.paymentInterval"
              :disabled="disabledFields?.includes('paymentInterval')"
            />
          </UFormGroup>

          <UFormGroup
            label="Día de Pago"
            name="paymentDay"
          >
            <UInput
              v-model="state.paymentDay"
              type="number"
              inputmode="numeric"
              :min="1"
              :max="31"
              :step="1"
              :disabled="disabledFields?.includes('paymentDay')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `financialaccounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #loan>
          <UFormGroup
            label="Número de cuenta"
            name="accountNumber"
            required
          >
            <UInput
              v-model="state.accountNumber"
              :disabled="disabledFields?.includes('accountNumber')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo de tasa interés"
            name="interestRateType"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.interestRateType"
              :options="optionsFinancialaccounts.interestRateType"
              :disabled="disabledFields?.includes('interestRateType')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tasa de interés"
            name="interestRate"
            required
          >
            <UInput
              v-model="state.interestRate"
              type="number"
              trailing-icon="i-mdi-percent"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('interestRate')"
            />
          </UFormGroup>

          <UFormGroup
            label="Línea de crédito"
            name="creditLine"
            required
          >
            <UInput
              v-model="state.creditLine"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('creditLine')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.issueDate"
            v-model:date-end="state.expiryDate"
            :is-required-start-date="isRequired(schema, 'issueDate')"
            :is-required-end-date="isRequired(schema, 'expiryDate')"
            :start-date-label="'Fecha de emisión'"
            :end-date-label="'Fecha de vencimiento'"
            :disabled-fields="['isDatetime']"
          />

          <UFormGroup
            label="Frencuencia de Pago"
            name="paymentFrecuency"
            required
          >
            <SelectMenuBaseInfo
              v-model="state.paymentInterval"
              :options="optionsFinancialaccounts.paymentInterval"
              :disabled="disabledFields?.includes('paymentInterval')"
            />
          </UFormGroup>

          <UFormGroup
            label="Día de Pago"
            name="paymentDay"
          >
            <UInput
              v-model="state.paymentDay"
              type="number"
              inputmode="numeric"
              :min="1"
              :max="31"
              :step="1"
              :disabled="disabledFields?.includes('paymentDay')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `financialaccounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #other>
          <UFormGroup
            label="Número de cuenta"
            name="accountNumber"
            required
          >
            <UInput
              v-model="state.accountNumber"
              :disabled="disabledFields?.includes('accountNumber')"
            />
          </UFormGroup>

          <UFormGroup
            label="Balance Mínimo"
            name="balanceMin"
          >
            <UInput
              v-model="state.balanceMin"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMin')"
            />
          </UFormGroup>

          <UFormGroup
            label="Balance Máximo"
            name="balanceMax"
          >
            <UInput
              v-model="state.balanceMax"
              type="number"
              trailing-icon="i-mdi-currency-usd"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('balanceMax')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `financialaccounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>
      </UAccordion>
    </UForm>
    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.financialaccounts"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
