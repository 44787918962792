import { merge } from 'lodash-es'

export const getDefaultAuditlog = (data: Partial<Auditlog>) => merge({}, data)

export const optionsAuditlogs = {
  action: [
    {
      value: 'create',
      label: 'Creación',
      description: 'Se creó un nuevo registro.',
      color: 'green',
    },
    {
      value: 'update',
      label: 'Modificación',
      description: 'Se modifico un registro existente.',
      color: 'blue',
    },
    {
      value: 'delete',
      label: 'Eliminación',
      description: 'Se eliminó un registro existente.',
      color: 'red',
    },
    {
      value: 'login',
      label: 'Inicio de sesión',
      description: 'Un usuario inició sesión.',
      color: 'purple',
    },
    {
      value: 'logout',
      label: 'Cierre de sesión',
      description: 'Un usuario cerró sesión.',
      color: 'orange',
    },
  ],
  entity: optionsShared.entity,
} satisfies Options

export const relationsAuditlogs: Record<string, boolean> = {
  user: true,
}
