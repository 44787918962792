import { AppDealsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppDealsSlideoverProps = ComponentProps<typeof AppDealsSlideover>

export type DealsUtils = ReturnType<typeof useDealsUtils>

// view:

export const VIEW_SETTINGS_DEALS: ViewSettings<Deal> = {
  tableName: 'deals',
  views: optionsShared.views.filter(view => ['table', 'kanban'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'accountId',
      label: 'Cliente',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'stage',
      label: 'Etapa',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'rating',
      label: 'Valoración',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'userId',
      label: 'Vendedor',
    },
    {
      key: 'contactIds',
      label: 'Contactos',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branchId',
      label: 'Sucursal',
    },
    {
      key: 'campaignId',
      label: 'Campaña',
    },
    {
      key: 'inquiryId',
      label: 'Solicitud',
    },
    {
      key: 'quotes',
      label: 'Cotizaciones',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groups: [
    { label: 'Tipo', value: 'type', options: optionsDeals.type },
    { label: 'Etapa', value: 'stage', options: optionsDeals.stage },
    { label: 'Estado', value: 'status', options: optionsDeals.status },
    { label: 'Prioridad', value: 'priority', options: optionsDeals.priority },
    { label: 'Valoración', value: 'rating', options: optionsDeals.rating },
  ],
  group: 'stage',
}

export const useDeals = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Deal>('deals')

  const slideoverOpenCreate = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const data = getDefaultDeal(props.data)
      slideover.open(AppDealsSlideover, {
        title: 'Crear negocio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppDealsSlideoverProps) => {
    return new Promise<Deal>((resolve, reject) => {
      const data = getDefaultDeal(props.data)

      slideover.open(AppDealsSlideover, {
        title: 'Editar negocio',
        ...props,
        disabledFields: ['type'],
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    priority: z.string(),
    stage: z.string(),
    status: z.string(),
    rating: z.string(),
    name: z.string(),
    accountId: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Deal, refresh: () => Promise<void>, utils: DealsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'deals', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
      {
        label: 'Ver archivos (campaña)',
        icon: 'i-mdi-file-multiple',
        disabled: !row.campaignId && row.campaign?.files.length > 0,
        click: () => {
          slideover.open(FilesSlideover, { data: row.campaign, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsDeals.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    // Resource Actions
    [
      {
        label: 'Registrar actividad',
        icon: ICONS.activities,
        click: () => {
          const activities$ = useActivities()
          activities$.slideoverOpenCreate({
            data: { dealId: row.id,
              accountId: row.accountId,
              branchId: row.branchId,
            },
            disabledFields: ['dealId', 'accountId'],
          }).then(refresh)
        },
      },
      {
        label: 'Crear cotización',
        icon: ICONS.quotes,

        click: () => {
          const quotes$ = useQuotes()
          quotes$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      {
        label: 'Registrar orden de venta',
        icon: ICONS.quotes,
        click: () => {
          const quotes$ = useQuotes()
          quotes$.slideoverOpenCreate({
            data: { dealId: row.id },
            disabledFields: ['dealId'],
          }).then(refresh)
        },
      },
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('deals', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useDealsUtils = ({ $accounts, $branches, $campaigns, $inquiries, $contacts, $users, $quotes }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $campaigns?: Awaited<ReturnType<typeof useFetch<Campaign[]>>>
  $inquiries?: Awaited<ReturnType<typeof useFetch<Inquiry[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
  $quotes?: Awaited<ReturnType<typeof useFetch<Quote[]>>>
} = {}) => {
  const options = {
    ...optionsDeals,
    accountId: computed(() => $accounts.data.value.map(toOption)),
    branchId: computed(() => $branches.data.value.map(toOption)),
    campaignId: computed(() => $campaigns.data.value.map(toOption)),
    inquiryId: computed(() => $inquiries.data.value.map(toOption)),
    contactId: computed(() => $contacts.data.value.map(toOption)),
    userId: computed(() => $users.data.value.map(toOption)),
    quoteId: computed(() => $quotes.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    priority: toMapByKey(optionsDeals.priority, 'value'),
    stage: toMapByKey(optionsDeals.stage, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
    rating: toMapByKey(optionsDeals.rating, 'value'),
    accountId: computed(() => toMapByKey($accounts.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches.data.value, 'id')),
    campaignId: computed(() => toMapByKey($campaigns.data.value, 'id')),
    inquiryId: computed(() => toMapByKey($inquiries.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts.data.value, 'id')),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
    campaignByType: computed(() => toMapByGroupKey($campaigns.data.value, 'type')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches.data.value, 'accountId')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts.data.value, 'accountId')),
    quoteByAccountId: computed(() => toMapByGroupKey($quotes.data.value, 'accountId')),
    quotesByDealId: computed(() => toMapByGroupKey($quotes.data.value, 'dealId')),
  }

  return { options, maps }
}
