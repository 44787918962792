<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<Productionorderitem | Fulfillmentorderitem>({ required: true })

const props = defineProps<{
  entity: 'productionorderitems' | 'fulfillmentorderitems'
  data: Partial<Productionorderitem | Fulfillmentorderitem >
  salesorderId: Partial<Salesorder>
  title?: string
  disabledFields?: (keyof Productionorderitem | Fulfillmentorderitem | string)[]
  schema: ZodSchema
  disabled?: boolean
  cols?: number
  slideoverOpenEdit: () => void
}>()

const $products = await useFetch<Productitem[]>('/api/db/products', {
  query: { $columns: ['id', 'name', 'type'] },
  default: () => [],
})
const $productitems = await useFetch<Productitem[]>('/api/db/productitems', {
  query: { $columns: ['id', 'name', 'type', 'productId'] },
  default: () => [],
})
const $salesorderitems = await useFetch<Salesorderitem[]>('/api/db/salesorderitems', {
  query: {},
  default: () => [],
})
const $services = await useFetch<Service[]>('/api/db/services', {
  query: { $columns: ['id', 'type', 'name'] },
  default: () => [],
})
const $serviceitems = await useFetch<Serviceitem[]>('/api/db/productitems', {
  query: { $columns: ['id', 'type', 'name', 'serviceId'] },
  default: () => [],
})

const products$ = useProducts()
const productitems$ = useProductitems()
const services$ = useServices()
const serviceitems$ = useServiceitems()

const category = ref<string | undefined>('products')
const productServiceType = ref<string | undefined>(undefined)
const productId = ref<number | undefined>(undefined)
const serviceId = ref<number | undefined>(undefined)

const options = {
  concept: {
    category: optionsCategories.type,
    type: {
      products: optionsProducts.type,
      services: optionsServices.type,
    },
  },
  productId: computed(() => $products?.data.value.map(toOption)),
  productitemId: computed(() => $productitems?.data.value.map(toOption)),
  salesorderitemId: computed(() => $salesorderitems?.data.value.map(toOption)),
  serviceId: computed(() => $services?.data.value.map(toOption)),
  serviceitemId: computed(() => $serviceitems?.data.value.map(toOption)),
}

const maps = {
  productId: computed(() => toMapByKey($products?.data.value, 'id')),
  productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
  salesorderitemId: computed(() => toMapByKey($salesorderitems?.data.value, 'id')),
  serviceId: computed(() => toMapByKey($services?.data.value, 'id')),
  serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  productsByType: computed(() => toMapByGroupKey($products?.data.value, 'type')),
  servicesByType: computed(() => toMapByGroupKey($services?.data.value, 'type')),
  productitemsByType: computed(() => toMapByGroupKey($productitems?.data.value, 'type')),
  salesorderitemsByType: computed(() => toMapByGroupKey($salesorderitems?.data.value, 'type')),
  serviceitemsByType: computed(() => toMapByGroupKey($serviceitems?.data.value, 'type')),
  productitemsByProductId: computed(() => toMapByGroupKey($productitems?.data.value, 'productId')),
  serviceitemsByServiceId: computed(() => toMapByGroupKey($serviceitems?.data.value, 'serviceId')),
  salesorderitemsBySalesorderId: computed(() => toMapByGroupKey($salesorderitems?.data.value, 'salesorderId')),
}

const salesorderitemOptions = computed(() => {
  if (props.entity === 'productionorderitems') {
    return maps.salesorderitemsBySalesorderId?.value.get(props.salesorderId)?.filter(item => item.type === 'products').map(toOption) ?? []
  }
  return maps.salesorderitemsBySalesorderId?.value.get(props.salesorderId)?.map(toOption) ?? []
})

const regeneratekey = ref(Date.now())

const onUpdateSalesorderitemId = (id: string) => {
  const salesorderitem = maps.salesorderitemId?.value.get(id)
  model.value.productitemId = salesorderitem?.productitemId
  model.value.serviceitemId = salesorderitem?.serviceitemId
  model.value.quantityPlanned = salesorderitem?.quantity
  model.value.name = salesorderitem?.name
  model.value.text = salesorderitem?.text
  if (model.value.productitemId) {
    const productitem = maps.productitemId.value.get(model.value.productitemId) as Productitem
    const selectedProduct = maps.productId.value.get(productitem?.productId) as Product
    console.log('selectedProduct', selectedProduct)
    category.value = 'products'
    productServiceType.value = selectedProduct.type
    productId.value = selectedProduct.id
  }
  if (model.value.serviceitemId) {
    const serviceitem = maps.serviceitemId.value.get(model.value.serviceitemId) as Serviceitem
    const selectedService = maps.serviceId.value.get(serviceitem?.serviceId) as Service
    category.value = 'services'
    productServiceType.value = selectedService.type
    serviceId.value = selectedService.id
  }
}

const generateConceptName = () => {
  if (model.value.productitemId) {
    const productitem = maps.productitemId.value.get(model.value.productitemId) as Productitem
    model.value.name = productitem.name
  }
  if (model.value.serviceitemId) {
    const serviceitem = maps.serviceitemId.value.get(model.value.serviceitemId) as Serviceitem
    model.value.name = serviceitem.name
  }
}

watch(model, () => {
  if (model.value.productitemId) {
    const productitem = maps.productitemId.value.get(model.value.productitemId) as Productitem
    const selectedProduct = maps.productId.value.get(productitem?.productId) as Product
    console.log('selectedProduct', selectedProduct)
    category.value = 'products'
    productServiceType.value = selectedProduct.type
    productId.value = selectedProduct.id
  }
  if (model.value.serviceitemId) {
    const serviceitem = maps.serviceitemId.value.get(model.value.serviceitemId) as Serviceitem
    const selectedService = maps.serviceId.value.get(serviceitem?.serviceId) as Service
    category.value = 'services'
    productServiceType.value = selectedService.type
    serviceId.value = selectedService.id
  }
  regeneratekey.value = Date.now()
},
{ immediate: true })

const selectedProductServiceTab = ref(0)
watch(model.value.type, () => {
  selectedProductServiceTab.value = 0
  onUpdateTab()
})

watch(model.value.salesorderitemId, () => {
  if (model.value.salesorderitemId) {
    selectedProductServiceTab.value = 1
    onUpdateSalesorderitemId(model.value.salesorderitemId)
  }
})

const onUpdateTab = () => {
  category.value = props.entity === 'productionorderitems' ? 'products' : undefined
  productServiceType.value = undefined
  productId.value = undefined
  serviceId.value = undefined
  model.value.name = undefined
  model.value.salesorderitemId = undefined
  model.value.productitemId = undefined
  model.value.serviceitemId = undefined
}
const productServiceTabs = [
  {
    label: 'Nuevo',
    icon: 'i-mdi-plus-circle',
  },
  {
    label: 'Orden de Venta',
    icon: ICONS.salesorderitems,
  },
]

const FIELDSET = `w-full grid grid-cols-1 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UTabs
      v-if="model.type === 'external'"
      v-model="selectedProductServiceTab"
      :items="productServiceTabs"
      @update:model-value="onUpdateTab"
    />

    <UFormGroup
      v-if="model.type === 'external' && selectedProductServiceTab === 1"
      label="Item de Orden de Venta"
      name="salesorderitemId"
    >
      <div class="flex gap-1">
        <UButtonGroup class="flex-1">
          <SelectMenuBase
            v-model="model.salesorderitemId"
            :options="salesorderitemOptions"
            :disabled="disabledFields?.includes('salesorderitemId') || !props.salesorderId"
            class="flex-1"
            @update:model-value="onUpdateSalesorderitemId"
          />
          <UButton icon="i-mdi-plus" disabled />
        </UButtonGroup>
        <ButtonRefresh
          toast="Cantidad actualizada"
          :disabled="!model.salesorderitemId"
          @click="onUpdateSalesorderitemId(model.salesorderitemId)"
        />
      </div>
    </UFormGroup>

    <UFormGroup
      :label="`Categoria`"
      name="concept.category"
      :required="isRequired(schema, 'concept.category')"
    >
      <SelectMenuBaseInfo
        v-model="category"
        :options="options.concept.category?? []"
        :disabled="disabledFields?.includes('concept.category') || (selectedProductServiceTab === 1) || props.entity === 'productionorderitems'"
      />
    </UFormGroup>

    <UFormGroup
      :label="`Tipo de ${category === 'products' ? 'producto' : 'servicio'}`"
      name="concept.type"
      :required="isRequired(schema, 'concept.type')"
    >
      <SelectMenuBaseInfo
        v-model="productServiceType"
        :options="options.concept.type[category] ?? []"
        :disabled="disabledFields?.includes('concept.type') || !category || (selectedProductServiceTab === 1)"
      />
    </UFormGroup>

    <UFormGroup
      :label="`${category === 'products' ? 'Producto' : 'Servicio'}`"
      name="parent"
      :required="isRequired(schema, 'parent')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-if="category === 'products'"
          :key="`product-id-${regeneratekey}`"
          v-model="productId"
          :options="maps.productsByType?.value.get(productServiceType)?.map(toOption) ?? []"
          :disabled="(selectedProductServiceTab === 1)"
          class="flex-1"
        />
        <SelectMenuBase
          v-else
          :key="`service-id-${regeneratekey}`"
          v-model="serviceId"
          :options="maps.servicesByType?.value.get(productServiceType)?.map(toOption) ?? []"
          :disabled="(selectedProductServiceTab === 1)"
          class="flex-1"
        />
        <UButton
          v-if="category === 'products'"
          icon="i-mdi-plus"
          :disabled="(selectedProductServiceTab === 1)"
          @click="openNested(() => products$.slideoverOpenCreate({
            data: { type: productServiceType },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              products$.onSubmitCreate(event.data)
              await $products.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
        <UButton
          v-else
          icon="i-mdi-plus"
          :disabled="(selectedProductServiceTab === 1)"
          @click="openNested(() => services$.slideoverOpenCreate({
            data: { type: productServiceType },
            disabledFields: ['type'],
            onSubmit: async (event) => {
              services$.onSubmitCreate(event.data)
              await $services.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Item"
      :required="isRequired(schema, 'item')"
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-if="category === 'products'"
          v-model="model.productitemId"
          :options="maps.productitemsByProductId?.value.get(productId)?.map(toOption) ?? []"
          :disabled="!productId || (selectedProductServiceTab === 1)"
          class="flex-1"
        />
        <SelectMenuBase
          v-else
          v-model="model.serviceitemId"
          :options="maps.serviceitemsByServiceId?.value.get(serviceId)?.map(toOption) ?? []"
          :disabled="!serviceId || (selectedProductServiceTab === 1)"
          class="flex-1"
        />
        <UButton
          v-if="category === 'products'"
          icon="i-mdi-plus"
          :disabled="!productId || (selectedProductServiceTab === 1)"
          @click="openNested(() => productitems$.slideoverOpenCreate({
            data: { type: productServiceType, productId: productId },
            disabledFields: ['type', 'productId'],
            onSubmit: async (event) => {
              const productitem = await productitems$.onSubmitCreate(event.data)
              model.productitemId = productitem!.id
              await $productitems.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
        <UButton
          v-else
          icon="i-mdi-plus"
          :disabled="!serviceId || (selectedProductServiceTab === 1)"
          @click="openNested(() => serviceitems$.slideoverOpenCreate({
            data: { type: productServiceType, serviceId: serviceId },
            disabledFields: ['type', 'serviceId'],
            onSubmit: async (event) => {
              const serviceitem = await serviceitems$.onSubmitCreate(event.data)
              model.serviceitemId = serviceitem!.id
              await $serviceitems.refresh()
              await openNested(() => props.slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Nombre"
      name="name"
      :required="isRequired(schema, 'name')"
    >
      <UButtonGroup class="flex">
        <UInput
          v-model="model.name"
          autofocus
          :disabled="disabledFields?.includes('name')"
          class="flex-1"
        />
        <ButtonGenerateString
          :disabled="disabledFields?.includes('name') || !model.productitemId && !model.serviceitemId"
          @click="generateConceptName"
        />
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      name="image"
      label="Imagen"
      :required="isRequired(schema, 'image')"
    >
      <InputImage
        v-model="model.image"
        :query="{ prefix: `${entity}/${model.id}` }"
      />
    </UFormGroup>

    <UFormGroup
      label="Descripción"
      name="text"
      :required="isRequired(schema, 'text')"
    >
      <UTextarea
        v-model="model.text"
        autoresize
        :disabled="disabledFields?.includes('text')"
      />
    </UFormGroup>
  </fieldset>
</template>
