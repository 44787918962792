<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Transaction>
  onSubmit: (event: FormSubmitEvent<Partial<Transaction>>) => void
  title?: string
  disabledFields?: (keyof Transaction | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Transaction>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  slideoverOpenReconcile,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  getDropdownItemsReconcile,
} = useTransactions()

const $financialaccounts = await useFetch<Financialaccount[]>('/api/db/financialaccounts', {
  query: { $columns: ['id', 'name', 'image', 'currency'] },
  default: () => [],
})

const utils = useTransactionsUtils({ $financialaccounts })

const financialaccounts$ = useFinancialaccounts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

watch(() => [state.value.amount, state.value.transactionFee], ([amount, transactionFee]) => {
  state.value.netAmount = (amount || 0) - (transactionFee || 0)
}, { immediate: true })

const currency = computed(() => utils.maps.financialaccountId.value.get(state.value.financialaccountId)?.currency)
const loading = ref(false)

const onSubmitTransaction = async (event: FormSubmitEvent<Partial<Transaction>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.transactions"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitTransaction"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Cuenta financiera"
            name="financialaccountId"
            :required="isRequired(schema, 'financialaccountId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.financialaccountId"
                :options="utils.options.financialaccountId.value"
                :disabled="disabledFields?.includes('financialaccountId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('financialaccountId')"
                @click="openNested(() => financialaccounts$.slideoverOpenCreate({
                  data: {
                    type: 'cash',
                    currency: 'MXN',
                    balanceMin: 0,
                    balanceMax: 0,
                  },
                  onSubmit: async (event) => {
                    const financialaccount = await financialaccounts$.onSubmitCreate(event.data)
                    state.financialaccountId = financialaccount!.id
                    await $financialaccounts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Referencia"
            name="reference"
            :required="isRequired(schema, 'reference')"
          >
            <UInput
              v-model="state.reference"
              autofocus
              :disabled="disabledFields?.includes('reference')"
            />
          </UFormGroup>

          <div class="grid grid-cols-2 gap-2">
            <UFormGroup
              label="Monto"
              name="amount"
              :required="isRequired(schema, 'amount')"
            >
              <InputCurrency
                v-model="state.amount"
                :currency="currency ?? 'USD'"
                :disabled="disabledFields?.includes('amount')"
              />
            </UFormGroup>

            <UFormGroup
              label="Comisión"
              name="transactionFee"
              :required="isRequired(schema, 'transactionFee')"
            >
              <InputCurrency
                v-model="state.transactionFee"
                :currency="currency ?? 'USD'"
                :disabled="disabledFields?.includes('transactionFee')"
              />
            </UFormGroup>

            <UFormGroup
              label="Monto neto"
              name="netAmount"
            >
              <InputReadOnly
                :model-value="toCurrency(Number(isNaN(state.netAmount)) ? 0 : (state.netAmount ?? 0), currency ?? 'USD')"
              />
            </UFormGroup>
            <UFormGroup
              label="Moneda"
            >
              <InputReadOnly :model-value="currency" />
            </UFormGroup>
          </div>

          <Alert
            type="info"
            description="Monto neto calculado restando comisión bancaria al monto total."
          />

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.accountingDate"
            v-model:date-end="state.valueDate"
            :is-required-start-date="isRequired(schema, 'accountingDate')"
            :is-required-end-date="isRequired(schema, 'valueDate')"
            :start-date-label="'Fecha contable'"
            :end-date-label="'Fecha valor'"
            :disabled="disabledFields"
          />

          <Alert
            v-if="state.type === 'input'"
            type="info"
            description="Fecha contable es cuando se registra la transacción, y fecha de valor es cuando los fondos están disponibles"
          />

          <UFormGroup
            v-if="state.type !== 'input'"
            label="Fecha"
            name="accountingDate"
            :required="isRequired(schema, 'accountingDate')"
          >
            <InputDatetime
              v-model="state.accountingDate"
              :is-datetime="state.isDatetime"
              :disabled="disabledFields?.includes('accountingDate')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `transactions/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #data>
          <AppTransactionsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.transactions"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
