import type { Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultFinancialaccount = (data: Partial<Financialaccount>) => merge({
  status: 'active',
  tags: [],
  files: [],
}, data)

export const optionsFinancialaccounts = {
  type: [
    { value: 'cash', label: 'Efectivo', color: 'green', description: 'Cuenta de efectivo en mano o en caja.' },
    { value: 'bank', label: 'Banco', color: 'blue', description: 'Cuenta bancaria en una institución financiera.' },
    { value: 'credit', label: 'Crédito', color: 'pink', description: 'Cuenta de crédito que permite gastar más de lo que se tiene disponible.' },
    { value: 'loan', label: 'Préstamo', color: 'orange', description: 'Cuenta de préstamo que se debe pagar en cuotas.' },
    { value: 'other', label: 'Otro', color: 'purple', description: 'Cualquier otro tipo de cuenta que no se ajuste a las categorías anteriores.' },
  ],
  subtype: {
    cash: [
      { value: 'cash-office', label: 'Caja oficina', description: 'Efectivo en caja de la oficina.' },
      { value: 'cash-employee', label: 'Caja empleado', description: 'Efectivo en caja de un empleado.' },
    ],
    bank: [
      { value: 'bank-checking', label: 'Cuenta corriente', description: 'Cuenta bancaria utilizada para transacciones diarias y pagos.' },
      { value: 'bank-saving', label: 'Cuenta ahorro', description: 'Cuenta bancaria destinada a ahorrar dinero con intereses.' },
    ],
    credit: [
      { value: 'credit-business', label: 'Crédito empresarial', description: 'Líneas de crédito destinadas a financiar operaciones empresariales.' },
      { value: 'credit-personal', label: 'Crédito personal', description: 'Líneas de crédito personales, como préstamos personales.' },
      { value: 'credit-card', label: 'Tarjeta crédito', description: 'Tarjetas de crédito que permiten realizar ventas a crédito.' },
    ],
    loan: [
      { value: 'loan-business', label: 'Préstamo empresarial', description: 'Préstamos destinados a financiar operaciones empresariales.' },
      { value: 'loan-personal', label: 'Préstamo personal', description: 'Préstamos destinados a individuos para uso personal.' },
      { value: 'loan-mortgage', label: 'Hipoteca', description: 'Préstamos para la ventade bienes inmuebles.' },
      { value: 'loan-auto', label: 'Préstamo auto', description: 'Préstamos destinados a la ventade vehículos.' },
    ],
    other: [
      { value: 'other', label: 'Otro', description: 'Cualquier otro tipo de cuenta financiera.' },
    ],
  },
  status: optionsSharedApp.statusResource,
  creditType: [
    { value: 'revolving', label: 'Revolvente', description: 'Crédito renovable, como tarjetas de crédito. El límite de crédito se renueva a medida que se paga.' },
    { value: 'installment', label: 'Por cuotas', description: 'Crédito que se paga en cuotas fijas a lo largo de un período específico. Ejemplo: préstamos personales.' },
    { value: 'open-ended', label: 'Crédito abierto', description: 'Crédito que permite pedir prestado según sea necesario y pagar según conveniencia. Ejemplo: líneas de crédito.' },
  ],
  interestRateType: [
    { value: 'fixed', label: 'Tasa fija', description: 'Tasa de interés que no cambia durante la vida del préstamo o inversión.' },
    { value: 'variable', label: 'Tasa variable', description: 'Tasa de interés que cambia según las condiciones del mercado.' },
  ],
  paymentInterval: [
    { value: 'weekly', label: 'Semanal', description: 'Pago semanal de la cuota.' },
    { value: 'biweekly', label: 'Quincenal', description: 'Pago quincenal de la cuota.' },
    { value: 'monthly', label: 'Mensual', description: 'Pago mensual de la cuota.' },
    { value: 'quarterly', label: 'Trimestral', description: 'Pago trimestral de la cuota.' },
    { value: 'semiannually', label: 'Semestral', description: 'Pago semestral de la cuota.' },
    { value: 'annually', label: 'Anual', description: 'Pago anual de la cuota.' },
  ],
} satisfies Options

export const relationsFinancialaccounts: Record<string, boolean> = {
  bank: true,
  transactions: true,
  user: true,
}
