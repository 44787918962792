type ExchangeRate = {
  date: string
  value: number
}

export function useExchangeRate() {
  const {
    state,
    isReady,
    execute,
  } = useAsyncState<ExchangeRate>(() => $fetch('/api/exchange-rate'), {} as ExchangeRate)

  const toSelectedCurrency = (value: number, currency: string, selectedCurrency: 'MXN' | 'USD') => {
    if (!state.value) return ''

    const mxnToUsd = state.value.value
    const usdToMxn = Math.round((1 / mxnToUsd) * 1e6) / 1e6

    // default to 'MXN' which will render value in mexican locale (format $X,XXX.XX)
    if (currency === selectedCurrency) return toCurrency(value, 'MXN')
    if (currency === 'MXN' && selectedCurrency === 'USD') return toCurrency(value * mxnToUsd, 'MXN')
    if (currency === 'USD' && selectedCurrency === 'MXN') return toCurrency(value * usdToMxn, 'MXN')

    return '' // default to empty state
  }

  return {
    state,
    isReady,
    execute,
    toSelectedCurrency,
  }
}
