<script setup lang="ts">
import type { DropdownItem } from '#ui/types'

const props = defineProps<{
  auditlog: Auditlog
  utils: AuditlogsUtils
  dropdownItems?: DropdownItem[]
}>()

const { user } = useUserSession()
const { moduleId } = useModules()

const auditlogUser = computed(
  () => props.auditlog?.user ?? props.utils.maps.userId.value.get(props.auditlog.userId),
)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitUpdateMultiple,
  modal,
  modalOpenChanges,
  schema,
  getDropdownItems,
  getAuditlogDescription,
} = useAuditlogs()
</script>

<template>
  <!-- IMPORTANT: must use CSS variables and not e.g. `border-[toHexColor(auditlog.userId)]`
  directly since TailwindCSS will not pick-up dynamic CSS colors outside of the SSR (on client) -->
  <div
    :id="`auditlog-${auditlog.id}`"
    class="h-min p-2 rounded-md bg-gray-100 dark:bg-gray-800"
    :style="{ '--dynamic-color': toHexColor(auditlog.userId) }"
  >
    <div class="flex items-center justify-between text-xs py-1">
      <div class="flex-1 flex items-center gap-2">
        <UAvatar
          :title="auditlogUser?.name"
          :src="auditlogUser?.image"
          :alt="auditlogUser?.name"
          size="xs"
          class="border border-[var(--dynamic-color)]"
          :style="{ '--dynamic-color': toHexColor(auditlog.userId) }"
        />
        <span
          :title="auditlogUser?.email"
          class="text-[var(--dynamic-color)] font-medium text-xs"
          :style="{ '--dynamic-color': toHexColor(auditlog.userId) }"
        >
          {{ auditlogUser?.name?.toUpperCase() ?? auditlogUser?.email }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <ViewRendererDatetime
          :datetime="auditlog.createdAt"
          exact
          display-hours
        />
        <Can
          v-if="dropdownItems?.length"
          :ability="update"
          :args="[moduleId]"
        >
          <UDropdown
            :items="dropdownItems"
            :popper="{ placement: 'auto-start' }"
            :ui="{ width: 'min-w-max' }"
          >
            <UButton
              icon="i-mdi-dots-horizontal"
              variant="ghost"
              size="2xs"
              square
            />
          </UDropdown>
        </Can>
      </div>
    </div>

    <!-- AUDITLOG: could add e.g. `class="max-h-[500px] overflow-y-auto"` to limit card height -->
    <p
      class="py-2 px-1 text-sm"
      v-html="getAuditlogDescription(auditlog, utils)"
    />
  </div>
</template>

<style scoped>
:deep(code),
:deep(kbd) {
  font-family: "Source Code Pro",monospace;
  font-variant-ligatures: none !important;
  border: 1px solid rgb(var(--ui-foreground) / 0.3);
  border-radius: 4px;
  /* background-color: rgba(var(--background));
  color: rgba(var(--on-background)); */
  padding: 1px 4px;
  margin: auto 1px;
  /* box-shadow: 0px 1px 4px rgb(0 0 0 / 10%); */
  vertical-align: baseline;
}
</style>
