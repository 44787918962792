import { merge } from 'lodash-es'

// TODO: Fix edit tax salesorderitems must also be considered
export const getDefaultSalesorderitem = (data: Partial<Salesorderitem>): Salesorderitem => merge({
  id: createId(),
  type: 'products',
  subtype: 'finished-goods',
  discount: { type: 'percent' },
  fiscalData: {
    satTaxObject: '02',
  },
  taxes: {
    transfers: [
      {
        enabled: true,
        tax: '002',
        satFactorType: 'rate',
        satRateOrQuota: 0.16000,
        base: 0,
        amount: 0,
      },
      {
        enabled: false,
        tax: '003',
        satFactorType: 'rate',
        satRateOrQuota: 0.08000,
        base: 0,
        amount: 0,
      },
    ],
    retentions: [
      {
        enabled: false,
        tax: '002',
        satFactorType: 'rate',
        satRateOrQuota: 0.10000,
        base: 0,
        amount: 0,
      },
      {
        enabled: false,
        tax: '001',
        satFactorType: 'rate',
        satRateOrQuota: 0.10000,
        base: 0,
        amount: 0,
      },
    ],
  },
  tags: [],
  files: [],
}, data)

export const optionsSalesorderitems = {
  type: optionsCategories.type,
  subtype: {
    products: optionsProducts.type,
    services: optionsServices.type,
  },
  discount: [
    { value: 'percent', label: '%' },
    { value: 'number', label: '$' },
  ],
} satisfies Options

export const relationsSalesorderitems: Record<string, boolean> = {
  productitem: true,
  salesorder: true,
  serviceitem: true,
}
