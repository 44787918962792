<script setup lang="ts">
import { z } from 'zod'

const model = defineModel<Address>({ required: true })

const props = defineProps<{
  label?: string
  required?: boolean
  disabled?: boolean
}>()

const schema = z.object({
  street: z.string(),
  numberExterior: z.string(),
  city: z.string(),
  state: z.string(),
  country: z.string(),
  postalCode: z.string(),
}).required()

const FIELDSET = `w-full grid grid-cols-1 md:grid-cols-2 gap-2 !mt-4 !mb-2 p-4 border rounded-lg`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="props.disabled">
    <UFormGroup
      :label=" props.label ?? 'Dirección'"
      class="col-span-2"
    />
    <UFormGroup
      label="Calle"
      name="address.street"
      :required="props.required && isRequired(schema, 'street')"
    >
      <UInput
        v-model="model.street"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Exterior"
      name="address.numberExterior"
      :required="props.required && isRequired(schema, 'numberExterior')"
    >
      <UInput
        v-model="model.numberExterior"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Número Interior"
      name="address.numberInterior"
      :required="props.required && isRequired(schema, 'numberInterior')"
    >
      <UInput
        v-model="model.numberInterior"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Colonia"
      name="address.neighborhood"
      :required="props.required && isRequired(schema, 'neighborhood')"
    >
      <UInput
        v-model="model.neighborhood"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Ciudad"
      name="address.city"
      :required="props.required && isRequired(schema, 'city')"
    >
      <UInput
        v-model="model.city"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Delegación/Municipio"
      name="address.district"
      :required="props.required && isRequired(schema, 'district')"
    >
      <UInput
        v-model="model.district"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Estado"
      name="address.state"
      :required="props.required && isRequired(schema, 'state')"
    >
      <UInput
        v-model="model.state"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="País"
      name="address.country"
      :required="props.required && isRequired(schema, 'country')"
    >
      <SelectMenuBase
        v-model="model.country"
        :options="optionsShared.countryCode"
        value-attribute="label"
        :disabled="props.disabled"
      />
    </UFormGroup>

    <UFormGroup
      label="Código Postal"
      name="address.postalCode"
      :required="props.required && isRequired(schema, 'postalCode')"
    >
      <UInput
        v-model="model.postalCode"
        :disabled="props.disabled"
      />
    </UFormGroup>
  </fieldset>
</template>
