<script setup lang="ts">
const model = defineModel<string>()

const props = defineProps<{
  label?: string
  disabled?: boolean
}>()
</script>

<template>
  <UInput
    v-model="model"
    label="Teléfono"
    type="tel"
    :disabled="disabled"
    v-bind="$attrs"
  />
</template>
