<script setup lang="ts">
const props = defineProps<{
  src: string | undefined
  title: string
  onClickRegenerate?: () => Promise<void>
}>()

const UI_DASHBOARD_SLIDEOVER = ref<UI.DashboardSlideover>({
  title: 'text-gray-900 dark:text-white font-semibold flex items-center gap-x-1.5 min-w-0',
})

const loading = ref(false)

const rerenderKey = ref(Date.now())

const onClick = async () => {
  loading.value = true
  await props?.onClickRegenerate?.()
  loading.value = false
  rerenderKey.value = Date.now()
}
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ width: 'min-w-[60vw]', body: { padding: 'p-0' } }"
  >
    <template #title>
      <span class="truncate">{{ title }}</span>
      <UTooltip v-if="onClickRegenerate" text="Recargar">
        <UButton
          variant="ghost"
          square
          size="xs"
          icon="i-mdi-refresh"
          :loading="loading"
          @click="onClick"
        />
      </UTooltip>
    </template>
    <iframe
      :key="rerenderKey"
      class="w-full h-full border-none"
      :src="src"
    />
  </UDashboardSlideover>
</template>
