import type { Entity } from '#/shared'
import { ModalDelete, SlideoverInformation, SlideoverPreview } from '#components'
import type { ComponentProps } from 'vue-component-type-helpers'

export type TRecord = {
  id: string
  [k: string]: unknown
}

export type TRecordUtils = {
  options: Option[] | ComputedRef<Option[]>
  maps: Record<string, unknown[]> | ComputedRef<Record<string, unknown[]>>
  [k: string]: unknown
}

/**
 * A shared composable with common utilities for records
 */
export const useRecords = <T extends TRecord>(entity: Entity) => {
  const slideover = useSlideover()
  const modal = useModal()

  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<T>(entity)

  const slideoverOpenInformation = (entity: Entity, row: T) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.open(SlideoverInformation, { data: row, entityId: row.id, entity })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const slideoverOpenFilePreview = (id: string, file: { pathname: string }) => {
    return new Promise<T>((resolve, reject) => {
      try {
        slideover.open(SlideoverPreview, {
          src: new URL(`/api/blob/${file.pathname}`, window.location.origin).href,
          title: `${file.pathname.split('/').pop()}`,
          onClickRegenerate: async () => {
            try {
              const result = await onSubmitUpdate({ id, updatedAt: new Date().toISOString() } as Partial<T>, false)
              useToastAlert().success('Archivo regenerado con éxito')
              resolve(result)
            }
            catch (error) {
              useToastAlert().error('Error al regenerar el archivo')
              console.error(error)
            }
          },
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const modalOpenDelete = (props: ComponentProps<typeof ModalDelete>) => {
    return new Promise<T[]>((resolve, reject) => {
      try {
        modal.open(ModalDelete, {
          title: 'Eliminar registro',
          onSubmit: props?.onSubmit ?? (async (event) => {
            const result = await onSubmitDelete([event.data])
            modal.close()
            resolve(result)
          }),
        })
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  }
}

export const useRecord = (entity: Entity) => {
  switch (entity) {
    case 'accounts': return useAccounts()
    case 'activities': return useActivities()
    case 'apikeys': return useApikeys()
    case 'applications': return useApplications()
    case 'auditlogs': return useAuditlogs()
    // case 'authcredentials': return useAuthcredentials()
    // case 'authemailverificationcodes': return useAuthemailverificationcodes()
    // case 'authinvitations': return useAuthinvitations()
    // case 'authoauthaccounts': return useAuthoauthaccounts()
    // case 'authotps': return useAuthotps()
    // case 'authpasswordresettokens': return useAuthpasswordresettokens()
    case 'banks': return useBanks()
    case 'branches': return useBranches()
    case 'campaigns': return useCampaigns()
    case 'categories': return useCategories()
    // case 'cfdis': return useCfdis()
    case 'contacts': return useContacts()
    case 'deals': return useDeals()
    case 'financialaccounts': return useFinancialaccounts()
    case 'fulfillmentorderitems': return useFulfillmentorderitems()
    case 'fulfillmentorders': return useFulfillmentorders()
    case 'industries': return useIndustries()
    case 'inquiries': return useInquiries()
    case 'issues': return useIssues()
    case 'notes': return useNotes()
    // case 'notifications': return useNotifications()
    case 'pricelistitems': return usePricelistitems()
    case 'pricelists': return usePricelists()
    case 'productionorderitems': return useProductionorderitems()
    case 'productionorders': return useProductionorders()
    case 'productitems': return useProductitems()
    case 'products': return useProducts()
    case 'quoteitems': return useQuoteitems()
    case 'quotes': return useQuotes()
    case 'salesorderitems': return useSalesorderitems()
    case 'salesorders': return useSalesorders()
    case 'serviceitems': return useServiceitems()
    case 'services': return useServices()
    case 'tasks': return useTasks()
    case 'transactions': return useTransactions()
    case 'usernotifications': return useUsernotifications()
    case 'users': return useUsers()
    default: throw new Error(`Entity "${entity}" not found`)
  }
}

export const useRecordUtils = (entity: Entity) => {
  switch (entity) {
    case 'accounts': return useAccountsUtils()
    case 'activities': return useActivitiesUtils()
    case 'apikeys': return useApikeysUtils()
    case 'applications': return useApplicationsUtils()
    case 'auditlogs': return useAuditlogsUtils()
    // case 'authcredentials': return useAuthcredentialsUtils()
    // case 'authemailverificationcodes': return useAuthemailverificationcodesUtils()
    // case 'authinvitations': return useAuthinvitationsUtils()
    // case 'authoauthaccounts': return useAuthoauthaccountsUtils()
    // case 'authotps': return useAuthotpsUtils()
    // case 'authpasswordresettokens': return useAuthpasswordresettokensUtils()
    case 'banks': return useBanksUtils()
    case 'branches': return useBranchesUtils()
    case 'campaigns': return useCampaignsUtils()
    case 'categories': return useCategoriesUtils()
    // case 'cfdis': return useCfdisUtils()
    case 'contacts': return useContactsUtils()
    case 'deals': return useDealsUtils()
    case 'financialaccounts': return useFinancialaccountsUtils()
    case 'fulfillmentorderitems': return useFulfillmentorderitemsUtils()
    case 'fulfillmentorders': return useFulfillmentordersUtils()
    case 'industries': return useIndustriesUtils()
    case 'inquiries': return useInquiriesUtils()
    case 'issues': return useIssuesUtils()
    case 'notes': return useNotesUtils()
    // case 'notifications': return useNotificationsUtils()
    case 'pricelistitems': return usePricelistitemsUtils()
    case 'pricelists': return usePricelistsUtils()
    case 'productionorderitems': return useProductionorderitemsUtils()
    case 'productionorders': return useProductionordersUtils()
    case 'productitems': return useProductitemsUtils()
    case 'products': return useProductsUtils()
    case 'quoteitems': return useQuoteitemsUtils()
    case 'quotes': return useQuotesUtils()
    case 'salesorderitems': return useSalesorderitemsUtils()
    case 'salesorders': return useSalesordersUtils()
    case 'serviceitems': return useServiceitemsUtils()
    case 'services': return useServicesUtils()
    case 'tasks': return useTasksUtils()
    case 'transactions': return useTransactionsUtils()
    case 'usernotifications': return useUsernotificationsUtils()
    case 'users': return useUsersUtils()
    default: throw new Error(`Entity "${entity}" not found`)
  }
}
