import { defaultPayment } from '#imports'
import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultPricelistitem = (data: Partial<Pricelistitem>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  currency: defaultPayment.currency,
  tags: [],
  files: [],
}, data)

export const optionsPricelistitems = {
  currency: optionsShared.currency,
} satisfies Options

export const relationsPricelistitems: Record<string, boolean> = {
  pricelist: true,
  productitem: true,
  serviceitem: true,
}
