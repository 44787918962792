<script setup lang="ts">
const model = defineModel<string | number>()

const props = defineProps<{
  currency?: string
  icon?: boolean
}>()
</script>

<template>
  <UInput
    v-model="model"
    type="number"
    :icon="icon ? 'i-mdi-currency-usd' : undefined"
    leading
    inputmode="decimal"
    :min="0.00"
    :step="0.01"
    v-bind="$attrs"
  />
</template>
