import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultFulfillmentorderitem = (data: Partial<Fulfillmentorderitem>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'internal',
  subtype: 'delivery',
  stage: 'new',
  status: 'pending',
  priority: '2',
  approval: {
    requiresApproval: true,
    approvedBy: null,
    approvedAt: null,
    text: '',
  },
  tasks: data?.tasks ?? [],
  tags: [],
  data: { notifications: [] },
  files: [],
}, data)

export const optionsFulfillmentorderitems = {
  type: optionsFulfillmentorders.type,
  subtype: [
    { value: 'pending', label: 'Por definir', description: 'Items que aún no han sido procesados.', icon: 'i-mdi-circle-slice-1', color: 'blue' },
    { value: 'pickup', label: 'Recolección', description: 'Items para recolección en el lugar designado.', icon: 'i-mdi-package-variant', color: 'blue' },
    { value: 'delivery', label: 'Entrega', description: 'Items para la entrega de productos a la dirección del cliente.', icon: 'i-mdi-truck', color: 'amber' },
    { value: 'installation', label: 'Instalación', description: 'Items que requieren la instalación de productos en la ubicación del cliente.', icon: 'i-mdi-wrench', color: 'green' },
    { value: 'service', label: 'Servicio', description: 'Items para servicios técnicos en el lugar designado.', icon: 'i-mdi-wrench', color: 'green' },
  ],
  stage: optionsFulfillmentorders.stage,
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
} satisfies Options

export const relationsFulfillmentorderitems: Record<string, boolean> = {
  productitem: true,
  serviceitem: true,
  salesorderitem: true,
  user: true,
  fulfillmentorder: true,
}
