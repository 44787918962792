import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultApplication = (data: Partial<Application>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  industryIds: [],
  tags: [],
  files: [],
}, data)

export const optionsApplications = {} satisfies Options

export const relationsApplications: Record<string, boolean> = {}
