// adapted from https://github.com/vueuse/vueuse/issues/1592
export function useLocaleTimeAgo(locale: 'es' | 'en', date: Date) {
  // TODO: replace lodash get() with t() once 18n supported (const { t } = useI18n())

  if (locale === 'en') return useTimeAgo(date)

  return useTimeAgo(date, {
    fullDateFormatter: (date: Date) => date.toLocaleDateString(),
    messages: {
      justNow: 'justo ahora',
      past: (n: string | number) => n.toString().match(/\d/) ? `hace ${n}` : n,
      future: (n: string | number) => n.toString().match(/\d/) ? `en ${n}` : n,
      month: (n: string | number, past: boolean) =>
        n === 1
          ? past
            ? 'el mes pasado'
            : 'el próximo mes'
          : `${n} ${n === 1 ? 'mes' : 'meses'}`,
      year: (n: string | number, past: boolean) =>
        n === 1
          ? past
            ? 'el año pasado'
            : 'el próximo año'
          : `${n} ${n === 1 ? 'año' : 'años'}`,
      day: (n: string | number, past: boolean) =>
        n === 1
          ? past
            ? 'ayer'
            : 'mañana'
          : `${n} ${n === 1 ? 'día' : 'días'}`,
      week: (n: string | number, past: boolean) =>
        n === 1
          ? past
            ? 'la semana pasada'
            : 'la próxima semana'
          : `${n} ${n === 1 ? 'semana' : 'semanas'}`,
      hour: (n: string | number) => `${n} ${n === 1 ? 'hora' : 'horas'}`,
      minute: (n: string | number) => `${n} ${n === 1 ? 'minuto' : 'minutos'}`,
      second: (n: string | number) => `${n} ${n === 1 ? 'segundo' : 'segundos'}`,
      invalid: '',
    },
  })
}
