import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultBank = (data: Partial<Bank>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'active',
  address: {},
  contactIds: [],
  tags: [],
  files: [],
}, data)

export const optionsBanks = {
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsBanks: Record<string, boolean> = {
  financialaccounts: true,
}
