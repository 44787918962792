<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Bank>
  onSubmit: (event: FormSubmitEvent<Partial<Bank>>) => void
  title?: string
  disabledFields?: (keyof Bank | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Bank>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useBanks()

const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: {},
  default: () => [],
})

const utils = useBanksUtils({ $contacts })

const contacts$ = useContacts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'address',
      label: 'Dirección',
      icon: 'i-mdi-map-marker',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const loading = ref(false)

const onSubmitBank = async (event: FormSubmitEvent<Partial<Bank>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.banks"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitBank"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsBanks.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            name="image"
            label="Imagen"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `banks/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Sitio web"
            name="website"
            :required="isRequired(schema, 'website')"
          >
            <UInput
              v-model="state.website"
              type="url"
              :disabled="disabledFields?.includes('website')"
            />
          </UFormGroup>

          <UFormGroup
            label="Teléfono (sucursal)"
            name="phone"
            :required="isRequired(schema, 'phone')"
          >
            <InputPhone
              v-model="state.phone"
              :disabled="disabledFields?.includes('phone')"
            />
          </UFormGroup>

          <UFormGroup
            label="Código SWIFT"
            name="swift"
            :required="isRequired(schema, 'swift')"
          >
            <UInput
              v-model="state.swift"
              type="swift"
              :disabled="disabledFields?.includes('swift')"
            />
          </UFormGroup>

          <UFormGroup
            label="Código de banco"
            name="bankCode"
            :required="isRequired(schema, 'bankCode')"
          >
            <UInput
              v-model="state.bankCode"
              type="bankCode"
              :disabled="disabledFields?.includes('bankCode')"
            />
          </UFormGroup>

          <UFormGroup
            label="Número de sucursal"
            name="subsidiaryNumber"
            :required="isRequired(schema, 'subsidiaryNumber')"
          >
            <UInput
              v-model="state.subsidiaryNumber"
              type="subsidiaryNumber"
              :disabled="disabledFields?.includes('subsidiaryNumber')"
            />
          </UFormGroup>

          <UFormGroup
            label="Contactos"
            name="contactIds"
            :required="isRequired(schema, 'contactIds')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.contactIds"
                :options="utils.options.contactId.value"
                :disabled="disabledFields?.includes('contactIds')"
                multiple
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('contactIds')"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: { type: 'other' },
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactIds = [...state.contactIds!, contact!.id]
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `banks/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #address>
          <FieldsetAddress
            v-model="state.address"
            :required="isRequired(schema, 'address')"
            :disabled="disabledFields?.includes('address')"
          />
        </template>

        <template #data>
          <AppBanksFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.banks"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
