import type { CalendarEvent } from '@schedule-x/calendar'
import { format } from 'date-fns'
import { merge } from 'lodash-es'

export const getDefaultTask = (data: Partial<Task>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'new',
  priority: '2',
  isDatetime: false,
  dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  tags: [],
  files: [],
}, data)

export const optionsTasks = {
  status: [
    {
      value: 'new',
      label: 'Nuevo',
      description: 'La tarea es nueva y aún no ha sido programada.',
      icon: 'i-mdi-circle-outline',
      color: 'gray',
    },
    {
      value: 'scheduled',
      label: 'Programado',
      description: 'La tarea ha sido programada para ser atendida.',
      icon: 'i-mdi-dots-horizontal-circle-outline',
      color: 'red',
    },
    {
      value: 'in-progress',
      label: 'En progreso',
      description: 'La tarea está en proceso de ser completada.',
      icon: 'i-mdi-progress-clock',
      color: 'orange',
    },
    {
      value: 'waiting',
      label: 'Esperando',
      description: 'La tarea está esperando una respuesta o acción.',
      icon: 'i-mdi-clock',
      color: 'amber',
    },
    {
      value: 'stuck',
      label: 'Atorado',
      description: 'La tarea está atascada y necesita ser revisada.',
      icon: 'i-mdi-clock-alert',
      color: 'purple',
    },
    {
      value: 'done',
      label: 'Completado',
      description: 'La tarea se ha completado con éxito.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
  ],
  priority: [
    {
      value: '1',
      label: 'Baja',
      description: 'No es importante y puede esperar',
      icon: 'i-mdi-arrow-down-circle',
      color: 'green',
    },
    {
      value: '2',
      label: 'Media',
      description: 'Es importante pero puede esperar',
      icon: 'i-mdi-arrow-right-circle',
      color: 'amber',
    },
    {
      value: '3',
      label: 'Alta',
      description: 'Es importante y necesita ser atendido pronto',
      icon: 'i-mdi-arrow-up-circle',
      color: 'orange',
    },
    {
      value: '4',
      label: 'Critica',
      description: 'Es urgente, necesita ser atendido de inmediato',
      icon: 'i-mdi-alert',
      color: 'red',
    },
  ],
} satisfies Options

export const relationsTasks: Record<string, boolean> = {
  user: true,
}

// views:

export const taskToViewCalendarEvent = (calendarId: keyof Task, utils: TasksUtils) => {
  return (row: Task): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}
