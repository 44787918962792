import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultProductitem = (data: Partial<Productitem>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'active',
  fiscalData: {},
  tags: [],
  files: [],
}, data)

export const productitemToConcept = (data: Productitem & { product: Product, pricelistitems: Pricelistitem[] }): Quoteitem | Salesorderitem => {
  const { product, pricelistitems, ...productitem } = data ?? {}
  return {
    type: 'products',
    subtype: productitem.type,
    satProductServiceKey: product.fiscalData?.satProductServiceKey,
    name: productitem?.name,
    description: productitem?.description || undefined,
    image: productitem?.image ?? product.image ?? undefined,
    sku: productitem?.sku || undefined,
    satUnitKey: productitem.fiscalData?.satUnitKey,
    unitPrice: pricelistitems[0]?.basePrice,
    productitemId: productitem.id,
  }
}

export const productitemToOption = (item: Productitem & { product: Product, pricelistitems: Pricelistitem[] }): Option => {
  const label = `${item.product?.name ?? ''} | ${item.name ?? ''}`
  const src = item.image || item.product?.image

  const avatar = src
    ? { src, alt: label } // Use the available image with alt text
    : { alt: item.name?.charAt(0).toUpperCase() ?? '?' } // Use initials if no image

  return {
    label,
    value: item.id,
    avatar,
  }
}

export const optionsProductitems = {
  type: [
    { value: 'consumables', label: 'Consumibles', icon: 'i-mdi-cogs', color: 'blue', description: 'Materiales utilizados en el proceso de producción que no se integran en el producto final, como suministros de limpieza, lubricantes y equipos de seguridad.' },
    { value: 'raw-materials', label: 'Materia prima', icon: 'i-mdi-raw', color: 'yellow', description: 'Materiales básicos utilizados en la fabricación de productos.' },
    { value: 'components', label: 'Componentes', icon: 'i-mdi-apps', color: 'purple', description: 'Partes o piezas que se ensamblan para formar productos finales, como piezas mecánicas o electrónicas.' },
    { value: 'packaging', label: 'Embalaje', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Materiales utilizados para el embalaje y envío de productos, como etiquetas, cajas o cubetas.' },
    { value: 'finished-goods', label: 'Producto terminado', icon: 'i-mdi-diamond-stone', color: 'green', description: 'Productos completamente fabricados que se venden directamente al cliente final.' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsProductitems: Record<string, boolean> = {
  pricelistitems: true,
  product: true,
  salesorderitems: true,
  quoteitems: true,
}
