<script setup lang="ts">
import type { DropdownItem } from '#ui/types'

const props = defineProps<{
  notification: Notification
  dropdownItems?: DropdownItem[]
  utils: NotificationsUtils
}>()

const { user } = useUserSession()
const { moduleId } = useModules()

const { color } = props.utils.maps.type.get(props.notification.type)!
</script>

<template>
  <!-- IMPORTANT: must use CSS variables and not e.g. `border-[toHexColor(notification.userId)]`
  directly since TailwindCSS will not pick-up dynamic CSS colors outside of the SSR (on client) -->
  <div
    :id="`notification-${notification.id}`"
    class="h-min p-2 rounded-md bg-gray-100 dark:bg-gray-800"
  >
    <div class="flex items-center justify-between text-xs py-1">
      <div class="flex-1 flex items-center gap-2">
        <UIcon
          :title="utils.maps.type.get(notification.type).label"
          :name="utils.maps.type.get(notification.type).icon"
          class="w-4 h-4"
          :class="`text-${color}-500`"
        />
        <span
          :title="notification.name"
          class="font-medium text-gray-900 dark:text-white"
        >
          {{ notification.name }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <ViewRendererDatetime
          :datetime="notification.createdAt"
          exact
          display-hours
        />
        <Can
          v-if="dropdownItems?.length"
          :ability="update"
          :args="[moduleId]"
        >
          <UDropdown
            :items="dropdownItems"
            :popper="{ placement: 'auto-start' }"
            :ui="{ width: 'min-w-max' }"
          >
            <UButton
              icon="i-mdi-dots-horizontal"
              variant="ghost"
              size="2xs"
              square
            />
          </UDropdown>
        </Can>
      </div>
    </div>

    <!-- NOTE: could add e.g. `class="max-h-[500px] overflow-y-auto"` to limit card height -->
    <MarkdownRenderer :value="notification.description" />
  </div>
</template>
