<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Email>
  title?: string
  disabledFields?: (keyof Email | string)[]
  accountId?: string
}>()

const state = ref<Partial<Email>>(getDefaultEmail(props.data))

const {
  slideover,
  slideoverOpenCreate,
  schema,
  openEmailInNewTab,
} = useEmails()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { $columns: ['id', 'name', 'image', 'status', 'accountId', 'email'] },
  default: () => [],
})
const $users = await useFetch<Contact[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useEmailsUtils({ $accounts, $contacts, $users })

const contacts$ = useContacts()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'recipients',
      label: 'Destinatarios',
      icon: 'i-mdi-send',
      defaultOpen: true,
    },
    {
      slot: 'email',
      label: 'Correo',
      icon: 'i-mdi-email',
      defaultOpen: true,
    },
  ]
})

const filteredContacts = computed(() => {
  const contacts = []

  if (props.accountId) {
    const contactsByAccountId = utils.maps.contactByAccountId
    const accountContacts = contactsByAccountId.value?.get(props.accountId) || []
    const filteredAndMappedContacts = accountContacts
      .filter(contact => contact.status === 'active')
      .filter(contact => !contact.email)
      .map(contact => ({
        label: contact.name + ' | ' + contact.email,
        value: contact.email,
      }))
    contacts.push(...filteredAndMappedContacts)
  }

  // TODO: filter repeated contacts
  return contacts
})

const optionsRecipientId = [...utils.options.userId.value, ...filteredContacts.value]

const useEmailTemplate = ref<boolean>(!!state.value.templateId)

watch(() => useEmailTemplate.value, (useEmailTemplate) => {
  state.value.body = ''
})

watch(() => state.value.templateId, () => {
  const { templateId } = state.value
  if (!templateId) return
  const template = utils.maps.templateId.get(templateId)
  state.value.subject = template?.subject(props.data) ?? ''
  state.value.body = template?.body(props.data) ?? ''
}, { immediate: true })

const onSubmit = (event: FormSubmitEvent<Email>) => {
  event.data.to = state.value.to?.map(option => option.value)
  event.data.cc = state.value.cc?.map(option => option.value)
  event.data.bcc = state.value.bcc?.map(option => option.value)
  openEmailInNewTab(event.data)
}
</script>

<template>
  <UDashboardSlideover prevent-close :title="title">
    <UForm
      id="form.inquiries-create-email"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      @submit="onSubmit"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #recipients>
          <UFormGroup
            label="Para"
            name="to"
            :required="isRequired(schema, 'to')"
          >
            <UButtonGroup class="flex">
              <SelectMenuCreatableString
                v-model="state.to"
                :label-attribute="'value'"
                autofocus
                :options="optionsRecipientId"
                class="flex-1"
                :creatable="true"
              />
              <UButton
                icon="i-mdi-plus"
                @click="openNested(() => contacts$.slideoverOpenCreate({
                  data: {
                    status: 'active',
                    accountId: props.accountId,
                  },
                  onSubmit: async (event) => {
                    const contact = await contacts$.onSubmitCreate(event.data)
                    state.contactId = contact!.id
                    await $contacts.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="CC"
            name="cc"
            :required="isRequired(schema, 'cc')"
          >
            <SelectMenuCreatableString
              v-model="state.cc"
              :options="optionsRecipientId"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup
            label="BCC"
            name="bcc"
            :required="isRequired(schema, 'bcc')"
          >
            <SelectMenuCreatableString
              v-model="state.bcc"
              :options="optionsRecipientId"
              :creatable="true"
            />
          </UFormGroup>
        </template>

        <template #email>
          <UFormGroup
            label="Utilizar Plantilla"
            name="templateId"
            :required="isRequired(schema, 'templateId')"
          >
            <UButtonGroup
              class="w-full"
            >
              <UButton
                v-if="useEmailTemplate"
                icon="i-mdi-checkbox-marked"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                @click="useEmailTemplate = !useEmailTemplate"
              />
              <UButton
                v-else
                icon="i-mdi-checkbox-blank-outline"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                @click="useEmailTemplate = !useEmailTemplate"
              />
              <SelectMenuBaseInfo
                v-model="state.templateId"
                :options="utils.options.templateId"
                class="pl-1"
                :disabled="disabledFields?.includes('templateId') || !useEmailTemplate"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Asunto"
            name="subject"
            :required="isRequired(schema, 'subject')"
          >
            <UInput v-model="state.subject" />
          </UFormGroup>

          <UFormGroup
            label="Mensaje"
            name="body"
            :required="isRequired(schema, 'body')"
          >
            <UTextarea
              v-model="state.body"
              :rows="16"
              autoresize
            />
          </UFormGroup>
        </template>
      </UAccordion>
    </UForm>
    <template #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.inquiries-create-email"
          type="submit"
          label="Confirmar"
          color="primary"
          block
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
