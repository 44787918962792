<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Salesorder>
  onSubmit: (event: FormSubmitEvent<Partial<Salesorder>>) => void
  title?: string
  disabledFields?: (keyof Salesorder | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Salesorder>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileSalesorderPdf,
} = useSalesorders()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { $columns: ['id', 'name', 'image', 'billing'] },
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: () => ({ $columns: ['id', 'name', 'accountId', 'title', 'email', 'phone'] }),
  default: () => [],
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $columns: ['id', 'uid', 'image', 'accountId', 'branchId', 'userId'] },
  default: () => [],
})
const $quotes = await useFetch<Quote[]>('/api/db/quotes', {
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useSalesordersUtils({ $accounts, $branches, $contacts, $deals, $quotes, $users })

const deals$ = useDeals()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.salesorders,
      defaultOpen: true,
    },
    {
      slot: 'delivery',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y condiciones de pago',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'notes',
      label: 'Notas',
      icon: 'i-mdi-text',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateAccountId = async (id: string) => {
  const account = utils.maps.accountId.value.get(id) as Account
  if (!account) return
  state.value.receiver = { ...state.value.receiver, ...account.billing }
  state.value.payment = { ...state.value.payment, ...account.payment }
}

const onUpdateQuoteId = (quoteId: string) => {
  const quote = utils.maps.quoteId.value.get(quoteId) as Quote
  if (!quote || !quote.payment) return
  const {
    name,
    userId,
    issuer,
    receiver,
    delivery,
    accountId,
    branchId,
    contactId,
    ...quoteData
  } = quote

  const {
    options,
    ...paymentData
  } = quote.payment

  state.value.name = name
  state.value.userId = userId
  state.value.payment = paymentData
  state.value.receiver = receiver
  state.value.issuer = issuer
  state.value.delivery = delivery
  state.value.accountId = accountId
  state.value.branchId = branchId
  state.value.contactId = contactId
}

const accountInformation = computed(() => {
  return [
    ['Razón social', state.value.receiver?.legalName],
    ['RFC', state.value.receiver?.taxId],
    ['Dirección de facturación', formatAddress(state.value.receiver?.address)],
    ['Regimen fiscal', utils.maps.billing.satFiscalRegime.get(state.value.receiver?.satFiscalRegime)?.label?.toUpperCase()],
    ['Uso del CFDI', utils.maps.satCfdiUse?.get(state.value.payment?.satCfdiUse)?.label?.toUpperCase()],
  ]
})

const loading = ref(false)

const onSubmitSalesorder = async (event: FormSubmitEvent<Partial<Salesorder>>) => {
  loading.value = true
  await props.onSubmit(event)
  router.push(`/${moduleId.value}/salesorders/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.salesorders"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitSalesorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.dealId"
                :options="utils.options.dealId.value"
                :disabled="disabledFields?.includes('dealId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                @click="openNested(() => deals$.slideoverOpenCreate({
                  onSubmit: async (event) => {
                    const deal = await deals$.onSubmitCreate(event.data)
                    state.dealId = deal!.id
                    await $deals.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Cotización"
            name="quoteId"
            :required="isRequired(schema, 'quoteId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.quoteId"
                  :options="utils.maps.quoteByDealId.value.get(state.dealId)?.map(toOption) || []"
                  :disabled="disabledFields?.includes('quoteId') || !state.dealId"
                  class="flex-1"
                  @update:model-value="onUpdateQuoteId"
                />
                <UButton
                  icon="i-mdi-plus"
                  @click="openNested(() => deals$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const deal = await deals$.onSubmitCreate(event.data)
                      state.dealId = deal!.id
                      await $deals.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Campos relacionados a la cotización actualizada correctamente."
                :disabled="!state.quoteId"
                @click="onUpdateQuoteId(state.quoteId)"
              />
            </div>
          </UFormGroup>

          <UFormGroup
            label="Vendedor"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(schema, 'accountId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <InputReadOnly
                  :model-value="utils.maps.accountId.value.get(state.accountId)?.name ?? 'Selecciona una cotización'"
                  class="flex-1"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información fiscal y opciones de pago de la cotización actualizados correctamente."
                :disabled="!state.accountId"
                @click="onUpdateAccountId(state.accountId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la orden de venta"
            :entries="accountInformation"
            :default-open="true"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Fecha de recepción"
            name="date"
            :required="isRequired(schema, 'date')"
          >
            <InputDatetime
              v-model="state.date"
              :is-datetime="state.isDatetime"
              :disabled="disabledFields?.includes('date')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :required="['salesorder', 'payslip'].includes(state.type)"
              :query="{ prefix: `salesorders/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #delivery>
          <FieldsetDeliveryAddress
            v-model="state"
            :disabled="!state.accountId"
            v-bind="{ utils, disabledFields, schema, openNested, slideoverOpenEdit }"
          />
        </template>

        <template #payment>
          <InputPayment
            v-model="state.payment"
            :schema="schema"
            :disabled-fields="disabledFields"
          />
        </template>

        <template #notes>
          <InputStrings v-model="state.notes" />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :default-password="state.receiver?.taxId ?? ''"
            :cols="2"
          />
        </template>

        <template #data>
          <AppSalesordersFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.salesorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
