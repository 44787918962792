<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Quote>
  onSubmit: (event: FormSubmitEvent<Partial<Quote>>) => void
  title?: string
  disabledFields?: (keyof Quote | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Quote>>(getDefaultQuote(props.data))

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileQuotePdf,
} = useQuotes()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: { type: ['customer'] },
  default: () => [],
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: {},
  default: () => [],
})
const $branches = useFetch<Branch[]>('/api/db/branches', {
  query: () => ({ $columns: ['id', 'image', 'name', 'address'] }),
  default: () => [],
})
const $contacts = useFetch<Contact[]>('/api/db/contacts', {
  query: () => ({ $columns: ['id', 'name', 'accountId', 'title', 'email', 'phone'] }),
  default: () => [],
})
const $financialaccounts = await useFetch<Financialaccount[]>('/api/db/financialaccounts', {
  query: { type: ['bank'], $with: { bank: true } },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useQuotesUtils({ $accounts, $branches, $contacts, $deals, $financialaccounts, $users })

const deals$ = useDeals()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.quotes,
      defaultOpen: true,
    },
    {
      slot: 'message',
      label: 'Mensaje',
      icon: ICONS.inquiries,
      defaultOpen: true,
    },
    {
      slot: 'delivery',
      label: 'Entrega',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y condiciones de pago',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'notes',
      label: 'Notas',
      icon: 'i-mdi-text',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateAccountId = async (id: string) => {
  const account = utils.maps.accountId.value.get(id) as Account
  if (!account) return
  state.value.receiver = { ...state.value.receiver, ...account.billing }
  state.value.payment = { ...state.value.payment, ...account.payment }
}

const onUpdateDealId = async (dealId: string) => {
  const deal = utils.maps.dealId.value.get(dealId) as Deal
  if (!deal) return
  state.value.name = deal.name
  state.value.accountId = deal.accountId
  state.value.userId = deal.userId as string
  state.value.branchId = deal.branchId
  const account = utils.maps.accountId.value.get(deal.accountId) as Account

  if (!deal.branchId) {
    state.value.delivery.useAccountAddress = true
    state.value.delivery.address = account.billing?.address
  }
  else {
    const branch = utils.maps.branchId.value.get(deal.branchId) as Branch
    state.value.delivery.useAccountAddress = false
    state.value.delivery.address = branch.address
  }

  await onUpdateAccountId(state.value.accountId)
}

const toPaymentAccount = (financialaccountId: Financialaccount & { bank: Bank }) => {
  // ensure payment.options are complete and valid before submission
  const financialaccount = utils.maps.financialaccountId.value.get(financialaccountId)
  return {
    id: financialaccountId,
    name: financialaccount?.bank?.name ?? '',
    currency: financialaccount?.currency ?? '',
    swift: financialaccount?.bank?.swift ?? '',
    clabe: financialaccount?.clabe ?? '',
    iban: financialaccount?.iban ?? '',
    accountNumber: financialaccount?.accountNumber ?? '',
    routingNumber: financialaccount?.routingNumber ?? '',
  }
}

const accountInformation = computed(() => {
  return [
    ['Razón social', state.value.receiver?.legalName],
    ['RFC', state.value.receiver?.taxId],
    ['Dirección de facturación', formatAddress(state.value.receiver?.address)],
    ['Regimen fiscal', utils.maps.billing.satFiscalRegime.get(state.value.receiver?.satFiscalRegime)?.label?.toUpperCase()],
    ['Uso del CFDI', utils.maps.satCfdiUse?.get(state.value.payment?.satCfdiUse)?.label?.toUpperCase()],
  ]
})

const loading = ref(false)

const onSubmitQuote = async (event: FormSubmitEvent<Partial<Quote>>) => {
  loading.value = true
  if (['pending', 'EXW'].includes(event.data.delivery.incoterm)) {
    delete event.data.branchId
    event.data.delivery.address = {}
  }
  if (event.data.delivery.useAccountAddress) delete event.data.branchId

  await props.onSubmit(event)
  router.push(`/${moduleId.value}/quotes/${event.data.id}/items`)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.quotes"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitQuote"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UButtonGroup class="flex">
              <UInput
                v-model="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(schema, 'dealId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.dealId"
                  :options="utils.options.dealId.value"
                  :disabled="disabledFields?.includes('dealId')"
                  class="flex-1"
                  @update:model-value="onUpdateDealId"
                />
                <UButton
                  icon="i-mdi-plus"
                  @click="openNested(() => deals$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const deal = await deals$.onSubmitCreate(event.data)
                      state.dealId = deal!.id
                      await $deals.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Campos relacionados al negocio actualizada correctamente."
                :disabled="!state.dealId"
                @click="onUpdateDealId(state.dealId)"
              />
            </div>
          </UFormGroup>

          <UFormGroup
            label="Vendedor"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            required
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <InputReadOnly
                  :model-value="utils.maps.accountId.value.get(state.accountId)?.name ?? 'Selecciona un negocio'"
                  class="flex-1"
                />
                <UButton icon="i-mdi-plus" disabled />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información fiscal y opciones de pago de la cotización actualizados correctamente."
                :disabled="!state.accountId"
                @click="onUpdateAccountId(state.accountId)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            title="Información de la cotización"
            :entries="accountInformation"
            :default-open="true"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.issueDate"
            v-model:date-end="state.expirationDate"
            :is-required-start-date="isRequired(schema, 'issueDate')"
            :is-required-end-date="isRequired(schema, 'expirationDate')"
            :start-date-label="'Fecha de emisión'"
            :end-date-label="'Fecha de vencimiento'"
            :disabled-fields="['isDatetime']"
          />

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `quotes/${state.id}/files` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Detalles"
            name="text"
            :required="isRequired(schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #message>
          <UFormGroup
            label="Dedicatoria"
            class="w-full"
            :required="state.message!.enabled"
          >
            <UButtonGroup class="flex">
              <UButton
                :icon="`${state.message!.enabled ? 'i-mdi-checkbox-marked' : 'i-mdi-checkbox-blank-outline'}`"
                square
                variant="outline"
                :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
                @click="() => state.message!.enabled = !state.message!.enabled"
              />
              <UInput
                v-model="state.message!.subject"
                placeholder="Estimado cliente"
                class="flex-1"
                :required="state.message!.enabled"
              />
            </UButtonGroup>
          </UFormGroup>
          <UFormGroup
            label="Mensaje"
            name="message.body"
            :required="state.message!.enabled"
          >
            <UTextarea
              v-model="state.message!.body"
              autoresize
              :required="state.message!.enabled"
            />
          </UFormGroup>
        </template>

        <template #delivery>
          <FieldsetDeliveryAddress
            v-model="state"
            :disabled="!state.accountId"
            v-bind="{ utils, disabledFields, schema, openNested, slideoverOpenEdit }"
          />
        </template>

        <template #payment>
          <InputPayment
            v-model="state.payment"
            :schema="schema"
            :disabled-fields="disabledFields"
          />

          <UFormGroup
            label="Cuentas"
            name="paymentAccounts"
            :required="isRequired(schema, 'paymentAccounts')"
          >
            <SelectMenuBase
              :model-value="state.paymentAccounts?.map(account => account.id) ?? []"
              :options="utils.options.financialaccountId.value"
              :disabled="disabledFields?.includes('paymentAccounts') || state.payment?.satPaymentForm !== '03'"
              multiple
              @update:model-value="value => state.paymentAccounts = value.map(id => toPaymentAccount(id))"
            />
          </UFormGroup>
        </template>

        <template #notes>
          <InputStrings v-model="state.notes" />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings
            v-model="state!.pdfSettings"
            :default-password="state.receiver?.taxId ?? ''"
            :cols="2"
          />
        </template>

        <template #data>
          <AppQuotesFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.quotes"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
