<script setup lang="ts">
const props = defineProps<{
  title?: string
  toast?: string
}>()

const emit = defineEmits(['click'])

const onClick = () => {
  emit('click')
  useToastAlert().success({
    title: `Actualización exitosa`,
    description: props.toast ?? 'La información ha sido actualizada correctamente',
  })
}
</script>

<template>
  <UButton
    :title="title ?? 'Actualizar'"
    icon="i-mdi-refresh"
    variant="outline"
    square
    :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
    @click="onClick"
  />
</template>
