import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultCategory = (data: Partial<Category>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'products',
  tags: [],
  files: [],
}, data)

export const optionsCategories = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Bienes tangibles que se pueden vender.' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'purple', description: 'Actividades intangibles que se ofrecen para satisfacer necesidades, como asesoría, mantenimiento, y formación.' },
  ],
} satisfies Options

export const relationsCategories: Record<string, boolean> = {
  products: true,
  services: true,
}
