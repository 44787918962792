<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { parseISO } from 'date-fns'

const props = defineProps<{
  data: Partial<Productionorderitem>
  onSubmit: (event: FormSubmitEvent<Partial<Productionorderitem>>) => void
  title?: string
  disabledFields?: (keyof Productionorderitem | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Productionorderitem>>(getDefaultProductionorderitem(props.data))

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileProductionorderitemPdf,
} = useProductionorderitems()

const $salesorders = await useFetch<Salesorder[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $productionorders = await useFetch<Productionorder[]>('/api/db/productionorders', {
  query: { $columns: ['id', 'uid', 'salesorderId', 'userId'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useProductionorderitemsUtils({ $salesorders, $productionorders, $users })

const productionorders$ = useProductionorders()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.productionorderitems,
      defaultOpen: true,
    },
    {
      slot: 'quality',
      label: 'Control de Calidad',
      icon: 'i-mdi-check-decagram',
      defaultOpen: true,
    },
    {
      slot: 'tasks',
      label: 'Tareas',
      icon: 'mdi:checkbox-multiple-marked-outline',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateProductionorderId = (id: string) => {
  const productionorder = utils.maps.productionorderId.value.get(id)
  state.value.userId = productionorder?.userId
}

const informationSalesorder = computed(() => {
  const productionorder = utils.maps.productionorderId.value.get(state.value.productionorderId)
  const salesorder = utils.maps.salesorderId.value.get(productionorder?.salesorderId)
  if (!salesorder) return []
  const user = salesorder?.user?.name
  const account = salesorder?.account?.name
  const localDate = parseISO(salesorder.delivery?.date)
  const delivery = !salesorder.delivery?.isDatetime ? localDate.toLocaleDateString() : localDate.toLocaleString()
  return [
    ['Orden de Venta', salesorder.uid],
    ['Vendedor', user],
    ['Cliente', account],
    ['Fecha de entrega', delivery],
  ]
})

const loading = ref(false)
const onSubmitProductionorderitem = async (event: FormSubmitEvent<Partial<Productionorderitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.productionorderitems"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitProductionorderitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de Producción"
            name="productionorderId"
            :required="isRequired(schema, 'productionorderId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.productionorderId"
                  :options="utils.options.productionorderId.value"
                  :disabled="disabledFields?.includes('productionorderId') || !state.type"
                  class="flex-1"
                  @update:model-value="onUpdateProductionorderId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('productionorderId')"
                  @click="openNested(() => productionorders$.slideoverOpenCreate({
                    onSubmit: async (event) => {
                      const productionorder = await productionorders$.onSubmitCreate(event.data)
                      state.productionorderId = productionorder!.id
                      await $productionorders.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información relacionada a la orden de producción actualizada correctamente."
                :disabled="!state.productionorderId"
                @click="onUpdateProductionorderId(state.productionorderId!)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.type === 'external' || state.productionorderId"
            title="Información asociada a la orden de venta"
            :default-open="true"
            :entries="informationSalesorder"
          />

          <FieldsetOpsConcept
            v-model="state"
            :salesorder-id="utils.maps.productionorderId.value.get(state.productionorderId)?.salesorderId"
            entity="productionorderitems"
            :disabled="disabledFields?.includes('productitemId')"
            v-bind="{ data, onSubmit, schema, title, disabledFields, slideoverOpenEdit }"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId') || !state.productionorderId"
            />
          </UFormGroup>

          <UFormGroup
            label="Cantidad (plan)"
            name="quantityPlanned"
            :required="isRequired(schema, 'quantityPlanned')"
          >
            <UInput
              v-model="state.quantityPlanned"
              type="number"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('quantityPlanned')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cantidad (real)"
            name="quantityActual"
            :required="isRequired(schema, 'quantityActual')"
          >
            <UInput
              v-model="state.quantityActual"
              type="number"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('quantityActual')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `productionorderitems/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #quality>
          <div class="grid grid-cols-2 md:grid-cols-3 gap-4 mb-4">
            <div
              v-for="(item, index) in state.qualityAssurance.items"
              :key="`item-${index}`"
              class="flex items-center gap-1"
            >
              <UButton
                variant="ghost"
                size="sm"
                :icon="!item.resolved ? 'i-mdi-checkbox-blank-outline' : 'i-mdi-checkbox-marked'"
                @click="() => item.resolved = !item.resolved"
              />
              <span class="flex-1 block font-medium text-gray-700 dark:text-gray-200">
                {{ item.label }}
              </span>
            </div>
          </div>

          <UFormGroup
            label="Aprobado por"
            name="qualityAssurance.approvedBy"
            :required="isRequired(schema, 'qualityAssurance.approvedBy')"
          >
            <SelectMenuBase
              v-model="state.qualityAssurance.approvedBy"
              :options="utils.options.userId.value"
              multiple
              :disabled="disabledFields?.includes('qualityAssurance.qualityAssurance.approvedBy')"
              @update:model-value="state.qualityAssurance.approvedAt = new Date().toISOString()"
            />
          </UFormGroup>

          <UFormGroup
            label="Fecha de aprobación"
            name="qualityAssurance.approvedAt"
          >
            <InputDatetime
              v-model="state.qualityAssurance.approvedAt"
              :is-datetime="state.qualityAssurance.isDatetime"
              :disabled="disabledFields?.includes('qualityAssurance.approvedAt')"
              class="flex-1"
            />
          </UFormGroup>
        </template>

        <template #tasks>
          <InputChecklist v-model="state.tasks" />
        </template>

        <template #data>
          <AppProductionorderitemsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.productionorderitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
