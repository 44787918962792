<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'lodash-es'

const props = defineProps<{
  data: Partial<Quoteitem>
  onSubmit: (event: FormSubmitEvent<Partial<Quoteitem>>) => void
  title?: string
  disabledFields?: (keyof Quoteitem | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Quoteitem>>(getDefaultQuoteitem(props.data))

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileQuoteitemPdf,
} = useQuoteitems()

const $products = await useFetch<Product[]>('/api/db/products', {
  query: { $with: { category: true } },
  default: () => [],
})
const $productitems = await useFetch<Productitem[]>('/api/db/productitems', {
  query: { $with: { product: true, pricelistitems: true } },
  default: () => [],
})
const $services = await useFetch<Service[]>('/api/db/services', {
  query: { $with: { category: true } },
  default: () => [],
})
const $serviceitems = await useFetch<Serviceitem[]>('/api/db/serviceitems', {
  query: { $with: { service: true, pricelistitems: true } },
  default: () => [],
})

const utils = useQuoteitemsUtils({ $products, $productitems, $services, $serviceitems })

const products$ = useProducts()
const productitems$ = useProductitems()
const services$ = useServices()
const serviceitems$ = useServiceitems()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.quoteitems,
      defaultOpen: true,
    },
    {
      slot: 'taxes',
      label: 'Impuestos',
      icon: 'i-mdi-scale-balance',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const parentId = ref<string | undefined>(undefined)
const selectedItemId = props.data.productitemId ?? props.data.serviceitemId
if (selectedItemId) {
  parentId.value = props.data.productitemId
    ? utils.maps.productitemId.value.get(selectedItemId)?.product.id
    : utils.maps.serviceitemId.value.get(selectedItemId)?.service.id
}

const onUpdateProductitemId = (id: string) => {
  const productitem = utils.maps.productitemId?.value.get(id)
  state.value = merge(state.value, productitemToConcept(productitem))
}
const onUpdateServiceitemId = (value: string) => {
  const serviceitem = utils.maps.serviceitemId?.value.get(value)
  state.value = merge(state.value, serviceitemToConcept(serviceitem))
}

watch(() => state.value!.type!, (newType) => {
  const { delivery, quoteId } = state.value
  state.value = getDefaultQuoteitem({ type: newType, delivery, quoteId })
  parentId.value = undefined
})

watch(() => state.value.fiscalData?.satTaxObject, (satTaxObject) => {
  const disable = ({ enabled, ...rest }) => ({ enabled: false, ...rest })
  // disable all taxes if satTaxObject is "01 - Sin objeto de impuestos"
  if (['01'].includes(satTaxObject)) {
    state.value.taxes.transfers = state.value.taxes.transfers.map(disable)
    state.value.taxes.retentions = state.value.taxes.retentions.map(disable)
  }
})

const loading = ref(false)

const onSubmitQuoteitem = async (event: FormSubmitEvent<Partial<Quoteitem>>) => {
  loading.value = true
  event.data.total = event.data.amount + event.data.taxes.transfers
    .reduce((acc, tax) => acc + tax.amount, 0) - event.data.taxes.retentions
    .reduce((acc, tax) => acc + tax.amount, 0)
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[50vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.quoteitems"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitQuoteitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-2">
            <UFormGroup
              label="Tipo"
              name="type"
              :required="isRequired(schema, 'type')"
            >
              <SelectMenuBaseInfo
                v-model="state.type"
                :options="utils.options.type"
                autofocus
                :disabled="disabledFields?.includes('type')"
              />
            </UFormGroup>

            <UFormGroup
              :label="`Tipo de ${state.type === 'products' ? 'producto' : 'servicio'}`"
              name="subtype"
              :required="isRequired(schema, 'subtype')"
            >
              <SelectMenuBaseInfo
                v-if="state.type === 'products'"
                v-model="state.subtype"
                :options="optionsProducts.type"
                :disabled="disabledFields?.includes('subtype')"
              />
              <SelectMenuBaseInfo
                v-else
                v-model="state.subtype"
                :options="optionsServices.type"
                :disabled="disabledFields?.includes('subtype')"
              />
            </UFormGroup>

            <UFormGroup
              :label="`${state.type === 'products' ? 'Producto' : 'Servicio'}`"
              name="parentId"
              required
            >
              <UButtonGroup class="flex">
                <SelectMenuBase
                  v-if="state.type === 'products'"
                  v-model="parentId"
                  :options="utils.maps.productsByType?.value.get(state?.subtype)?.map(toOption) ?? []"
                  :disabled="!state.subtype"
                  class="flex-1"
                />
                <SelectMenuBase
                  v-else
                  v-model="parentId"
                  :options="utils.maps.servicesByType?.value.get(state?.subtype)?.map(toOption) ?? []"
                  :disabled="!state.subtype"
                  class="flex-1"
                />
                <UButton
                  v-if="state.type === 'products'"
                  icon="i-mdi-plus"
                  :disabled="!state.subtype"
                  @click="openNested(() => products$.slideoverOpenCreate({
                    data: { type: state.subtype },
                    disabledFields: ['type'],
                    onSubmit: async (event) => {
                      const product = await products$.onSubmitCreate(event.data)
                      parentId = product!.id
                      await $products.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
                <UButton
                  v-else
                  icon="i-mdi-plus"
                  :disabled="!state.subtype"
                  @click="openNested(() => services$.slideoverOpenCreate({
                    data: { type: state.subtype },
                    disabledFields: ['type'],
                    onSubmit: async (event) => {
                      const service = await services$.onSubmitCreate(event.data)
                      parentId = service!.id
                      await $services.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
            </UFormGroup>

            <UFormGroup
              label="Item"
              :name="`${state.type === 'products' ? 'productitemId' : 'serviceitemId'}`"
              required
            >
              <div class="flex gap-1">
                <UButtonGroup class="flex-1">
                  <SelectMenuBase
                    v-if="state.type === 'products'"
                    v-model="state.productitemId"
                    :options="utils.maps.productitemsByProductId?.value.get(parentId)?.map(productitemToOption) ?? []"
                    class="flex-1"
                    :disabled="disabledFields?.includes('productitemId') || !parentId"
                    @update:model-value="onUpdateProductitemId"
                  />
                  <SelectMenuBase
                    v-else
                    v-model="state.serviceitemId"
                    :options="utils.maps.serviceitemsByServiceId?.value.get(parentId)?.map(serviceitemToOption) ?? []"
                    class="flex-1"
                    :disabled="disabledFields?.includes('serviceitemId') || !parentId"
                    @update:model-value="onUpdateServiceitemId"
                  />
                  <UButton
                    v-if="state.type === 'products'"
                    icon="i-mdi-plus"
                    :disabled="disabledFields?.includes('productitemId') || !parentId"
                    @click="openNested(() => productitems$.slideoverOpenCreate({
                      data: {
                        type: state.subtype,
                        productId: parentId,
                      },
                      disabledFields: ['type', 'productId'],
                      onSubmit: async (event) => {
                        const productitem = await productitems$.onSubmitCreate(event.data)
                        state.productitemId = productitem!.id
                        await $productitems.refresh()
                        await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                      },
                    }))"
                  />
                  <UButton
                    v-else
                    icon="i-mdi-plus"
                    :disabled="disabledFields?.includes('serviceitemId') || !parentId"
                    @click="openNested(() => serviceitems$.slideoverOpenCreate({
                      data: {
                        type: state.subtype,
                        productId: parentId,
                      },
                      disabledFields: ['type', 'productId'],
                      onSubmit: async (event) => {
                        const serviceitem = await serviceitems$.onSubmitCreate(event.data)
                        state.serviceitem = serviceitem!.id
                        await $serviceitems.refresh()
                        await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                      },
                    }))"
                  />
                </UButtonGroup>
                <ButtonRefresh
                  v-if="state.type === 'products'"
                  @click="onUpdateProductitemId(state.productitemId)"
                />
                <ButtonRefresh
                  v-else
                  @click="onUpdateServiceitemId(state.serviceitemId)"
                />
              </div>
            </UFormGroup>
          </div>
        </template>

        <template #details>
          <FieldsetSalesConcept
            v-model="state"
            resource="quoteitems"
            :disabled="disabledFields?.includes('concepts')"
            v-bind="{ utils, schema, disabledFields }"
          />
        </template>

        <template #taxes>
          <FieldsetTaxesMX
            v-model="state"
            :disabled="disabledFields?.includes('taxes')"
            v-bind="{ utils, schema, disabledFields }"
          />
        </template>

        <template #data>
          <AppQuoteitemsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.quoteitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
