import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultIndustry = (data: Partial<Industry>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  tags: [],
  files: [],
}, data)

export const optionsIndustries = {} satisfies Options

export const relationsIndustries: Record<string, boolean> = {
  accounts: true,
}
