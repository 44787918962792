import { createId, createUid, type Options } from '#netzo/utils/app/index'
import { format } from 'date-fns'
import { merge } from 'lodash-es'
import type { Content, ContentColumns, TDocumentDefinitions, TDocumentInformation } from 'pdfmake/interfaces'

const uid = createUid(new Date(), 'LP')

export const getDefaultPricelist = (data: Partial<Pricelist>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'products',
  status: 'pending',
  uid: uid,
  name: uid,
  date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  pdfSettings: merge(defaultPdfSettings, { watermark: { text: 'ESGRAF' } }),
  tags: [],
  files: [],
}, data)

export const optionsPricelists = {
  type: [
    { value: 'products', label: 'Productos', icon: 'i-mdi-package-variant-closed', color: 'sky', description: 'La lista de precios es de productos' },
    { value: 'services', label: 'Servicios', icon: 'i-mdi-tools', color: 'orange', escription: 'La lista de precios es de servicios' },
  ],
  status: optionsSharedApp.statusProcess,
} satisfies Options

export const relationsPricelists: Record<string, boolean> = {
  pricelistitems: true,
}

export const createPdfmakeDocumentDefinitionPricelists = async (
  data: Pricelist,
  utils: PricelistsUtils,
): Promise<TDocumentDefinitions> => {
  // see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
  const { baseUrl } = useRuntimeConfig().public // new URL('/api/blob/...', baseUrl).href

  data.data ??= {}
  data.pricelistitems ??= []

  // sections:

  const info: TDocumentInformation = {
    title: `${data.uid}`,
    subject: 'Lista de precios La Tinta',
    keywords: 'diseño, agencia, publicidad',
    author: 'LA TINTA',
    producer: 'Netzo (https://netzo.dev)',
  }

  const sectionHeader: ContentColumns = {
    columns: [
      { image: SYMBOL_BASE64, height: 90, width: 90 },
      {
        stack: [
          { text: 'Lista de Precios', fontSize: 18, alignment: 'right', margin: [0, 0, 0, 5] },
          { text: [{ text: 'Referencia: ', bold: true }, `${data.uid.toUpperCase()}`], alignment: 'right' },
          { text: [{ text: 'Fecha de entrada en vigor: ', bold: true }, new Date(data.date).toLocaleDateString()], alignment: 'right' },
          { text: [{ text: 'Tipo: ', bold: true }, utils.maps.type.get(data.type).label], alignment: 'right' },
          { text: [{ text: 'Estado: ', bold: true }, utils.maps.status.get(data.status).label], alignment: 'right' },
        ],
        margin: [0, 10],
      },
    ],
    margin: [0, 0, 0, 5],
  }

  const sectionPricelistitems: Content = {
    table: {
      headerRows: 1,
      widths: data.pdfSettings.images ? ['auto', 'auto', '*', 'auto', 'auto'] : ['auto', '*', 'auto', 'auto'],
      body: [
        [
          { text: 'SKU', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          ...data.pdfSettings.images ? [{ text: 'Imagen', bold: true, fillColor: '#aaa', fillOpacity: 0.25 }] : [],
          { text: 'Item(s)', bold: true, fillColor: '#aaa', fillOpacity: 0.25 },
          { text: 'Unidad (SAT)', bold: true, fillColor: '#aaa', fillOpacity: 0.25, alignment: 'center' },
          { text: 'Precio U', bold: true, alignment: 'right', fillColor: '#aaa', fillOpacity: 0.25 },
        ],
        ...(await Promise.all(data.pricelistitems.map(async (pricelistitem: Pricelistitem) => [
          { text: pricelistitem.basePrice || '', alignment: 'center', margin: [0, 5, 0, 0] },
          ...(data.pdfSettings.images ? [pricelistitem.image ? { image: await getBase64ImageFromURL(pricelistitem.image), width: 40, height: 40, margin: [5, 5, 5, 5] } : { text: '' }] : []),
          { text: pricelistitem.basePrice || '', alignment: 'center', margin: [0, 5, 0, 0] },
          { text: pricelistitem.basePrice || '', alignment: 'center', margin: [0, 5, 0, 0] },
          { text: pricelistitem.basePrice || '', alignment: 'center', margin: [0, 5, 0, 0] },
        ]))),
      ].filter(Boolean),
    },
    layout: 'noBorders',
  }

  return {
    language: 'es-ES',
    userPassword: data.pdfSettings.password?.enabled ? data.pdfSettings.password?.value : undefined,
    watermark: data.pdfSettings.watermark?.enabled
      ? {
          text: data.pdfSettings.watermark.text,
          color: data.pdfSettings.watermark.color,
          bold: data.pdfSettings.watermark.bold,
          italics: data.pdfSettings.watermark.italic,
          opacity: 0.05,
        }
      : undefined,
    info,
    content: [
      sectionHeader,
      sectionPricelistitems,
    ],
    footer: (currentPage, pageCount, pageSize) => {
      return {
        columns: [
          { image: LOGO_BASE64, fit: [80, 20], margin: [20, 10, 0, 0] },
          {
            stack: [
              {
                text: [
                  { text: 'Generado con ' },
                  { text: 'netzo.dev', link: 'https://netzo.dev', color: 'blue' },
                ],
              },
            ],
            alignment: 'center',
            margin: [0, 10, 0, 0],
          },
          { text: `Página ${currentPage.toString()} de ${pageCount}`, alignment: 'right', margin: [0, 10, 20, 0] },
        ],
        widths: ['auto', '*', 'auto'],
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 10, 5],
      },
    },
    defaultStyle: {
      fontSize: 8,
    },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}
