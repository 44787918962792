import { AppPricelistitemsSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppPricelistitemsSlideoverProps = ComponentProps<typeof AppPricelistitemsSlideover>

export type PricelistitemsUtils = ReturnType<typeof usePricelistitemsUtils>

// view:

export const VIEW_SETTINGS_PRICELISTITEMS: ViewSettings<Pricelistitem> = {
  tableName: 'pricelistitems',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: true,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: false, // cannot sort by product.name or service.name
      // rowClass: 'pointer-events-none', // disable clickable link but keep styles
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'basePrice',
      label: 'Precio U',
      sortable: true,
    },
    {
      key: 'basePriceLatest',
      label: 'Último Precio U',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'updatedAtLatest',
      label: 'Fecha Última Precio U',
      sortable: true,
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const usePricelistitems = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Pricelistitem>('pricelistitems')

  const slideoverOpenCreate = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem>((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Crear precio',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppPricelistitemsSlideoverProps) => {
    return new Promise<Pricelistitem > ((resolve, reject) => {
      const data = getDefaultPricelistitem(props.data)
      slideover.open(AppPricelistitemsSlideover, {
        title: 'Editar precio',
        ...props,
        data,
        disabledFields: ['productitemId', 'serviceitemId'],
        pricelistType: data.productitemId ? 'products' : 'services',
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    currency: z.string(),
    pricelistId: z.string(),
  }).required()

  // actions:

  const getItem = (row: Pricelistitem): Productitem | Serviceitem => {
    return row?.productitem ?? row?.serviceitem
  }

  const getDropdownItems = (row: Pricelistitem, refresh: () => Promise<void>, utils: PricelistitemsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['productitemId', 'serviceitemId'],
            pricelistType: row.productitemId ? 'products' : 'services',
            optionsItemsFiltered: [toOption(getItem(row))],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'pricelistitems', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Resource Actions
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('pricelistitems', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        disabled: row?.pricelist?.$inmutable,
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getItem,
    getDropdownItems,
  }
}

export const usePricelistitemsUtils = ({ $productitems, $serviceitems }: {
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsPricelistitems,
    currency: optionsPricelistitems.currency,
    productitemId: computed(() => $productitems.data.value.map(toOption)),
    serviceitemId: computed(() => $serviceitems.data.value.map(toOption)),
  }

  const maps = {
    currency: toMapByKey(optionsPricelistitems.currency, 'value'),
    productitemId: computed(() => toMapByKey($productitems.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems.data.value, 'id')),
  }

  return { options, maps }
}
