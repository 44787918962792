<script setup lang="ts">
import type { BlobPutOptions } from '@nuxthub/core'
import { vElementHover } from '@vueuse/components'

// NOTE: only accept PDFMake-supported types PNG/JPEG
// to avoid issues later on when generating PDFs
const ACCEPT = 'image/png,image/jpeg'

const model = defineModel<string>({ default: '' })

const props = defineProps<{
  query?: BlobPutOptions
}>()

const { renameFile, uploadFile, removeFile } = useApiBlob()

const rerenderKey = ref(Date.now())

const onChange = async (fileList: FileList) => {
  const file = fileList[0]!
  if (!file) return

  // DISABLED: do not enforce file maxSize limit
  // if (file.size > 1e6) return alert('Imagen excede el tamaño máximo permitido de 1MB')

  const newFile = renameFile(file, 'image') // force filename to `image` for simpler access

  if (model.value) await removeFile({ pathname: model.value.replace('/api/blob/', '') }) // remove existing (previous model value)
  const blobObject = await uploadFile(newFile, props.query)
  model.value = `/api/blob/${blobObject.pathname}` // manually set new model value
  rerenderKey.value = Date.now() // force re-render to update avatar
}

const isHovered = ref(false)

const onClickRemove = async () => {
  if (model.value) {
    await removeFile({ pathname: model.value.replace('/api/blob/', '') })
    model.value = ''
    document.getElementById('input-image').value = null
  }
}

const avatarProps = computed(() => {
  if (model.value && isHovered.value) return { icon: 'i-mdi-image-remove', class: 'cursor-pointer' }
  else if (model.value && !isHovered.value) return { src: model.value }
  else return { icon: 'i-mdi-image' }
})
</script>

<template>
  <div class="flex items-center gap-3">
    <div class="flex-1 w-full relative flex items-center gap-2">
      <UInput
        id="input-image"
        v-bind="$attrs"
        type="file"
        name="file"
        :accept="ACCEPT"
        icon="i-mdi-image"
        class="flex-1 w-full"
        @change="onChange"
      />
    </div>

    <div class="-my-2" @click="onClickRemove">
      <UAvatar
        :key="rerenderKey"
        v-element-hover="(state: boolean) => isHovered = state"
        v-bind="avatarProps"
        size="md"
      />
    </div>
  </div>
</template>
