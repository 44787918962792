import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultBranch = (data: Partial<Branch>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'active',
  address: {},
  tags: [],
  files: [],
}, data)

export const optionsBranches = {
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsBranches: Record<string, boolean> = {
  account: true,
  activities: true,
  contacts: true,
  deals: true,
  fulfillmentorders: true,
  inquiries: true,
}
