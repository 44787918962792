import { merge } from 'lodash-es'

export const getDefaultIssue = (data: Partial<Issue>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'enhancement',
  status: 'new',
  priority: '2',
  tags: [],
  files: [],
}, data)

export const optionsIssues = {
  type: [
    {
      value: 'type: idea',
      label: 'Propuesta de una idea o sugerencia',
      icon: 'i-mdi-lightbulb',
      color: 'cyan',
    },
    {
      value: 'type: feature',
      label: 'Propuesta de una nueva funcionalidad',
      icon: 'i-mdi-star',
      color: 'green',
    },
    {
      value: 'type: question',
      label: 'Pregunta o duda sobre la aplicación',
      icon: 'i-mdi-help',
      color: 'fuchsia',
    },
    {
      value: 'type: enhancement',
      label: 'Mejora de una funcionalidad existente',
      icon: 'i-mdi-shimmer',
      color: 'emerald',
    },
    {
      value: 'type: bug',
      label: 'Reporte de un error o bug',
      icon: 'i-mdi-bug',
      color: 'red',
    },
  ],
  status: [
    {
      value: 'new',
      label: 'Nuevo',
      description: 'El ticket es nuevo y aún no ha sido programado.',
      icon: 'i-mdi-circle-outline',
      color: 'gray',
    },
    {
      value: 'scheduled',
      label: 'Programado',
      description: 'El ticket ha sido programado para ser atendido.',
      icon: 'i-mdi-dots-horizontal-circle-outline',
      color: 'red',
    },
    {
      value: 'in-progress',
      label: 'En progreso',
      description: 'El ticket está en proceso de ser completado.',
      icon: 'i-mdi-progress-clock',
      color: 'orange',
    },
    {
      value: 'waiting',
      label: 'Esperando',
      description: 'El ticket está esperando una respuesta o acción.',
      icon: 'i-mdi-clock',
      color: 'amber',
    },
    {
      value: 'stuck',
      label: 'Atorado',
      description: 'El ticket está atascado y necesita ser revisado.',
      icon: 'i-mdi-clock-alert',
      color: 'purple',
    },
    {
      value: 'done',
      label: 'Completado',
      description: 'El ticket se ha completado con éxito.',
      icon: 'i-mdi-check-circle',
      color: 'green',
    },
  ],
  priority: [
    {
      value: '1',
      label: 'Baja',
      description: 'No es importante y puede esperar',
      icon: 'i-mdi-arrow-down-circle',
      color: 'green',
    },
    {
      value: '2',
      label: 'Media',
      description: 'Es importante pero puede esperar',
      icon: 'i-mdi-arrow-right-circle',
      color: 'amber',
    },
    {
      value: '3',
      label: 'Alta',
      description: 'Es importante y necesita ser atendido pronto',
      icon: 'i-mdi-arrow-up-circle',
      color: 'orange',
    },
    {
      value: '4',
      label: 'Critica',
      description: 'Es urgente, necesita ser atendido de inmediato',
      icon: 'i-mdi-alert',
      color: 'red',
    },
  ],
} satisfies Options

export const relationsIssues: Record<string, boolean> = {
  user: true,
}
