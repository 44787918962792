import { createId, createUid, type Options } from '#netzo/utils/app/index'
import { format } from 'date-fns'
import { id } from 'date-fns/locale'
import { merge } from 'lodash-es'

const uid = createUid(new Date(), 'TRA')

export const getDefaultTransaction = (data: Partial<Transaction>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'input',
  uid: uid,
  name: uid,
  accountingDate: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  valueDate: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  method: 'bank-transfer',
  tags: [],
  files: [],
}, data)

export const optionsTransactions = {
  type: [
    { value: 'input', label: 'Entrada', icon: 'i-mdi-arrow-down-circle', color: 'green', description: 'Transacción que representa un ingreso de dinero a tu cuenta o fondo. Esto puede incluir depósitos, pagos recibidos, o la disposición de fondos provenientes de una línea de crédito. En otras palabras, es cualquier aumento en el saldo de tu cuenta o disponibilidad de fondos.' },
    { value: 'output', label: 'Salida', icon: 'i-mdi-arrow-up-circle', color: 'red', description: 'Transacción que representa una salida de dinero de tu cuenta o fondo. Esto incluye gastos, pagos realizados, o retiros de una línea de crédito. Es cualquier disminución en el saldo de tu cuenta o en la disponibilidad de fondos.' },
  ],
  category: [
    { value: 'sales-revenue', label: 'Ingresos por ventas', color: 'green', description: 'Ingresos generados por la venta de productos o servicios.' },
    { value: 'other-income', label: 'Otros ingresos', color: 'teal', description: 'Ingresos provenientes de fuentes distintas a las operaciones principales del negocio, como retornos de inversiones.' },
    { value: 'cogs', label: 'Costo de ventas (COGS)', color: 'orange', description: 'Costos directos asociados con la producción de bienes vendidos.' },
    { value: 'opex', label: 'Gastos operativos (OPEX)', color: 'purple', description: 'Gastos incurridos en el curso normal de las operaciones del negocio, excluyendo el COGS.' },
    { value: 'capex', label: 'Gastos de capital (CAPEX)', color: 'blue', description: 'Inversiones en activos a largo plazo, como equipos o propiedades.' },
    { value: 'taxes', label: 'Impuestos', color: 'red', description: 'Pagos realizados a autoridades fiscales gubernamentales.' },
    { value: 'debt-repayment', label: 'Pago de deuda', color: 'gray', description: 'Pagos realizados hacia el reembolso de préstamos o líneas de crédito.' },
    { value: 'dividends', label: 'Dividendos', color: 'red', description: 'Pagos realizados a los accionistas a partir de las ganancias.' },
    { value: 'other', label: 'Otros', color: 'gray', description: 'Transacciones que no encajan en ninguna de las categorías anteriores.' },
  ],
  subcategory: {
    'sales-revenue': [
      { value: 'product-sales', label: 'Productos', color: 'green' },
      { value: 'service-sales', label: 'Servicios', color: 'blue' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'other-income': [
      { value: 'investment-returns', label: 'Retornos de Inversión', color: 'green' },
      { value: 'one-time-income', label: 'Ingresos Únicos', color: 'orange' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'cogs': [
      { value: 'raw-materials', label: 'Materias Primas', color: 'orange' },
      { value: 'manufacturing-labor', label: 'Mano de Obra', color: 'purple' },
      { value: 'production-overheads', label: 'Costos Indirectos', color: 'blue' },
      { value: 'packaging', label: 'Embalaje', color: 'green' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'opex': [
      { value: 'salaries', label: 'Salarios', color: 'red' },
      { value: 'rent', label: 'Renta', color: 'orange' },
      { value: 'utilities', label: 'Utilidades', color: 'green' },
      { value: 'insurance', label: 'Seguros', color: 'teal' },
      { value: 'office-supplies', label: 'Material de oficina', color: 'blue' },
      { value: 'travel', label: 'Viaje', color: 'blue' },
      { value: 'transportation', label: 'Transporte', color: 'green' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'capex': [
      { value: 'equipment', label: 'Equipos', color: 'blue' },
      { value: 'property', label: 'Propiedades', color: 'green' },
      { value: 'vehicles', label: 'Vehículos', color: 'purple' },
      { value: 'technology', label: 'Tecnología', color: 'orange' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'taxes': [
      { value: 'income-tax', label: 'ISR', color: 'red' },
      { value: 'vat', label: 'IVA', color: 'blue' },
      { value: 'property-tax', label: 'Impuesto de Propiedad', color: 'orange' },
      { value: 'payroll-taxes', label: 'Impuestos sobre Nómina', color: 'green' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'debt-repayment': [
      { value: 'bank-loans', label: 'Préstamos bancarios', color: 'green' },
      { value: 'credit-lines', label: 'Líneas de crédito', color: 'blue' },
      { value: 'credit-cards', label: 'Tarjetas de crédito', color: 'amber' },
      { value: 'mortgages', label: 'Hipotecas', color: 'purple' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'dividends': [
      { value: 'cash', label: 'Efectivo', color: 'green' },
      { value: 'stock', label: 'Acciones', color: 'blue' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
    'other': [
      { value: 'internal-transfers', label: 'Transferencias Internas', color: 'green' },
      { value: 'other', label: 'Otros', color: 'sky' },
    ],
  },
  method: [
    { value: 'credit-card', label: 'Tarjeta de Crédito', icon: 'i-mdi-credit-card', color: 'sky' },
    { value: 'bank-transfer', label: 'Transferencia Bancaria', icon: 'i-mdi-bank-transfer', color: 'amber' },
    { value: 'cash', label: 'Efectivo', icon: 'i-mdi-cash', color: 'orange' },
    { value: 'other', label: 'Otro', icon: 'i-mdi-question', color: 'purple' },
  ],
  flagType: [
    { value: 'fraud', label: 'Fraude', color: 'red', description: 'Transacción que ha sido marcada como fraudulenta.' },
    { value: 'dispute', label: 'Disputa', color: 'amber', description: 'Transacción que está siendo disputada.' },
    { value: 'other', label: 'Otro', color: 'gray', description: 'Transacción que ha sido marcada por otro motivo.' },
  ],
  flagStatus: {
    fraud: [
      { value: 'suspicious', label: 'Sospechosa', color: 'amber', description: 'Transacción que ha sido marcada como sospechosa.' },
      { value: 'under-review', label: 'En Revisión', color: 'orange', description: 'Transacción que está siendo revisada por el equipo.' },
      { value: 'fraud', label: 'Fraude', color: 'red', description: 'Transacción que ha sido marcada como fraudulenta.' },
    ],
    dispute: [
      { value: 'open', label: 'Abierta', color: 'amber', description: 'Disputa que ha sido abierta.' },
      { value: 'resolved', label: 'Resuelta', color: 'green', description: 'Disputa que ha sido resuelta.' },
      { value: 'rejected', label: 'Rechazada', color: 'red', description: 'Disputa que ha sido rechazada.' },
    ],
    other: [
      { value: 'other', label: 'Otro', color: 'gray', description: 'Transacción que ha sido marcada por otro motivo.' },
    ],
  },
} satisfies Options

export const relationsTransactions: Record<string, boolean> = {
  account: true,
  financialaccount: true,
}
