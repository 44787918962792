export const getHeaderSection = (options: { text: string, style?: string, alignment?: string, margin?: number[] }) => {
  return {
    style: 'header',
    alignment: 'center',
    margin: [0, 20, 0, 20],
    ...options,
  }
}

export const getClientInfoSection = (options: { clientName: string, clientAddress: string, clientPhone: string }) => {
  return {
    columns: [
      { text: 'Información del Cliente:', style: 'subheader' },
      {
        stack: [
          { text: `Nombre: ${options.clientName}`, style: 'text' },
          { text: `Dirección: ${options.clientAddress}`, style: 'text' },
          { text: `Teléfono: ${options.clientPhone}`, style: 'text' },
        ],
      },
    ],
    margin: [0, 0, 0, 20],
  }
}

export const getQuoteDetailsSection = (options: { quoteNumber: string, date: string, total: number }) => {
  return {
    columns: [
      { text: 'Información del Presupuesto:', style: 'subheader' },
      {
        stack: [
          { text: `Número de Presupuesto: ${options.quoteNumber}`, style: 'text' },
          { text: `Fecha: ${options.date}`, style: 'text' },
          { text: `Total: $${options.total.toFixed(2)}`, style: 'text' },
        ],
      },
    ],
    margin: [0, 0, 0, 20],
  }
}

export const getTableHeaderRow = () => {
  return [
    { text: 'Artículo', style: 'tableHeader' },
    { text: 'Descripción', style: 'tableHeader' },
    { text: 'Cantidad', style: 'tableHeader' },
    { text: 'Precio Unitario', style: 'tableHeader' },
    { text: 'Total', style: 'tableHeader' },
  ]
}

export const getTableBody = (lineItems: { name: string, description: string, quantity: number, unitPrice: number }[]) => {
  const body = [getTableHeaderRow()] // Initialize with header row

  lineItems.forEach((item) => {
    body.push([
      { text: item.name, style: 'tableText' },
      { text: item.description, style: 'tableText' },
      { text: item.quantity.toString(), style: 'tableText' },
      { text: `$${item.unitPrice.toFixed(2)}`, style: 'tableText' },
      { text: `$${(item.quantity * item.unitPrice).toFixed(2)}`, style: 'tableText' },
    ])
  })

  return body
}

export const getLineItemsSection = (lineItems: { name: string, description: string, quantity: number, unitPrice: number }[]) => {
  return {
    table: {
      widths: ['20%', '40%', '15%', '15%', '10%'],
      body: getTableBody(lineItems),
    },
    layout: {
      fillColor: (rowIndex: number) => (rowIndex === 0 ? '#CCCCCC' : null),
    },
    margin: [0, 0, 0, 20],
  }
}

// image:

// see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
// see https://nuxt-pdfmake.behonbaker.com/examples/images#implementation
export async function getBase64ImageFromURL(href: string) {
  if (!href) return ''
  const { baseUrl } = useRuntimeConfig().public // new URL('/api/blob/...', baseUrl).href
  const url = href.startsWith('http') ? new URL(href) : new URL(href, baseUrl)

  // add cache-buster to avoid cached images since browser disallows CORS sometimes over cache
  // see https://github.com/bpampuch/pdfmake/issues/2416#issuecomment-1291687790
  url.searchParams.set('v', Date.now().toString())

  const result = await fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.onloadend = () => resolve(reader.result)
          reader.onerror = reject
          reader.readAsDataURL(blob)
        }),
    )
  return result
}
