<script setup lang="ts">
import type { FormSubmitEvent } from '#ui/types'

const model = defineModel<boolean>()

const props = defineProps<{
  title?: string
  description?: string
  onSubmit: (event: FormSubmitEvent<{ id: string, [k: string]: unknown }[]>) => void
}>()

const loading = ref(false)

const onSubmit = async (event: FormSubmitEvent<{ id: string, [k: string]: unknown }[]>) => {
  try {
    loading.value = true
    await props.onSubmit(event)
    model.value = false
  }
  catch (error) {
    console.error(error)
  }
  finally {
    loading.value = false
  }
}
</script>

<template>
  <UDashboardModal
    v-model="model"
    :title="title ?? 'Eliminar registro(s)'"
    :description="description ?? 'Esta acción es irreversible. ¿Estás seguro que deseas proceder?'"
    prevent-close
  >
    <template #footer>
      <UButton
        color="red"
        label="Eliminar"
        :loading="loading"
        @click="onSubmit"
      />
      <UButton
        color="white"
        label="Cancelar"
        :disabled="loading"
        @click="model = false"
      />
    </template>
  </UDashboardModal>
</template>
