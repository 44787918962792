import emphasis from "./emphasis.js";
import code from "./code.js";
import html from "./html.js";
import link from "./link.js";
import list from "./list.js";
import paragraph from "./paragraph.js";
import image from "./image.js";
import strong from "./strong.js";
import inlineCode from "./inlineCode.js";
import containerComponent from "./containerComponent.js";
export default {
  emphasis,
  code,
  link,
  paragraph,
  html,
  list,
  image,
  strong,
  inlineCode,
  containerComponent
};
