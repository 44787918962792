import { AppAuditlogsModalChanges } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

export type AuditlogsUtils = ReturnType<typeof useAuditlogsUtils>

// view:

export const VIEW_SETTINGS_AUDITLOGS: ViewSettings<Auditlog> = {
  tableName: 'auditlogs',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'action',
      label: 'Acción',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'createdAt',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'entity',
      label: 'Entidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'entityId',
      label: 'ID de Entidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'changes',
      label: 'Cambios',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'userId',
      label: 'Usuario',
      sortable: true,
    },
  ],
}

export const useAuditlogs = () => {
  // const slideover = useSlideover()
  const modal = useModal()

  // forms:

  const {
    onSubmitCreate,
    onSubmitUpdate,
    // onSubmitDelete,
    onSubmitUpdateMultiple,
  } = useApiDB<Auditlog>('auditlogs')

  const modalOpenChanges = (props: ComponentProps<typeof AppAuditlogsModalChanges>) => {
    modal.open(AppAuditlogsModalChanges, { title: 'Cambios en el registro', ...props })
  }

  const schema = z.object({
    action: z.string(),
    entityId: z.string(),
    entity: z.string(),
    userId: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Auditlog, refresh: () => Promise<void>, utils: AuditlogsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Ver cambios',
        icon: 'i-mdi-magnify',
        click: () => { modalOpenChanges({ data: row, utils }) },
      },
    ],
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
  ].filter(items => items?.length)

  // other:

  const getAuditlogDescription = (auditlog: Auditlog, utils: AuditlogsUtils) => {
    const action = ({
      create: 'creó',
      update: 'actualizó',
      delete: 'eliminó',
    })[auditlog.action] ?? auditlog.action
    // const entity = utils.maps.entity.get(auditlog.entity)?.label?.toLowerCase()
    const user = utils.maps.userId.value.get(auditlog.userId)
    const userName = user?.name ?? user?.email ?? auditlog.userId
    const columns = Object.keys(auditlog.data ?? {}).map(key => `<code>${key}</code>`)
    return ['update'].includes(auditlog.action)
      ? `${userName} ${action} el registro en los campos ${columns.join(', ')}.`
      : `${userName} ${action} el registro.`
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitUpdateMultiple,
    modal,
    modalOpenChanges,
    schema,
    getDropdownItems,
    getAuditlogDescription,
  }
}

export const useAuditlogsUtils = ({ $users }: {
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsAuditlogs,
    entity: optionsShared.entity,
    userId: computed(() => $users.data.value.map(toOption)),
  }

  const maps = {
    action: toMapByKey(optionsAuditlogs.action, 'value'),
    entity: toMapByKey(optionsAuditlogs.entity, 'value'),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
  }

  return { options, maps }
}
