<script setup lang="ts">
const model = defineModel<string>()

const props = defineProps<{
  href: string
  disabled?: boolean
}>()
</script>

<template>
  <UButtonGroup class="flex">
    <UInput
      v-model="model"
      :disabled="disabled"
      class="flex-1"
    />
    <UButton
      icon="i-mdi-open-in-new"
      variant="outline"
      square
      :to="href"
      target="_blank"
      :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
    />
  </UButtonGroup>
</template>
