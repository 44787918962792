// adapted from https://stackoverflow.com/a/66494926 (underscore-separated hsl syntax for unocss)
// usage: add to className e.g. `bg-[${toHslColor(category) }]`
// adapted from https://stackoverflow.com/a/66494926
export const toHslColor = (value: string, saturation = 75, lightness = 50, opacity = 1) => {
  const stringUniqueHash = [...value].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  const hue = Math.abs(stringUniqueHash % 360)
  return `hsl(${hue} ${saturation}% ${lightness}% / ${opacity})`
}

export const generateHexColor = () => {
  // Generate a random integer between 0 and 255 for red, green, and blue
  const r = Math.floor(Math.random() * 256)
  const g = Math.floor(Math.random() * 256)
  const b = Math.floor(Math.random() * 256)

  // Convert the integer values to hexadecimal and pad with zeros if necessary
  const hexR = r.toString(16).padStart(2, '0')
  const hexG = g.toString(16).padStart(2, '0')
  const hexB = b.toString(16).padStart(2, '0')

  // Concatenate the hex values to form a full hex color code
  return `#${hexR}${hexG}${hexB}`
}

// IMPORTANT: underscore-separated hsl syntax for unocss
// add to className e.g. `bg-[${toHslColorClassName(category) }]`
export const toHslColorClassName = (
  value: string,
  saturation = 75,
  lightness = 50,
  opacity = 1,
) => {
  const stringUniqueHash = [...value].reduce((acc, char) => {
    return char.charCodeAt(0) + ((acc << 5) - acc)
  }, 0)
  const hue = Math.abs(stringUniqueHash % 360)
  return `hsl(${hue}_${saturation}%_${lightness}%/${opacity})`
}

export const toHexColor = (value, saturation = 75, lightness = 50, opacity = 1) => {
  const stringUniqueHash = [...value].reduce(
    (acc, char) => char.charCodeAt(0) + ((acc << 5) - acc),
    0,
  )
  const hue = Math.abs(stringUniqueHash % 360)

  const hslToHex = (H, S, L, A = 1) => {
    const s = S / 100
    const l = L / 100
    const k = n => (n + H / 30) % 12
    const a1 = s * Math.min(l, 1 - l)
    const f = n => l - a1 * Math.max(Math.min(k(n) - 3, 9 - k(n), 1), -1)
    const toHex = n =>
      Math.round(n * 255)
        .toString(16)
        .padStart(2, '0')
    const [r, g, b] = [f(0), f(8), f(4)]
    const alpha = Math.round(A * 255)
      .toString(16)
      .padStart(2, '0')
    return `#${toHex(r)}${toHex(g)}${toHex(b)}${A < 1 ? alpha : ''}`
  }

  return hslToHex(hue, saturation, lightness, opacity)
}

export const getColorForTimeAgo = (timeAgo: string): string => {
  const colorScale = [
    '#32CD32', // Green (just now, seconds)
    '#66BB6A', // Lighter Green (minutes)
    '#9CCC65', // Light Green (hours)
    '#FFEB3B', // Yellow (day)
    '#FFC107', // Amber (week)
    '#FF5722', // Orange (month)
    '#FF0000', // Deep Orange (year)
  ]

  const defaultColor = '#A9A9A9' // Grey default for unrecognized values

  const words = timeAgo.trim().split(/\s+/)
  const unit = words[words.length - 1].toLowerCase()

  let index = 0
  switch (unit) {
    case 'segundos':
    case 'segundo':
      index = 0 // Green (just now)
      break
    case 'minuto':
    case 'minutos':
      index = 1 // Lighter Green (minutes)
      break
    case 'hora':
    case 'horas':
      index = 2 // Light Green (hours)
      break
    case 'dia':
    case 'dias':
      index = 3 // Yellow (day)
      break
    case 'semana':
    case 'semanas':
      index = 4 // Amber (week)
      break
    case 'mes':
    case 'meses':
      index = 5 // Orange (month)
      break
    case 'año':
    case 'años':
      index = 6 // Deep Orange (year)
      break
    default:
      console.error(`Unknown time unit: ${unit}`)
      return defaultColor
  }

  return colorScale[index] || defaultColor
}

export const tailwindcssColorToHex = (color?: string): string => {
  const tailwindcssColors = {
    transparent: '#00000000',
    current: 'currentColor',
    black: '#000000',
    white: '#ffffff',
    slate: '#64748b',
    gray: '#6b7280',
    zinc: '#71717a',
    neutral: '#737373',
    stone: '#78716c',
    red: '#ef4444',
    orange: '#f97316',
    amber: '#f59e0b',
    yellow: '#eab308',
    lime: '#84cc16',
    green: '#22c55e',
    emerald: '#10b981',
    teal: '#14b8a6',
    cyan: '#06b6d4',
    sky: '#0ea5e9',
    blue: '#3b82f6',
    indigo: '#6366f1',
    violet: '#8b5cf6',
    purple: '#a855f7',
    fuchsia: '#d946ef',
    pink: '#ec4899',
    rose: '#f43f5e',
  }

  return tailwindcssColors?.[color as keyof typeof tailwindcssColors] ?? toHexColor(color)
}

export const percentToColorDiscrete = (percent: number): string => {
  if (percent < 0 || percent > 100) {
    throw new Error('Percent must be between 0 and 100')
  }

  if (percent < 25) return 'red'
  if (percent < 50) return 'yellow'
  if (percent < 75) return 'amber'
  if (percent < 100) return 'orange'
  return 'green'
}
