// see http://omawww.sat.gob.mx/tramitesyservicios/Paginas/documentos/GuiallenadoCFDIglobal311221.pdf
// see https://soporte.enlacefiscal.com/article/200-objetos-de-impuesto-4-0#:~:text=(02)%20S%C3%AD%20objeto%20del%20impuesto,Importaci%C3%B3n%20de%20bienes%20o%20servicios.
// see https://www.gncys.com/anexo20/usocfdi
export const optionsSAT = {
  cfdiType: [
    { value: 'I', label: 'I - Ingreso', description: 'Facturas, Recibos de Honorarios, Recibos de Arrendamiento' },
    { value: 'E', label: 'E - Egreso', description: 'Notas de Crédito' },
    { value: 'T', label: 'T - Traslado', description: 'Complemento Carta Porte' },
    { value: 'N', label: 'N - Nómina', description: 'Recibos de Nómina, Salarios, Asimilados a Salarios' },
    { value: 'P', label: 'P - Pago', description: 'Pagos en parcialidades' },
    { value: 'R', label: 'R - Retenciones', description: 'Documentación de retenciones de impuestos' },
  ],
  productUnitId: [
    { value: 'H87', label: 'H87 - Pieza' },
    { value: 'EA', label: 'EA - Elemento' },
    { value: 'E48', label: 'E48 - Unidad de Servicio' },
    { value: 'ACT', label: 'ACT - Actividad' },
    { value: 'KGM', label: 'KGM - Kilogramo' },
    { value: 'E51', label: 'E51 - Trabajo' },
    { value: 'A9', label: 'A9 - Tarifa' },
    { value: 'MTR', label: 'MTR - Metro' },
    { value: 'AB', label: 'AB - Paquete a granel' },
    { value: 'BB', label: 'BB - Caja base' },
    { value: 'KT', label: 'KT - Kit' },
    { value: 'SET', label: 'SET - Conjunto' },
    { value: 'LTR', label: 'LTR - Litro' },
    { value: 'XBX', label: 'XBX - Caja' },
    { value: 'MON', label: 'MON - Mes' },
    { value: 'HUR', label: 'HUR - Hora' },
    { value: 'MTK', label: 'MTK - Metro cuadrado' },
    { value: '11', label: '11 - Equipos' },
    { value: 'MGM', label: 'MGM - Miligramo' },
    { value: 'XPK', label: 'XPK - Paquete' },
    { value: 'XKI', label: 'XKI - Kit(Conjunto de piezas)' },
    { value: 'AS', label: 'AS - Variedad' },
    { value: 'GRM', label: 'GRM - Gramo' },
    { value: 'PR', label: 'PR - Par' },
    { value: 'DPC', label: 'DPC - Docenas de piezas' },
    { value: 'xun', label: 'xun - Unidad' },
    { value: 'DAY', label: 'DAY - Día' },
    { value: 'XLT', label: 'XLT - Lote' },
    { value: '10', label: '10 - Grupos' },
    { value: 'MLT', label: 'MLT - Mililitro' },
    { value: 'E54', label: 'E54 - Viaje' },
  ],
  taxCfdiUse: [
    { value: 'G01', label: 'G01 - Adquisición de mercancías' },
    { value: 'G02', label: 'G02 - Devoluciones, descuentos o bonificaciones' },
    { value: 'G03', label: 'G03 - Gastos en general' },
    { value: 'I01', label: 'I01 - Construcciones' },
    { value: 'I02', label: 'I02 - Mobiliario y equipo de oficina por inversiones' },
    { value: 'I03', label: 'I03 - Equipo de transporte' },
    { value: 'I04', label: 'I04 - Equipo de cómputo y accesorios' },
    { value: 'I05', label: 'I05 - Dados, troqueles, moldes, matrices y herramental' },
    { value: 'I06', label: 'I06 - Comunicaciones telefónicas' },
    { value: 'I07', label: 'I07 - Comunicaciones satelitales' },
    { value: 'I08', label: 'I08 - Otra maquinaria y equipo' },
    { value: 'D01', label: 'D01 - Honorarios médicos, dentales y gastos hospitalarios' },
    { value: 'D02', label: 'D02 - Gastos médicos por incapacidad o discapacidad' },
    { value: 'D03', label: 'D03 - Gastos funerales' },
    { value: 'D04', label: 'D04 - Donativos' },
    { value: 'D05', label: 'D05 - Intereses reales efectivamente pagados por créditos hipotecarios (casa habitación)' },
    { value: 'D06', label: 'D06 - Aportaciones voluntarias al SAR' },
    { value: 'D07', label: 'D07 - Primas por seguros de gastos médicos' },
    { value: 'D08', label: 'D08 - Gastos de transportación escolar obligatoria' },
    { value: 'D09', label: 'D09 - Depósitos en cuentas para el ahorro, primas que tengan como base planes de pensiones' },
    { value: 'D10', label: 'D10 - Pagos por servicios educativos (colegiaturas)' },
    { value: 'CN01', label: 'CN01 - Nómina' },
    { value: 'CP01', label: 'CP01 - Pagos' },
    { value: 'S01', label: 'S01 - Sin efectos fiscales' },
  ],
  fiscalRegime: [
    { value: '601', label: '601 - General de Ley Personas Morales' },
    { value: '603', label: '603 - Personas Morales con Fines no Lucrativos' },
    { value: '605', label: '605 - Sueldos y Salarios e Ingresos Asimilados a Salarios' },
    { value: '606', label: '606 - Arrendamiento' },
    { value: '607', label: '607 - Demás ingresos' },
    { value: '608', label: '608 - Consolidación' },
    { value: '609', label: '609 - Residentes en el Extranjero sin Establecimiento Permanente en México' },
    { value: '610', label: '610 - Ingresos por Dividendos (socios y accionistas)' },
    { value: '611', label: '611 - Personas Físicas con Actividades Empresariales y Profesionales' },
    { value: '612', label: '612 - Incorporación Fiscal' },
    { value: '614', label: '614 - Ingresos por intereses' },
    { value: '615', label: '615 - Régimen de los ingresos por obtención de premios' },
    { value: '616', label: '616 - Sin obligaciones fiscales' },
    { value: '620', label: '620 - Sociedades Cooperativas de Producción que optan por diferir sus ingresos' },
    { value: '621', label: '621 - Incorporación Fiscal para personas morales' },
    { value: '622', label: '622 - Actividades Agrícolas, Ganaderas, Silvícolas y Pesqueras' },
    { value: '623', label: '623 - Opcional para Grupos de Sociedades' },
    { value: '624', label: '624 - Coordinados' },
    { value: '625', label: '625 - Régimen de Enajenación o Adquisición de Bienes' },
    { value: '626', label: '626 - Régimen Simplificado de Confianza' },
  ],
  taxObject: [
    { value: '01', label: '01 - Sin objeto de impuesto' },
    { value: '02', label: '02 - Con objeto de impuesto' },
    // { value: '03', label: '03 - Con objeto de impuesto, no causa impuesto' },
    // { value: '04', label: 'Locales' },
  ],
  taxTypes: [
    { value: '001', label: '001 - ISR' }, // Impuesto sobre la renta
    { value: '002', label: '002 - IVA' }, // Impuesto al valor agregado
    { value: '003', label: '003 - IEPS' }, // Impuesto especial sobre producción y servicios
  ],
  taxFactor: [
    { value: 'rate', label: 'Tasa' }, // Tasa % del total
    { value: 'quota', label: 'Cuota' }, // Cuota fija
    { value: 'exempt', label: 'Exento' },
  ],
  // Traslados: Cuando el emisor del CFDI cobra impuestos al receptor y luego los entera al SAT.
  tax002RateTransfers: [
    { value: 0.160000, label: 'Tasa - 16%' },
    { value: 0.080000, label: 'Tasa - 8%' },
    { value: 0.040000, label: 'Tasa - 4%' },
    { value: 0.000000, label: 'Tasa - 0%' },
  ],
  tax003RateTransfers: [
    { value: 1.600000, label: 'Tasa - 160%' },
    { value: 0.530000, label: 'Tasa - 53%' },
    { value: 0.500000, label: 'Tasa - 50%' },
    { value: 0.304000, label: 'Tasa - 30.4%' },
    { value: 0.265000, label: 'Tasa - 26.5' },
    { value: 0.250000, label: 'Tasa - 25%' },
    { value: 0.090000, label: 'Tasa - 9%' },
    { value: 0.080000, label: 'Tasa - 8%' },
  ],
  // Retenciones: Cuando el emisor del CFDI retiene impuestos que serán enterados al SAT por el emisor.
  tax001RateRetentions: [
    { value: 0.012500, label: 'Tasa - 1.25%' },
  ],
  tax002RateRetentions: [
    { value: 0.160000, label: 'Tasa - 16%' },
    { value: 0.106666, label: 'Tasa - 10.6666%' },
    { value: 0.080000, label: 'Tasa - 8%' },
    { value: 0.053333, label: 'Tasa - 5.3333%' },
  ],
  // http://omawww.sat.gob.mx/informacion_fiscal/factura_electronica/Documents/Complementoscfdi/catPagos.xls
  paymentForm: [
    { value: '01', label: '01 - Efectivo' },
    { value: '02', label: '02 - Cheque nominativo' },
    { value: '03', label: '03 - Transferencia electrónica de fondos' },
    { value: '04', label: '04 - Tarjeta de crédito' },
    { value: '05', label: '05 - Monedero electrónico' },
    { value: '06', label: '06 - Dinero electrónico' },
    { value: '08', label: '08 - Vales de despensa' },
    { value: '12', label: '12 - Dación en pago' },
    { value: '13', label: '13 - Pago por subrogación' },
    { value: '14', label: '14 - Pago por consignación' },
    { value: '15', label: '15 - Condonación' },
    { value: '17', label: '17 - Compensación' },
    { value: '23', label: '23 - Novación' },
    { value: '24', label: '24 - Confusión' },
    { value: '25', label: '25 - Remisión de deuda' },
    { value: '26', label: '26 - Prescripción o caducidad' },
    { value: '27', label: '27 - A satisfacción del acreedor' },
    { value: '28', label: '28 - Tarjeta de débito' },
    { value: '29', label: '29 - Tarjeta de servicios' },
    { value: '30', label: '30 - Aplicación de anticipos' },
    { value: '31', label: '31 - Intermediario pagos' },
    { value: '99', label: '99 - Por definir' },
  ],
  paymentMethod: [
    { value: 'PUE', label: 'PUE - Pago en una sola exhibición', color: 'lime' },
    { value: 'PPD', label: 'PPD - Pago en parcialidades o diferido', color: 'fuchsia' },
    { value: 'N/A', label: 'N/A - No aplica', color: 'gray' },
  ],
  currency: [
    { value: 'USD', label: 'USD - Dólar Americano', color: 'green' },
    { value: 'MXN', label: 'MXN - Peso Mexicano', color: 'blue' },
  ],
}
