<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<Quote | Salesorder | Fulfillmentorder>({ required: true })

const props = defineProps<{
  disabledFields?: (keyof Quote | keyof Salesorder | keyof Fulfillmentorder)[]
  disabled?: boolean
  schema: ZodSchema
  utils: QuotesUtils | SalesordersUtils | FulfillmentordersUtils
  openNested: (callback: () => void) => void
  slideoverOpenEdit: () => void
}>()

const contacts$ = useContacts()

const onToggleUseAccountAddress = () => {
  model.value.delivery.useAccountAddress = !model.value.delivery.useAccountAddress
}

const onUpdateAddress = () => {
  if (model.value.delivery.useAccountAddress) {
    const account = props.utils.maps.accountId.value.get(model.value.accountId)
    if (account) {
      model.value.delivery.address = account.billing?.address
    }
  }
  else if (model.value.branchId) {
    model.value.delivery.address = props.utils.maps.branchId.value.get(model.value.branchId)?.address
  }
}

const onUpdateBranchId = async (branchId: string) => {
  model.value.delivery.address = {}
  onUpdateAddress()
}

watch(() => model.value.delivery.useAccountAddress, (value) => { onUpdateAddress() })

const contactInformation = computed(() => {
  const contact = props.utils.maps.contactId.value.get(model.value.contactId)
  if (!contact) return []
  return [
    ['Titulo', contact.title],
    ['Nombre', contact.name],
    ['Correo', contact.email],
    ['Teléfono', contact.phone],
  ]
})

const FIELDSET = `w-full grid gap-2`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      label="Términos de entrega"
      name="delivery.incoterm"
      :required="isRequired(schema, 'delivery.incoterm')"
    >
      <SelectMenuBaseInfo
        v-model="model.delivery.incoterm"
        :options="optionsShared.incoterm"
        :disabled="disabledFields?.includes('delivery.incoterm')"
      />
    </UFormGroup>

    <UFormGroup
      label="Fecha de entrega"
      name="delivery.date"
      :required="isRequired(schema, 'delivery.date')"
    >
      <InputDatetime
        v-model="model.delivery.date"
        :is-datetime="model.delivery.isDatetime"
        :disabled="disabledFields?.includes('delivery.date')"
      />
    </UFormGroup>

    <UFormGroup
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
      label="Dirección de entrega"
      name="branchId"
      :required="model.delivery.useAccountAddress && !['EXW', 'pending'].includes(model.delivery?.incoterm)"
    >
      <div
        v-if="model.delivery.useAccountAddress"
        class="w-full flex items-center gap-1"
      >
        <UButton
          icon="i-mdi-checkbox-marked"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm)"
          @click="onToggleUseAccountAddress"
        />
        <div class="w-full flex gap-1 pl-1">
          <UButtonGroup class="flex-1">
            <InputReadOnly
              model-value="Entregar en domicilio fiscal"
              class="flex-1"
            />
            <UButton icon="i-mdi-plus" disabled />
          </UButtonGroup>
          <ButtonRefresh
            toast="Dirección actualizada"
            @click="onUpdateAddress()"
          />
        </div>
      </div>
      <UButtonGroup v-else class="w-full">
        <UButton
          icon="i-mdi-checkbox-blank-outline"
          square
          variant="outline"
          :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
          :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm)"
          @click="onToggleUseAccountAddress"
        />
        <div class="w-full flex gap-1 pl-1">
          <UButtonGroup class="flex-1">
            <SelectMenuBase
              v-model="model.branchId"
              :options="utils.maps.branchesByAccountId?.value.get(model.accountId)?.map(toOption) ?? []"
              class="flex-1"
              :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm) || disabledFields?.includes('branchId')"
              @update:model-value="onUpdateBranchId"
            />
            <UButton
              icon="i-mdi-plus"
              :disabled="['EXW', 'pending'].includes(model.delivery?.incoterm) || disabledFields?.includes('branchId')"
              @click="openNested(() => branches$.slideoverOpenCreate({
                data: { accountId: model.accountId },
                onSubmit: async (event) => {
                  const branch = await branches$.onSubmitCreate(event.data)
                  model.branchId = branch!.id
                  await openNested(() => slideoverOpenEdit({ ...props, data: model }))
                },
              }))"
            />
          </UButtonGroup>
          <ButtonRefresh
            toast="Dirección actualizada"
            @click="onUpdateAddress()"
          />
        </div>
      </UButtonGroup>
    </UFormGroup>

    <UFormGroup
      label="Contacto"
      name="contactId"
      :required="isRequired(schema, 'contactId')"
      help="Contacto al cual se le enviarian notificaciones."
    >
      <UButtonGroup class="flex">
        <SelectMenuBase
          v-model="model.contactId"
          :options="utils.maps.contactsByAccountId.value.get(model.accountId)?.map(toOption) ?? []"
          :disabled="disabledFields?.includes('contactId')"
          class="flex-1"
        />
        <UButton
          icon="i-mdi-plus"
          :disabled="disabledFields?.includes('contactId')"
          @click="openNested(() => contacts$.slideoverOpenCreate({
            data: { type: 'customer', accountId: model.accountId },
            disabledFields: ['type', 'accountId'],
            onSubmit: async (event) => {
              const contact = await contacts$.onSubmitCreate(event.data)
              model.contactId = contact!.id
              await $contacts?.refresh()
              await openNested(() => slideoverOpenEdit({ ...props, data: model }))
            },
          }))"
        />
      </UButtonGroup>
    </UFormGroup>

    <ViewRendererInformation
      v-if="model.contactId"
      title="Información del contacto"
      :entries="contactInformation"
      :default-open="true"
    />

    <FieldsetAddress
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
      v-model="model.delivery.address"
      :required="isRequired(schema, 'delivery.address')"
      disabled
    />

    <Alert
      type="warning"
      description="Para actualizar la dirección es necesario editar la sucursal o el domicilio fiscal."
    />

    <UFormGroup
      label="Detalles"
      name="delivery.text"
      :required="isRequired(schema, 'delivery.text')"
    >
      <UTextarea
        v-model="model.delivery.text"
        autoresize
        :disabled="disabledFields?.includes('delivery.text')"
      />
    </UFormGroup>
  </fieldset>
</template>
