<script setup lang="ts">
// WORKAROUND: uses native HTML select element with datalist element to circumvent Nuxt UI v2
// performance issue with USelectMenu component caused by upstream Headless UI Combobox component
// see https://github.com/nuxt/ui/issues/280
// see https://github.com/tailwindlabs/headlessui/issues/3455

const props = withDefaults(defineProps<{
  modelValue: string
  options: Option[]
  valueAttribute?: string
  labelAttribute?: string
}>(), {
  modelValue: '',
  valueAttribute: 'value',
  labelAttribute: 'label',
})

const emit = defineEmits<{
  'update:model-value': [value: string]
}>()

const model = computed({
  get: () => props.modelValue,
  set: (value: string) => emit('update:model-value', value),
})

const U_BUTTON = 'relative w-full disabled:cursor-not-allowed disabled:opacity-75 focus:outline-none border-0 inline-flex items-center text-left cursor-default rounded-md text-sm gap-x-1.5 px-2.5 py-1.5 shadow-sm bg-white dark:bg-gray-900 text-gray-900 dark:text-white ring-1 ring-inset ring-gray-300 dark:ring-gray-700 focus:ring-2 focus:ring-primary-500 dark:focus:ring-primary-400 pe-9'
</script>

<template>
  <select v-model="model" :class="U_BUTTON">
    <option
      v-for="(option, i) in options"
      :key="`concept-${option}-${i}`"
      :value="option?.[valueAttribute]"
    >
      {{ option?.[labelAttribute] }}
    </option>
  </select>
</template>
