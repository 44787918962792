<script setup lang="ts">
const model = defineModel<string[] | undefined | null>({ required: true })
const options = defineModel<string[]>('options', { required: true })

defineProps<{
  creatable?: boolean
}>()

function onUpdateModelValue(_value: string[]) {
  const el = document.querySelector('.select-menu-creatable-string input') as HTMLInputElement
  if (el) el.value = '' // clear input
}
</script>

<template>
  <USelectMenu
    v-model="model"
    :options="options"
    searchable
    :searchable-placeholder="creatable ? 'Buscar o crear' : 'Buscar'"
    creatable
    multiple
    v-bind="$attrs"
    class="select-menu-creatable-string"
    @update:model-value="onUpdateModelValue"
  >
    <template #label>
      <template v-if="model?.length">
        <div class="flex gap-1">
          <UBadge
            v-for="(tag, i) in Array.isArray(model) ? model : [model]"
            :key="`tag-${tag}-${i}`"
            :label="tag.label"
            color="gray"
            size="sm"
          />
        </div>
      </template>
      <template v-else>
        <span class="text-gray-500 dark:text-gray-400 truncate">
          {{ creatable ? 'Selecciona o crea' : 'Selecciona' }}
        </span>
      </template>
    </template>

    <template #option="{ option }">
      <span class="truncate">{{ option.label }}</span>
    </template>

    <template v-if="creatable" #option-create>
      <UKbd>Enter</UKbd> para crear nuevo
    </template>
  </USelectMenu>
</template>
