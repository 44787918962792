<script setup lang="ts">
const model = defineModel<string | string[]>({ required: true, default: () => [] })

const props = defineProps<{
  options: Array<{ value: string, label: string }>
  disabled?: boolean
}>()
</script>

<template>
  <UButtonGroup class="w-full">
    <SelectMenuBase
      v-model="model"
      :options
      :disabled
      class="w-full"
      v-bind="$attrs"
    />
    <UPopover
      :ui="{ width: 'max-w-[800px]' }"
      :popper="{ placement: 'auto-end' }"
    >
      <UButton
        icon="i-mdi-information"
        variant="outline"
        square
        :ui="{ variant: { outline: 'ring-gray-300 dark:ring-gray-700' } }"
      />
      <template #panel>
        <div class="max-h-[500px] overflow-y-auto p-4">
          <UTable
            :rows="options"
            :columns="[
              { key: 'label', label: 'Nombre' },
              { key: 'description', label: 'Descripción' },
            ]"
            :ui="{
              th: { base: 'hidden' },
              td: { padding: 'py-2 px-3 text-wrap text-pretty' },
            }"
          >
            <template #label-data="{ row }">
              <div class="w-full contents mx-4 text-xs">
                <UBadge
                  variant="subtle"
                  :label="row.label"
                  :color="row.color ? row.color : 'gray'"
                  size="sm"
                  class="mx-auto text-nowrap"
                />
              </div>
            </template>
          </UTable>
        </div>
      </template>
    </UPopover>
  </UButtonGroup>
</template>
