import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultService = (data: Partial<Service>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'internal',
  status: 'active',
  fiscalData: {},
  images: [],
  tags: [],
  files: [],
}, data)

export const optionsServices = {
  type: [
    { value: 'core', label: 'Principales', icon: 'i-mdi-heart-pulse', color: 'blue', description: 'Servicios principales que son el núcleo del negocio que constituyen la base de la oferta.' },
    { value: 'logistics', label: 'Envío y Logística', icon: 'i-mdi-truck-delivery', color: 'teal', description: 'Servicios de logística y envío, tanto nacionales como internacionales, gestionando transporte, embalaje y entrega de productos.' },
    { value: 'specialized', label: 'Especializados', icon: 'i-mdi-hammer-wrench', color: 'gray', description: 'Servicios fuera de lo convencional, como asesoría, consultoría o servicios de emergencia, que requieren conocimientos especializados.' },
    { value: 'other', label: 'Otros', icon: 'i-mdi-dots-horizontal', color: 'gray', description: 'Servicios que no encajan en las tipos anteriores, como servicios de consultoría, asesoría o servicios de emergencia.' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsServices: Record<string, boolean> = {
  category: true,
  serviceitems: true,
  supplier: true,
}
