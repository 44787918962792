import { AppPricelistsSlideover, FilesSlideover, SlideoverPreview } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppPricelistsSlideoverProps = ComponentProps<typeof AppPricelistsSlideover>

export type PricelistsUtils = ReturnType<typeof usePricelistsUtils>

// view:

export const VIEW_SETTINGS_PRICELISTS: ViewSettings<Pricelist> = {
  tableName: 'pricelists',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'filePricelistPdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'date',
      label: 'Fecha',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'items',
      label: 'Items',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const usePricelists = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Pricelist>('pricelists')

  const slideoverOpenCreate = (props: AppPricelistsSlideoverProps) => {
    return new Promise<Pricelist>((resolve, reject) => {
      const data = getDefaultPricelist(props.data)
      slideover.open(AppPricelistsSlideover, {
        title: 'Crear lista de precios',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppPricelistsSlideoverProps) => {
    return new Promise<Pricelist>((resolve, reject) => {
      const data = getDefaultPricelist(props.data)
      slideover.open(AppPricelistsSlideover, {
        title: 'Editar lista de precios',
        ...props,
        data,
        disabledFields: ['type'],
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
    date: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Pricelist, refresh: () => Promise<void>, utils: PricelistsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, uid, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: 'Copiar Referencia',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.uid) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'pricelists', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsPricelists.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Copiar contraseña',
        icon: 'i-mdi-asterisk',
        disabled: !row.pdfSettings.password.enabled,
        click: () => copyToClipboard(row.pdfSettings.password.value),
      },
      {
        label: 'Ver PDF',
        icon: 'i-mdi-file-pdf',
        click: () => slideover.open(SlideoverPreview, {
          src: toUrl(`/api/blob/pricelists/${row.id}/pricelist.pdf`).href,
          title: `Lista de precios: ${row.name}`,
        }),
      },
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('pricelists', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  // files:

  const { uploadFile, removeFile } = useApiBlob()

  const createPdf = async (data: Pricelist, utils: PricelistsUtils) => {
    const documentDefinition = await createPdfmakeDocumentDefinitionPricelists(data, utils)
    const pdf = usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the pricelist.files array (to be used after POST/PATCH)
  async function createFilePricelistPdf(data: Pricelist, utils: PricelistsUtils) {
    const [existingData] = await $fetch<Pricelist[]>(`/api/db/pricelists`, {
      query: { id: data.id, $with: relationsPricelists },
    }) // findOne instead of get to allow passing query.$with
    const newData = { ...existingData, ...data }

    const pdf = await createPdf(newData, utils)

    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'pricelist.pdf', { type: 'application/pdf' })
    return uploadFile(file, { prefix: `pricelists/${data.id}` })
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
    createPdf,
    createFilePricelistPdf,
  }
}

export const usePricelistsUtils = ({ $productitems, $serviceitems }: {
  $productitems?: Awaited<ReturnType<typeof useFetch<Productitem[]>>>
  $serviceitems?: Awaited<ReturnType<typeof useFetch<Serviceitem[]>>>
} = {}) => {
  const options = {
    ...optionsPricelists,
    productitems: computed(() => $productitems?.data.value.map(toOption)),
    serviceitems: computed(() => $serviceitems?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsPricelists.type, 'value'),
    status: toMapByKey(optionsPricelists.status, 'value'),
    productitemId: computed(() => toMapByKey($productitems?.data.value, 'id')),
    serviceitemId: computed(() => toMapByKey($serviceitems?.data.value, 'id')),
  }

  return { options, maps }
}
