<script setup lang="ts">
import type { DropdownItem } from '#ui/types'
import type { BlobObject } from '@nuxthub/core'

const props = defineProps<{
  file: BlobObject
  dropdownItems?: DropdownItem[]
}>()

const { user } = useUserSession()
const { moduleId } = useModules()

const fileProps = getFileProps(props.file)
</script>

<template>
  <!-- IMPORTANT: must use CSS variables and not e.g. `border-[toHexColor(file.userId)]`
  directly since TailwindCSS will not pick-up dynamic CSS colors outside of the SSR (on client) -->
  <div
    :id="`file-${file.pathname}`"
    class="h-min p-2 rounded-md bg-gray-100 dark:bg-gray-800"
    :style="{ '--dynamic-color': fileProps.color }"
  >
    <div class="flex items-center justify-between text-xs py-1">
      <div class="flex-1 flex items-center gap-2">
        <ButtonFile :file="file" hide-label />
        <span :title="file.pathname" class="font-medium text-xs">
          {{ fileProps.label?.toUpperCase() ?? file.pathname }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <ViewRendererDatetime
          :datetime="file.uploadedAt"
          exact
          display-hours
        />
        <Can
          v-if="dropdownItems?.length"
          :ability="update"
          :args="[moduleId]"
        >
          <UDropdown
            :items="dropdownItems"
            :popper="{ placement: 'auto-start' }"
            :ui="{ width: 'min-w-max' }"
          >
            <UButton
              icon="i-mdi-dots-horizontal"
              variant="ghost"
              size="2xs"
              square
            />
          </UDropdown>
        </Can>
      </div>
    </div>

    <!-- FILE: could add e.g. `class="max-h-[500px] overflow-y-auto"` to limit card height -->
    <MarkdownRenderer :value="file.text" />
  </div>
</template>
