<script setup lang="ts">
const model = defineModel<string | number | null>()
</script>

<template>
  <UInput
    v-model="model"
    disabled
    color="gray"
    :ui="{
      base: '!cursor-default disabled:opacity-100',
      placeholder: 'placeholder-gray-900 dark:placeholder-white',
      color: {
        gray: {
          outline: 'bg-gray-100 dark:bg-gray-800 ',
        },
      },
    }"
  />
</template>
