<script setup lang="ts">
const model = defineModel<Pricelist>({ required: true })

const props = defineProps<{
  utils: PricelistsUtils
  disabled?: boolean
}>()
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled" />
</template>
