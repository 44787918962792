import { AppUsersModalDelete, AppUsersSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppUsersSlideoverProps = ComponentProps<typeof AppUsersSlideover>

export type UsersUtils = ReturnType<typeof useUsersUtils>

// view:

export const VIEW_SETTINGS_USERS: ViewSettings<User> = {
  tableName: 'users',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'email',
      label: 'Email',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'emailVerified',
      label: 'Email verificado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'banned',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'modules',
      label: 'Módulos',
    },
  ],
}

export const useUsers = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<User>('users')

  const slideoverOpenCreate = (props: AppUsersSlideoverProps) => {
    return new Promise<User>((resolve, reject) => {
      const data = getDefaultUser(props.data)
      slideover.open(AppUsersSlideover, {
        title: 'Crear usuario',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppUsersSlideoverProps) => {
    return new Promise<User>((resolve, reject) => {
      const data = getDefaultUser(props.data)
      slideover.open(AppUsersSlideover, {
        title: 'Editar usuario',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const modalOpenDeleteUser = (props: ComponentProps<typeof AppUsersModalDelete>) => {
    modal.open(AppUsersModalDelete, {
      title: props.title ?? 'Eliminar usuario',
      description: props.description ?? '¿Estás seguro de que deseas eliminar tu cuenta? Esta acción no se puede deshacer. Todos tus datos se eliminarán permanentemente.',
      data: props.data,
    })
  }

  const schema = z.object({
    name: z.string(),
    email: z.string().email(),
    modules: z.array(z.string()),
  }).required()

  // actions:

  const getDropdownItems = (row: User, refresh: () => Promise<void>, utils: UsersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            disabledFields: ['email', 'password'],
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'users', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Resource Actions
    [
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('users', row) },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    modalOpenDeleteUser,
    schema,
    getDropdownItems,
  }
}

export const useUsersUtils = () => {
  const options = {
    ...optionsUsers,
    emailVerified: optionsUsers.emailVerified,
    banned: optionsUsers.banned,
  }

  const maps = {
    emailVerified: toMapByKey(optionsUsers.emailVerified, 'value'),
    banned: toMapByKey(optionsUsers.banned, 'value'),
    modules: toMapByKey(optionsUsers.modules, 'value'),
    modulesRole: toMapByKey(optionsUsers.modulesRole, 'value'),
  }

  return { options, maps }
}
