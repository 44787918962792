import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultProduct = (data: Partial<Product>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)  i18n: {},
  type: 'finished-goods',
  status: 'active',
  images: [],
  fiscalData: {},
  applicationIds: [],
  tags: [],
  files: [],
}, data)

export const optionsProducts = {
  type: [
    { value: 'consumables', label: 'Consumibles', icon: 'i-mdi-cogs', color: 'blue', description: 'Materiales utilizados en el proceso de producción que no se integran en el producto final, como suministros de limpieza, lubricantes y equipos de seguridad.' },
    { value: 'raw-materials', label: 'Materia prima', icon: 'i-mdi-raw', color: 'yellow', description: 'Materiales básicos e insumos utilizados en la fabricación de productos propios.' },
    { value: 'components', label: 'Componentes', icon: 'i-mdi-apps', color: 'purple', description: 'Partes o piezas que se ensamblan para formar productos finales, como piezas mecánicas o electrónicas.' },
    { value: 'packaging', label: 'Embalaje', icon: 'i-mdi-package-variant-closed', color: 'orange', description: 'Materiales utilizados para el embalaje y envío de productos, como etiquetas, sacos, cajas, cubetas y otros materiales de empaque.' },
    { value: 'finished-goods', label: 'Producto terminado', icon: 'i-mdi-diamond-stone', color: 'green', description: 'Productos completamente fabricados que se venden directamente al cliente final.' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsProducts: Record<string, boolean> = {
  category: true,
  manufacturer: true,
  productitems: true,
  supplier: true,
}
