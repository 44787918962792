<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { parseISO } from 'date-fns'

const props = defineProps<{
  data: Partial<Fulfillmentorderitem>
  onSubmit: (event: FormSubmitEvent<Partial<Fulfillmentorderitem>>) => void
  title?: string
  disabledFields?: (keyof Fulfillmentorderitem | string)[]
  readonly?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Fulfillmentorderitem>>(getDefaultFulfillmentorderitem(props.data))

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
  createPdf,
  createFileFulfillmentorderitemPdf,
} = useFulfillmentorderitems()

const $fulfillmentorders = await useFetch<Fulfillmentorder[]>('/api/db/fulfillmentorders', {
  query: { $columns: ['id', 'type', 'uid', 'salesorderId', 'userId'] },
  default: () => [],
})
const $salesorders = await useFetch<Salesorder[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useFulfillmentorderitemsUtils({ $fulfillmentorders, $salesorders, $users })

const fulfillmentorders$ = useFulfillmentorders()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.fulfillmentorderitems,
      defaultOpen: true,
    },
    {
      slot: 'tasks',
      label: 'Tareas',
      icon: 'mdi:checkbox-multiple-marked-outline',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateFulfillmentorderId = (id: string) => {
  const fulfillmentorder = utils.maps.fulfillmentorderId.value.get(id)
  state.value.userId = fulfillmentorder?.userId
}

const informationSalesorder = computed(() => {
  const fulfillmentorder = utils.maps.fulfillmentorderId.value.get(state.value.fulfillmentorderId)
  const salesorder = utils.maps.salesorderId.value.get(fulfillmentorder?.salesorderId)
  if (!salesorder) return []
  const user = salesorder?.user?.name
  const account = salesorder?.account?.name
  const localDate = parseISO(salesorder.delivery?.date)
  const delivery = !salesorder.delivery?.isDatetime ? localDate.toLocaleDateString() : localDate.toLocaleString()
  return [
    ['Orden de Venta', salesorder.uid],
    ['Vendedor', user],
    ['Cliente', account],
    ['Fecha de entrega', delivery],
  ]
})

const loading = ref(false)
const onSubmitFulfillmentorderitem = async (event: FormSubmitEvent<Partial<Fulfillmentorderitem>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.fulfillmentorderitems"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitFulfillmentorderitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              autofocus
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de Cumplimiento"
            name="fulfillmentorderId"
            :required="isRequired(schema, 'fulfillmentorderId')"
          >
            <div class="flex gap-1">
              <UButtonGroup class="flex-1">
                <SelectMenuBase
                  v-model="state.fulfillmentorderId"
                  :options="utils.maps.fullfillmentordersByType?.value.get(state?.type)?.map(toOption) || []"
                  :disabled="disabledFields?.includes('fulfillmentorderId') || !state.type"
                  class="flex-1"
                  @update:model-value="onUpdateFulfillmentorderId"
                />
                <UButton
                  icon="i-mdi-plus"
                  :disabled="disabledFields?.includes('fulfillmentorderId')"
                  @click="openNested(() => fulfillmentorders$.slideoverOpenCreate({
                    data: { type: state.type },
                    disabledFields: ['type'],
                    onSubmit: async (event) => {
                      const fulfillmentorder = await fulfillmentorders$.onSubmitCreate(event.data)
                      state.fulfillmentorderId = fulfillmentorder!.id
                      await $fulfillmentorders.refresh()
                      await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                    },
                  }))"
                />
              </UButtonGroup>
              <ButtonRefresh
                toast="Información relacionada a la orden de producción actualizada correctamente."
                :disabled="!state.fulfillmentorderId"
                @click="onUpdateFulfillmentorderId(state.fulfillmentorderId!)"
              />
            </div>
          </UFormGroup>

          <ViewRendererInformation
            v-if="state.type === 'external' || state.fulfillmentorderId"
            title="Información asociada a la orden de venta"
            :default-open="true"
            :entries="informationSalesorder"
          />

          <FieldsetOpsConcept
            v-model="state"
            :salesorder-id="utils.maps.fulfillmentorderId.value.get(state.fulfillmentorderId)?.salesorderId"
            entity="fulfillmentorderitems"
            :disabled="disabledFields?.includes('productitemId')"
            v-bind="{ data, onSubmit, schema, title, disabledFields, slideoverOpenEdit }"
          />
        </template>

        <template #details>
          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="utils.options.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Etapa"
            name="stage"
            :required="isRequired(schema, 'stage')"
          >
            <SelectMenuBaseInfo
              v-model="state.stage"
              :options="utils.options.stage"
              :disabled="disabledFields?.includes('stage')"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId') || !state.fulfillmentorderId"
            />
          </UFormGroup>

          <UFormGroup
            label="Cantidad (plan)"
            name="quantityPlanned"
            :required="isRequired(schema, 'quantityPlanned')"
          >
            <UInput
              v-model="state.quantityPlanned"
              type="number"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('quantityPlanned')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cantidad (real)"
            name="quantityActual"
            :required="isRequired(schema, 'quantityActual')"
          >
            <UInput
              v-model="state.quantityActual"
              type="number"
              inputmode="decimal"
              :min="0.00"
              :step="0.01"
              :disabled="disabledFields?.includes('quantityActual')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `fulfillmentorderitems/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #tasks>
          <InputChecklist v-model="state.tasks" />
        </template>

        <template #data>
          <AppFulfillmentorderitemsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.fulfillmentorderitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
