import { createId, createUid, type Options } from '#netzo/utils/app/index'
import type { CalendarEvent } from '@schedule-x/calendar'
import { addMonths, format } from 'date-fns'
import { merge } from 'lodash-es'

const uid = createUid(new Date(), 'CAM')

export const getDefaultCampaign = (data: Partial<Campaign>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'outbound',
  status: 'active',
  uid: uid,
  name: uid,
  dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  dateEnd: new Date(`${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  tags: [],
  files: [],
}, data)

export const optionsCampaigns = {
  type: [
    { value: 'outbound', label: 'Directa', description: 'Campañas orientadas a contactar directamente a clientes nuevos y existentes para generar ventas.', icon: 'i-mdi-account-group', color: 'blue' },
    { value: 'inbound', label: 'Indirecta', description: 'Campañas diseñadas para atraer nuevos clientes y mantener relaciones sólidas con los actuales mediante la promoción de productos y servicios.', icon: 'i-mdi-bullhorn', color: 'orange' },
  ],
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsCampaigns: Record<string, boolean> = {
  deals: true,
  inquiries: true,
  user: true,
}

// views:

export const campaignToViewCalendarEvent = (calendarId: keyof Campaign, utils: CampaignsUtils) => {
  return (row: Campaign): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.name}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}
