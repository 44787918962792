import { allows as _allows, denies as _denies, authorize as _authorize, AuthorizationError } from "../../utils";
import { useNuxtApp, createError } from "#imports";
export async function allows(ability, ...args) {
  const user = await useNuxtApp().$authorization.resolveClientUser();
  return _allows(ability, user, ...args);
}
export async function denies(ability, ...args) {
  const user = await useNuxtApp().$authorization.resolveClientUser();
  return _denies(ability, user, ...args);
}
export async function authorize(ability, ...args) {
  try {
    const user = await useNuxtApp().$authorization.resolveClientUser();
    await _authorize(ability, user, ...args);
  } catch (error) {
    if (error instanceof AuthorizationError) {
      throw createError({
        statusCode: error.statusCode,
        message: error.message
      });
    }
  }
}
