import { AppAccountsSlideover, FilesSlideover } from '#components'
import { z } from '#imports'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppAccountsSlideoverProps = ComponentProps<typeof AppAccountsSlideover>

export type AccountsUtils = ReturnType<typeof useAccountsUtils>

// view:

export const VIEW_SETTINGS_ACCOUNTS: ViewSettings<Account> = {
  tableName: 'accounts',
  views: optionsShared.views.filter(view => ['table', 'cards'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'domain',
      label: 'Dominio',
      sortable: true,
    },
    {
      key: 'contacts',
      label: 'Contactos',
    },
    {
      key: 'userId',
      label: 'Dueño',
    },
    {
      key: 'industryId',
      label: 'Industria',
    },
    {
      key: 'fileCsfPdf',
      label: 'CSF',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useAccounts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Account>('accounts')

  const slideoverOpenCreate = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const data = getDefaultAccount(props.data)
      slideover.open(AppAccountsSlideover, {
        title: 'Crear cuenta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const data = getDefaultAccount(props.data)
      slideover.open(AppAccountsSlideover, {
        title: 'Editar cuenta',
        disabledFields: ['type'],
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    status: z.string(),
    name: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Account, refresh: () => Promise<void>, utils: AccountsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: row.$inmutable ? 'i-mdi-eye' : 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Duplicar',
        icon: 'i-mdi-content-duplicate',
        click: async () => {
          const { id, ...data } = row
          await onSubmitCreate(data)
          await refresh()
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'accounts', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Ver archivos',
        icon: 'i-mdi-file-multiple',
        click: () => {
          slideover.open(FilesSlideover, { data: row, refresh, title: 'Ver archivos', utils })
        },
      },
    ],
    // Status Actions
    optionsAccounts.status.map(status => ({
      label: `Marcar como ${status?.label.toLowerCase()}`,
      icon: status?.icon,
      disabled: row.status === status.value,
      click: async () => {
        await onSubmitUpdate({ ...row, status: status.value })
        await refresh()
      },
    })) as DropdownItem[],
    [
      {
        label: 'Agregar contacto',
        icon: ICONS.contacts,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: { type: row.type, accountId: row.id, userId: row.userId },
            disabledFields: ['type', 'accountId'],
          }).then(refresh)
        },
      },
      // Custom Resource Actions
      ...dropdownItemsAction,
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('accounts', row) },
      },
      {
        label: row.$inmutable ? 'Desbloquear' : 'Bloquear',
        icon: row.$inmutable ? 'i-mdi-lock-open-variant' : 'i-mdi-lock',
        click: async () => {
          await onSubmitUpdate({ ...row, $inmutable: !row.$inmutable })
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useAccountsUtils = ({ $contacts, $users, $industries }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
  $industries?: Awaited<ReturnType<typeof useFetch<Industry[]>>>
} = {}) => {
  const options = {
    ...optionsAccounts,
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
    industryId: computed(() => $industries?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsAccounts.type, 'value'),
    status: toMapByKey(optionsAccounts.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      type: toMapByKey(optionsAccounts.billing.type, 'value'),
      country: toMapByKey(optionsShared.countryCode, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    payment: {
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      forms: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    industryId: computed(() => toMapByKey($industries?.data.value, 'id')),
  }

  const getters = {
    individual: (data: Partial<Account['billing']>) => getDefaultBilling(data),
    legalEntity: (data: Partial<Account['billing']>) => getDefaultBilling(data),
    foreignLegalEntity: (data: Partial<Account['billing']>) => getDefaultBillingForeignLegalEntity(data),
    genericRFC: (data: Partial<Account['billing']>) => getDefaultBillingGenericRFC(data),
  }

  return { options, maps, getters }
}
