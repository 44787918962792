import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultServiceitem = (data: Partial<Serviceitem>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'active',
  fiscalData: {},
  tags: [],
  files: [],
}, data)

export const serviceitemToConcept = (data: Serviceitem & { service: Service, pricelistitems: Pricelistitem[] }): Quoteitem | Salesorderitem => {
  const { service, pricelistitems, ...serviceitem } = data ?? {}
  return {
    type: 'services',
    subtype: serviceitem.type,
    satProductServiceKey: service.fiscalData?.satProductServiceKey,
    name: serviceitem?.name,
    description: serviceitem?.description || undefined,
    image: serviceitem?.image ?? service.image ?? undefined,
    sku: serviceitem?.sku || undefined,
    satUnitKey: serviceitem.fiscalData?.satUnitKey,
    unitPrice: pricelistitems[0]?.basePrice,
    serviceitemId: serviceitem.id,
  }
}

export const serviceitemToOption = (item: Serviceitem & { service: Service, pricelistitems: Pricelistitem[] }): Option => {
  const label = `${item.service?.name ?? ''} | ${item.name ?? ''}`
  const src = item.image || item.service?.image

  const avatar = src
    ? { src, alt: label } // Use the available image with alt text
    : { alt: item.name?.charAt(0).toUpperCase() ?? '?' } // Use initials if no image

  return {
    label,
    value: item.id,
    avatar,
  }
}

export const optionsServiceitems = {
  type: optionsServices.type,
  status: optionsSharedApp.statusResource,
} satisfies Options

export const relationsServiceitems: Record<string, boolean> = {
  pricelistitems: true,
  quoteitems: true,
  salesorderitems: true,
  service: true,
}
