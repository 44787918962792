import { AppEmailsSlideover } from '#components'
import { z } from '#imports'
import type { ComponentProps } from 'vue-component-type-helpers'

type AppEmailsSlideoverProps = ComponentProps<typeof AppEmailsSlideover>

export type EmailsUtils = ReturnType<typeof useEmailsUtils>

export const useEmails = () => {
  const slideover = useSlideover()

  // form:

  const slideoverOpenCreate = (props: AppEmailsSlideoverProps) => {
    return new Promise<Email>((resolve, reject) => {
      try {
        const data = getDefaultEmail(props.data as Partial<Email>)
        slideover.open(AppEmailsSlideover, {
          title: 'Enviar correo',
          ...props,
          data,
        })
        resolve()
      }
      catch (error) {
        slideover.close()
        reject(error)
      }
    })
  }

  const schema = z.object({
    // NOTE: to, cc and bcc skipped since ChipSelectsrequire { value, label }
    // format for rendering chips, but we ensure the correct format on submit
    // to: z.array(z.string().email()),
    // cc: z.array(z.string().email()).optional(),
    // bcc: z.array(z.string().email()).optional(),
    subject: z.string(),
    body: z.string(),
  }).required()

  const openEmailInNewTab = (data: Email) => {
    const { to, cc, bcc, subject, body } = data
    const TO = to.length > 1 ? to.join(',') : to
    let href = `mailto:${TO}?subject=${subject}&body=${encodeURIComponent(body)}`
    if (cc?.length) href += `&cc=${cc.join(',')}`
    if (bcc?.length) href += `&bcc=${bcc.join(',')}`
    slideover.close() // close before navigating
    window.open(href, '_blank')
  }

  return {
    slideover,
    slideoverOpenCreate,
    schema,
    openEmailInNewTab,
  }
}

export const useEmailsUtils = ({ $accounts, $contacts, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsEmails,
    accountId: computed(() => $accounts.data.value.map(toOption)),
    contactId: computed(() => $contacts.data.value.map(contact => ({ label: contact.name + ' | ' + contact.email, value: contact.email }))),
    userId: computed(() => $users.data.value.map(user => ({ label: user.name + ' | ' + user.email + ' (interno)', value: user.email }))),
  }

  const maps = {
    templateId: toMapByKey(optionsEmails.templateId, 'value'),
    accountId: computed(() => toMapByKey($accounts.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts.data.value, 'id')),
    userId: computed(() => toMapByKey($users.data.value, 'id')),
    contactByAccountId: computed(() => toMapByGroupKey($contacts.data.value, 'accountId')),
  }

  return { options, maps }
}
