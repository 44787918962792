import { createId, createUid, type Options } from '#netzo/utils/app/index'
import type { CalendarEvent } from '@schedule-x/calendar'
import { addMonths, format } from 'date-fns'
import { merge } from 'lodash-es'
import type { TDocumentDefinitions } from 'pdfmake/interfaces'

const uid = createUid(new Date(), 'OC')

export const getDefaultFulfillmentorder = (data: Partial<Fulfillmentorder>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'external',
  stage: 'new',
  status: 'pending',
  priority: '2',
  uid: uid,
  name: uid,
  isDatetime: false,
  dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  dateEnd: new Date(`${format(addMonths(new Date(), 1), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
  delivery: {
    incoterm: 'DAP',
    isDatetime: false,
    date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    useAccountAddress: true,
    address: {},
  },
  fileFulfillmentorderPdf: [],
  tags: [],
  files: [],
  pdfSettings: merge(defaultPdfSettings, { watermark: { text: 'LATINTA' } }),
}, data)

export const optionsFulfillmentorders = {
  type: [
    { value: 'internal', label: 'Interna', description: 'Órdenes para procesos internos que no están vinculadas a ventas.', icon: 'i-mdi-truck', color: 'blue' },
    { value: 'external', label: 'Externa', description: 'Órdenes originadas de solicitudes de clientes para productos.', icon: 'i-mdi-package-variant', color: 'green' },
  ],
  stage: [
    { value: 'new', label: 'Nueva', description: 'En la lista de espera y aún no ha sido programada para su ejecución.', icon: 'i-mdi-circle-slice-1', color: 'blue' },
    { value: 'scheduled', label: 'Programada', description: 'Programada y se encuentra a la espera de ser ejecutada en la fecha y hora asignadas.', icon: 'i-mdi-circle-slice-3', color: 'blue' },
    { value: 'in-progress', label: 'En Ejecución', description: 'Se está llevando a cabo actualmente, con evaluaciones y análisis en proceso.', icon: 'i-mdi-circle-slice-4', color: 'amber' },
    { value: 'completed', label: 'Finalizada', description: 'Se ha completado con éxito, y los resultados han sido documentados y archivados.', icon: 'i-mdi-trophy', color: 'green' },
    { value: 'reopened', label: 'Reabierta', description: 'Ha sido reabierta debido a nuevos hallazgos o necesidades de revisión.', icon: 'i-mdi-refresh', color: 'orange' },
    { value: 'closed', label: 'Cerrada', description: 'Ha sido cerrada oficialmente y no requiere más acciones ni revisiones.', icon: 'i-mdi-lock', color: 'gray' },
  ],
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
  delivery: {
    useAccountAddress: [
      { value: 'true', label: 'Enviar a domicilio fiscal', icon: 'i-mdi-home', color: 'blue' },
      { value: 'false', label: 'Enviar a sucursal', icon: 'i-mdi-store', color: 'green' },
    ],
    incoterm: optionsShared.incoterm,
  },
} satisfies Options

export const relationsFulfillmentorders: Record<string, boolean> = {
  account: true,
  branch: true,
  contact: true,
  productionorder: true,
  salesorder: true,
  user: true,
  fulfillmentorderitems: true,
}

export const fulfillmentorderToViewCalendarEvent = (calendarId: keyof Fulfillmentorder, utils: FulfillmentordersUtils) => {
  return (row: Fulfillmentorder): CalendarEvent => {
    const calendarName = utils.maps[calendarId].get(row[calendarId])?.label?.toUpperCase()
    const dateFormat = row.isDatetime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd'
    return {
      ...row,
      id: row.id,
      title: `[${calendarName}] ${row.uid}`,
      description: row.description!,
      start: format(new Date(row.dateStart), dateFormat),
      end: format(new Date(row.dateEnd), dateFormat),
      calendarId: row[calendarId],
    }
  }
}

export const createPdfmakeDocumentDefinitionFulfillmentorders = async (
  fulfillmentorder: Fulfillmentorder,
  utils: FulfillmentordersUtils,
): Promise<TDocumentDefinitions> => {
  // see https://pdfmake.github.io/docs/0.1/document-definition-object/images/
  return {
    language: 'es-ES',
    info: {
      subject: 'Orden de Venta Esgraf',
      keywords: 'diseño, agencia, publicidad',
      author: 'ESGRAF',
      producer: 'Netzo (https://netzo.dev)',
    },
    footer: function (currentPage, pageSize) {
      return {
        stack: [
          {
            text: `Página ${currentPage.toString()} de ${pageSize}`,
          },
          {
            text: [
              {
                text: 'Generado con ',
              },
              {
                text: 'netzo.dev',
                link: 'https://netzo.dev',
                color: 'blue',
              },
            ],
          },
        ],
        alignment: 'center',
      }
    },
    styles: {
      header: {
        fontSize: 10,
        bold: true,
        margin: [0, 0, 10, 5],
      },

    },
    defaultStyle: {
      fontSize: 8,
    },
    pageSize: 'LETTER',
    pageMargins: [20, 20, 20, 40],
  }
}
