<script setup lang="ts">
const model = defineModel<number>()

const props = defineProps<{
  icon?: boolean
}>()

const displayModel = computed({
  get: () => (model.value ?? 0) * 100,
  set: (value: number) => model.value = value / 100,
})
</script>

<template>
  <UInput
    v-model="displayModel"
    type="number"
    :icon="icon ? 'i-mdi-percent' : undefined"
    trailing
    inputmode="decimal"
    :min="0.00"
    :max="100.00"
    :step="0.01"
    v-bind="$attrs"
  />
</template>
