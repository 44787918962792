<script setup lang="ts">
import type { DropdownItem } from '#ui/types'

const props = defineProps<{
  note: Note
  dropdownItems?: DropdownItem[]
}>()

const { user } = useUserSession()
const { moduleId } = useModules()
</script>

<template>
  <!-- IMPORTANT: must use CSS variables and not e.g. `border-[toHexColor(note.userId)]`
  directly since TailwindCSS will not pick-up dynamic CSS colors outside of the SSR (on client) -->
  <div
    :id="`note-${note.id}`"
    class="h-min p-2 rounded-md bg-gray-100 dark:bg-gray-800"
    :style="{ '--dynamic-color': toHexColor(note.userId) }"
  >
    <div class="flex items-center justify-between text-xs py-1">
      <div class="flex-1 flex items-center gap-2">
        <UAvatar
          :title="note.user?.name"
          :src="note.user?.image"
          :alt="note.user?.name"
          size="xs"
          class="border border-[var(--dynamic-color)]"
          :style="{ '--dynamic-color': toHexColor(note.userId) }"
        />
        <span
          :title="note.user?.email"
          class="text-[var(--dynamic-color)] font-medium text-xs"
          :style="{ '--dynamic-color': toHexColor(note.userId) }"
        >
          {{ note.user?.name?.toUpperCase() ?? note.user?.email }}
        </span>
      </div>
      <div class="flex items-center gap-2">
        <ViewRendererDatetime
          :datetime="note.createdAt"
          exact
          display-hours
        />
        <Can
          v-if="dropdownItems?.length"
          :ability="update"
          :args="[moduleId]"
        >
          <UDropdown
            :items="dropdownItems"
            :popper="{ placement: 'auto-start' }"
            :ui="{ width: 'min-w-max' }"
          >
            <UButton
              icon="i-mdi-dots-horizontal"
              variant="ghost"
              size="2xs"
              square
            />
          </UDropdown>
        </Can>
      </div>
    </div>

    <!-- NOTE: could add e.g. `class="max-h-[500px] overflow-y-auto"` to limit card height -->
    <MarkdownRenderer :value="note.text" />
  </div>
</template>
