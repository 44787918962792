import { merge } from 'lodash-es'

export const getDefaultApikey = (data: Partial<Apikey>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  hashedApikey: createApikey(), // TODO: use custom endpoint POST /api/auth/apikeys instead to hash apiKey
  permission: 'admin',
}, data)

export const optionsApikeys = {
  permission: [
    {
      value: 'admin',
      label: 'Administación',
      description: 'Puede leer, escribir y eliminar registros en todos los módulos.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Escritura',
      description: 'Puede leer y escribir registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Lectura',
      description: 'Solo puede leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const relationsApikeys: Record<string, boolean> = {}
