import { createId, type Options } from '#netzo/utils/app/index'
import { Rfc } from '@nodecfdi/rfc'
import { merge } from 'lodash-es'

export const getDefaultBilling = (data: Partial<Account['billing']>) => merge({
  address: {},
}, data)

export const getDefaultBillingGenericRFC = (data: Partial<Account['billing']>) => merge({
  type: 'genericRFC',
  taxId: Rfc.RFC_GENERIC, // 'XAXX010101000',
  taxCountryCode: 'MX',
  legalName: 'PÚBLICO EN GENERAL',
  satFiscalRegime: '616',
  address: defaultLegalInformation!.address ?? {},
}, data)

export const getDefaultBillingForeignLegalEntity = (data: Partial<Account['billing']>) => merge({
  type: 'foreignLegalEntity',
  taxId: Rfc.RFC_FOREIGN, // 'XEXX010101000',
  satFiscalRegime: '609',
  address: {},
}, data)

export const getDefaultAccount = (data: Partial<Account>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  status: 'active',
  billing: getDefaultBillingGenericRFC({}),
  payment: merge(defaultPayment, data?.payment),
  tags: [],
  files: [],
}, data)

export const optionsAccounts = {
  type: [
    { value: 'customer', label: 'Cliente', description: 'Empresas a las que vendemos nuestros productos o servicios.', color: 'sky' },
    { value: 'partner', label: 'Partner', description: 'Empresas con las que tenemos una relación de colaboración. Pueden ser socios comerciales, proveedores de servicios, distribuidores, etc.', color: 'lime' },
    { value: 'supplier', label: 'Proveedor', description: 'Empresas que nos suministran productos o servicios.', color: 'yellow' },
    { value: 'other', label: 'Otro', description: 'Cualquier tipo de empresa que no encaje en las categorías anteriores.', color: 'fuchsia' },
  ],
  status: optionsSharedApp.statusResource,
  billing: {
    type: [
      { value: 'individual', label: 'Persona Fisica', description: 'Un individuo que emite o recibe facturas a título personal.', icon: 'i-mdi-account', color: 'sky' },
      { value: 'legalEntity', label: 'Persona Moral', description: 'Una entidad legal que emite o recibe facturas en nombre de una empresa o corporación.', icon: 'i-mdi-domain', color: 'lime' },
      { value: 'foreignLegalEntity', label: 'Persona Moral Extranjera', description: 'Una entidad legal extranjera que emite o recibe facturas en nombre de una empresa o corporación fuera del país.', icon: 'i-mdi-web', color: 'yellow' },
      { value: 'genericRFC', label: 'RFC Genérico', description: 'Un RFC genérico utilizado para operaciones que no requieren facturación personalizada.', icon: 'i-mdi-file-document', color: 'fuchsia' },
    ],
    paymentConditions: [
      { value: 'NET0', label: 'NET0 - Pago inmediato', description: 'Pago inmediato a la entrega del producto o servicio.', color: 'sky' },
      { value: 'NET7', label: 'NET7 - Pago en 7 días', description: 'Pago a realizarse dentro de 7 días a partir de la emisión de la factura.', color: 'sky' },
      { value: 'NET15', label: 'NET15 - Pago en 15 días', description: 'Pago a realizarse dentro de 15 días a partir de la emisión de la factura.', color: 'lime' },
      { value: 'NET30', label: 'NET30 - Pago en 30 días', description: 'Pago a realizarse dentro de 30 días a partir de la emisión de la factura.', color: 'indigo' },
      { value: 'NET45', label: 'NET45 - Pago en 45 días', description: 'Pago a realizarse dentro de 45 días a partir de la emisión de la factura.', color: 'orange' },
      { value: 'NET60', label: 'NET60 - Pago en 60 días', description: 'Pago a realizarse dentro de 60 días a partir de la emisión de la factura.', color: 'yellow' },
      { value: 'NET90', label: 'NET90 - Pago en 90 días', description: 'Pago a realizarse dentro de 90 días a partir de la emisión de la factura.', color: 'amber' },
      { value: 'COD', label: 'COD - Pago contra entrega', description: 'Pago realizado en el momento de la entrega del producto. Adecuado para pedidos con entrega física.', color: 'green' },
      { value: 'PREPAY', label: 'PREPAY - Pago anticipado', description: 'Pago total o parcial realizado antes del envío del producto. Asegura la confirmación del pedido.', color: 'emerald' },
      { value: 'INSTALLMENTS', label: 'INSTALLMENTS - Pago en plazos', description: 'Pago dividido en varias cuotas, según un plan acordado. Ideal para venta grandes o personalizadas.', color: 'teal' },
      { value: 'EOM', label: 'EOM - Pago fin de mes', description: 'Pago realizado al final del mes en el que se emite la factura. Útil para la conciliación de cuentas mensuales.', color: 'violet' },
      { value: 'EARLY_PAYMENT_DISCOUNT', label: 'Descuento por pronto pago', description: 'Descuento ofrecido si el pago se realiza dentro de un plazo específico, por ejemplo, 2/10 Net 30.', color: 'cyan' },
      { value: 'DEFERRED_PAYMENT', label: 'Pago diferido', description: 'Pago realizado en una fecha futura específica, más allá de los plazos estándar. Flexibilidad adicional para el cliente.', color: 'fuchsia' },
    ],
  },
} satisfies Options

export const relationsAccounts: Record<string, boolean> = {
  activities: true,
  branches: true,
  contacts: true,
  deals: true,
  industry: true,
  inquiries: true,
  quotes: true,
  salesorders: true,
  services: true,
  transactions: true,
  user: true,
}
