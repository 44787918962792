import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

type EmailAddress = {
  label: string
  value: string
}

export type Email = {
  templateId?: string
  to: EmailAddress[]
  cc?: EmailAddress[]
  bcc?: EmailAddress[]
  subject: string
  body: string
}

export const getDefaultEmail = (data: Partial<Email>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  to: [],
  cc: [],
  bcc: [],
  subject: '',
  body: '',
}, data)

export const optionsEmails = {
  templateId: [
    {
      value: 'quick-reply',
      label: 'Respuesta Rápida',
      subject: (data: Email) => data.subject,
      body: (data: Email) => {
        return `Hola,\n\nGracias por contactarnos. En breve nos pondremos en contacto contigo para hacer seguimiento.\n\nSaludos,\n\nNetzo`
      },
    },
    {
      value: 'follow-up',
      label: 'Seguimiento de Cotización',
      subject: (data: Email) => `Seguimiento a la cotización XXXXXXXXX`,
      body: (data: Email) => {
        return `Hola XXXXXXXXX,\n\nQueríamos hacer seguimiento a la cotización enviada el XXXXXXXXX. Por favor, no dudes en contactarnos si tienes alguna pregunta o necesitas ajustar algún detalle.\n\nQuedamos atentos a tus comentarios.\n\nSaludos cordiales,\n\nNetzo`
      },
    },
    {
      value: 'reminder',
      label: 'Recordatorio de Pago',
      subject: (data: Email) => `Recordatorio de Pago para la Factura XXXXXXXXX`,
      body: (data: Email) => {
        return `Hola XXXXXXXXX,\n\nTe recordamos que la factura XXXXXXXXX tiene una fecha de vencimiento el XXXXXXXXX. Te agradeceríamos realizar el pago a la brevedad para evitar recargos adicionales.\n\nSi necesitas alguna aclaración, estamos a tu disposición.\n\nSaludos,\n\nNetzo`
      },
    },
    {
      value: 'welcome',
      label: 'Bienvenida a Nuevos Clientes',
      subject: (data: Email) => `¡Bienvenido/a a Netzo, XXXXXXXXX!`,
      body: (data: Email) => {
        return `Hola XXXXXXXXX,\n\n¡Gracias por unirte a Netzo! Estamos emocionados de comenzar a trabajar contigo. A continuación, encontrarás algunos recursos iniciales y contactos clave para ayudarte a comenzar.\n\nQuedamos a tu disposición para cualquier duda.\n\nSaludos cordiales,\n\nNetzo`
      },
    },
    {
      value: 'thank-you',
      label: 'Agradecimiento por la Compra',
      subject: (data: Email) => `Gracias por tu compra, XXXXXXXXX`,
      body: (data: Email) => {
        return `Hola XXXXXXXXX,\n\nQueremos agradecerte por tu reciente compra de nuestros servicios. Nos complace contar contigo como cliente y estamos aquí para asegurarnos de que tu experiencia sea excelente.\n\nPara cualquier duda o asistencia, por favor contáctanos.\n\nSaludos cordiales,\n\nNetzo`
      },
    },
    {
      value: 'renewal-reminder',
      label: 'Recordatorio de Renovación de Contrato',
      subject: (data: Email) => `Recordatorio de Renovación de Contrato`,
      body: (data: Email) => {
        return `Hola XXXXXXXXX,\n\nQueremos recordarte que el contrato de servicio para XXXXXXXXX está próximo a vencer el XXXXXXXXX. Si estás interesado en renovar o realizar algún cambio en el servicio, por favor háznoslo saber.\n\nEstamos a tu disposición para lo que necesites.\n\nSaludos,\n\nNetzo`
      },
    },
  ],
} satisfies Options

// export const relationsEmails: Record<string, boolean> = {}
