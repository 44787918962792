import { merge } from 'lodash-es'

export const getDefaultUsernotification = (data: Partial<Usernotification>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  type: 'info',
  name: '',
  description: '',
  optionsLocal: {},
  optionsPush: {},
}, data)

export const optionsUsernotifications = {} satisfies Options

export const relationsUsernotifications: Record<string, boolean> = {
  notification: true,
  user: true,
}
