import { createId, type Options } from '#netzo/utils/app/index'
import { merge } from 'lodash-es'

export const getDefaultProductionorderitem = (data: Partial<Productionorderitem>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  stage: 'new',
  status: 'pending',
  priority: '2',
  qualityAssurance: {
    approvedBy: [],
    isDatetime: false,
    approvedAt: null,
    items: [],
  },
  tasks: [],
  tags: [],
  files: [],
}, data)

export const optionsProductionorderitem = {
  type: optionsProductionorders.type,
  stage: optionsProductionorders.stage,
  status: optionsSharedApp.statusProcess,
  priority: optionsSharedApp.priority,
} satisfies Options

export const relationsProductionorderitems: Record<string, boolean> = {
  productitem: true,
  salesorderitem: true,
  user: true,
  productionorder: true,
}
