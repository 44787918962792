import type { BlobObject } from '@nuxthub/core'

export const getFileProps = (file: BlobObject, label?: string) => {
  const { pathname, contentType } = file ?? {}
  label = label ?? pathname?.split('/').pop() ?? 'Archivo'
  if (contentType?.startsWith('image/')) return { icon: 'i-mdi-image', label, ui: { icon: { base: 'text-yellow-500' } } }
  else if (contentType?.startsWith('video/')) return { icon: 'i-mdi-video', label, ui: { icon: { base: 'text-yellow-500' } } }
  else if (contentType?.startsWith('audio/')) return { icon: 'i-mdi-volume', label, ui: { icon: { base: 'text-purple-500' } } }
  else if (contentType?.startsWith('text/') || contentType?.startsWith('application/')) {
    if (contentType?.includes('csv')) return { icon: 'i-mdi-file-excel', label, ui: { icon: { base: 'text-blue-500' } } }
    else if (contentType?.includes('html')) return { icon: 'i-mdi-language-html5', label, ui: { icon: { base: 'text-yellow-500' } } }
    else if (contentType?.includes('css')) return { icon: 'i-mdi-language-css3', label, ui: { icon: { base: 'text-blue-500' } } }
    else if (contentType?.includes('javascript')) return { icon: 'i-mdi-language-javascript', label, ui: { icon: { base: 'text-purple-500' } } }
    else if (contentType?.includes('pdf')) return { icon: 'i-mdi-file-pdf', label, ui: { icon: { base: 'text-red-500' } } }
    else if (contentType?.includes('xml')) return { icon: 'i-mdi-file-xml', label, ui: { icon: { base: 'text-blue-500' } } }
    else if (contentType?.includes('json')) return { icon: 'i-mdi-code-json', label, ui: { icon: { base: 'text-blue-500' } } }
    else if (contentType?.includes('zip')) return { icon: 'i-mdi-folder-zip', label, ui: { icon: { base: 'text-yellow-500' } } }
    else if (contentType?.includes('plain')) return { icon: 'i-mdi-file-document', label, ui: { base: 'text-gray-500' } }
    else return { icon: 'i-mdi-file', label, ui: { base: 'text-gray-500' } }
  }
  else return { icon: 'i-mdi-file', label, ui: { base: 'text-gray-500' } }
}
