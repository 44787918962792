import { merge } from 'lodash-es'

export const getDefaultUser = (data: Partial<User>) => merge({
  id: createId(), // manually generate before POSTing (used for `uid`, filenames, etc...)
  modules: {},
}, data)

export const optionsUsers = {
  emailVerified: [
    { value: true, label: 'Verificado', icon: 'i-mdi-check-circle', color: 'green' },
    { value: false, label: 'No verificado', icon: 'i-mdi-alert-circle', color: 'red' },
  ],
  banned: [
    { value: true, label: 'Bloqueado', icon: 'i-mdi-block-helper', color: 'red' },
    { value: false, label: 'Activo', icon: 'i-mdi-check', color: 'green' },
  ],
  modules: MODULES.map(toOption),
  modulesRole: [
    {
      value: 'admin',
      label: 'Administrador',
      description: 'Tiene acceso total a todos los módulos y funciones.',
      icon: 'i-mdi-shield-account',
      color: 'blue',
    },
    {
      value: 'edit',
      label: 'Editor',
      description: 'Puede editar y crear registros en todos los módulos.',
      icon: 'i-mdi-pencil',
      color: 'red',
    },
    {
      value: 'view',
      label: 'Visualizador',
      description: 'Solo puede ver y leer registros en todos los módulos.',
      icon: 'i-mdi-eye',
      color: 'green',
    },
  ],
} satisfies Options

export const relationsUsers: Record<string, boolean> = {
  // NOTE: user relations explicitly disabled for security/performance
  // also since they are never  needed in the /admin/auth/users page
}
