import { AppApikeysSlideover } from '#components'
import { z } from '#imports'
import { optionsApikeys } from '#netzo/utils/app/apikeys'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppApikeysSlideoverProps = ComponentProps<typeof AppApikeysSlideover>

export type ApikeysUtils = ReturnType<typeof useApikeysUtils>

// view:

export const VIEW_SETTINGS_APIKEYS: ViewSettings<Apikey> = {
  tableName: 'apikeys',
  views: optionsShared.views.filter(view => ['table'].includes(view.value)),
  selectedView: 'table',
  compact: false,
  columns: [
    {
      key: 'actions',
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
    },
    {
      key: 'description',
      label: 'Descripción',
    },
    {
      key: 'permission',
      label: 'Permiso',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'hashedApikey',
      label: 'Clave API',
    },
    ...COLUMNS_METADATA,
  ],
}

export const useApikeys = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
  } = useRecords<Apikey>('apikeys')

  // TODO: use custom endpoint POST /api/auth/apikeys instead of onSubmitPatch() to hash apiKey
  // const result = await $fetch<Apikey>('/api/auth/apikeys', { method: 'POST', body: event.data })
  const slideoverOpenCreate = (props: AppApikeysSlideoverProps) => {
    return new Promise<Apikey>((resolve, reject) => {
      const data = getDefaultApikey(props.data)
      slideover.open(AppApikeysSlideover, {
        title: 'Crear clave API',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const slideoverOpenEdit = (props: AppApikeysSlideoverProps) => {
    return new Promise<Apikey>((resolve, reject) => {
      const data = getDefaultApikey(props.data)
      slideover.open(AppApikeysSlideover, {
        title: 'Editar clave API',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            slideover.close()
            resolve(result)
          }
          catch (error) {
            slideover.close()
            reject(error)
          }
        }),
      })
    })
  }

  const schema = z.object({
    name: z.string(),
    permission: z.string(),
  }).required()

  // actions:

  const getDropdownItems = (row: Apikey, refresh: () => Promise<void>, utils: ApikeysUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      {
        label: 'Editar',
        icon: 'i-mdi-pencil',
        click: () => {
          slideoverOpenEdit({
            data: row,
            onSubmit: async (event) => {
              await onSubmitUpdate(event.data)
              slideover.close()
              await refresh()
            },
          })
        },
      },
      {
        label: 'Copiar ID',
        icon: 'i-mdi-content-copy',
        click: () => { copyToClipboard(row.id) },
      },
      {
        label: row.deletedAt ? 'Desarchivar' : 'Archivar',
        icon: row.deletedAt ? 'i-mdi-archive-arrow-up' : 'i-mdi-archive-arrow-down',
        click: async () => {
          const deletedAt = row?.deletedAt ? null : new Date().toISOString()
          await onSubmitUpdate({ ...row, deletedAt }) // soft delete
          await refresh()
        },
      },
    ],
    [
      {
        label: 'Abrir notas',
        icon: 'i-mdi-text',
        click: () => {
          const notes$ = useNotes()
          notes$.slideoverOpen({ data: { entityId: row.id, entity: 'apikeys', text: '' } })
        },
      },
    ],
    [
      {
        label: 'Información',
        icon: 'i-mdi-information',
        click: () => { slideoverOpenInformation('apikeys', row) },
      },
    ],
    [
      {
        label: 'Eliminar',
        icon: 'i-mdi-delete',
        iconClass: 'bg-red-500',
        labelClass: 'text-red-500',
        click: () => {
          modalOpenDelete({
            onSubmit: async (event) => {
              await onSubmitDelete([row])
              modal.close()
              await refresh()
            },
          })
        },
      },
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    modalOpenDelete,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useApikeysUtils = () => {
  const options = {
    ...optionsApikeys,
  }

  const maps = {
    permission: toMapByKey(optionsApikeys.permission, 'value'),
  }

  return { options, maps }
}
