<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<Account>
  onSubmit: (event: FormSubmitEvent<Partial<Account>>) => void
  title?: string
  disabledFields?: (keyof Account | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<Account>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  schema,
  getDropdownItems,
} = useAccounts()

const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const $industries = await useFetch<Industry[]>('/api/db/industries', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})

const utils = useAccountsUtils({ $contacts, $users, $industries })

const industries$ = useIndustries()

const items = computed<AccordionItem[]>(() => {
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.accounts,
      defaultOpen: true,
    },
    {
      slot: 'billing',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y pago',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Información adicional',
      icon: 'i-mdi-dots-horizontal',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateBillingType = (type: Account['billing']['type']) => {
  state.value.billing = utils.getters[type]({
    type,
    satPaymentMethod: state.value.payment.satPaymentMethod,
    satPaymentForm: state.value.payment.satPaymentForm,
  }) ?? state.value.billing
}

const loading = ref(false)

const onSubmitAccount = async (event: FormSubmitEvent<Partial<Account>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>

    <UForm
      id="form.accounts"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitAccount"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="optionsAccounts.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="optionsAccounts.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Dueño"
            name="userId"
            :required="isRequired(schema, 'userId')"
          >
            <SelectMenuBase
              v-model="state.userId"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userId')"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.type === 'customer'"
            label="Industria"
            name="industryId"
            :required="isRequired(schema, 'industryId')"
          >
            <UButtonGroup class="flex">
              <SelectMenuBase
                v-model="state.industryId"
                :options="utils.options.industryId.value"
                :disabled="disabledFields?.includes('industryId')"
                class="flex-1"
              />
              <UButton
                icon="i-mdi-plus"
                :disabled="disabledFields?.includes('industryId')"
                @click="openNested(() => industries$.slideoverOpenCreate({
                  data: {},
                  onSubmit: async (event) => {
                    const industry = await industries$.onSubmitCreate(event.data)
                    state.industryId = industry!.id
                    await $industries.refresh()
                    await openNested(() => slideoverOpenEdit({ ...props, data: state }))
                  },
                }))"
              />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Dominio"
            name="domain"
            :required="isRequired(schema, 'domain')"
          >
            <UInput
              v-model="state.domain"
              type="url"
              :disabled="disabledFields?.includes('domain')"
              help="Terminación del correo electrónico (e.g. 'netzo.dev')"
            />
          </UFormGroup>

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `accounts/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              :creatable="true"
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              accept="*"
              :query="{ prefix: `accounts/${state.id}/files` }"
            />
          </UFormGroup>
        </template>

        <template #billing>
          <UFormGroup
            label="Tipo de Entidad"
            name="billing.type"
            :required="isRequired(schema, 'billing.type')"
          >
            <SelectMenuBaseInfo
              v-model="state.billing!.type"
              :options="optionsAccounts.billing.type"
              :disabled="disabledFields?.includes('billing.type')"
              @update:model-value="onUpdateBillingType"
            />
          </UFormGroup>

          <UFormGroup
            v-if="state.billing?.type === 'legalEntity'"
            label="Constancia de Situación Fiscal"
            name="fileCsfPdf"
            :required="isRequired(schema, 'fileCsfPdf')"
          >
            <InputFile
              v-model="state.fileCsfPdf"
              accept="application/pdf"
              :disabled="disabledFields?.includes('fileCsfPdf')"
              :query="{ prefix: `accounts/${state.id}` }"
            />
          </UFormGroup>

          <UFormGroup
            label="Razón Social"
            name="billing.legalName"
            :required="isRequired(schema, 'billing.legalName')"
          >
            <UInput
              v-model="state.billing!.legalName"
              :disabled="disabledFields?.includes('billing.legalName') || ['genericRFC'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="RFC"
            name="billing.taxId"
            :required="isRequired(schema, 'billing.taxId')"
          >
            <UInput
              v-model="state.billing!.taxId"
              :disabled="disabledFields?.includes('billing.taxId') || ['genericRFC', 'foreignLegalEntity'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="Código de País"
            name="billing.taxCountryCode"
            :required="isRequired(schema, 'billing.taxCountryCode')"
          >
            <SelectMenuBase
              v-model="state.billing!.taxCountryCode"
              :options="optionsShared.countryCode"
              :disabled="disabledFields?.includes('billing.taxCountryCode') || ['genericRFC'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="Régimen Fiscal"
            name="billing.satFiscalRegime"
            :required="isRequired(schema, 'billing.satFiscalRegime')"
          >
            <SelectMenuBase
              v-model="state.billing!.satFiscalRegime"
              :options="optionsSAT.fiscalRegime"
              :disabled="disabledFields?.includes('billing.satFiscalRegime') || ['genericRFC', 'foreignLegalEntity'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <FieldsetAddress
            v-model="state.billing!.address"
            label="Domicilio Fiscal"
            :required="isRequired(schema, 'billing.address')"
            :disabled="disabledFields?.includes('billing.address') || ['genericRFC'].includes(state.billing?.type)"
            class="col-span-2"
          />
        </template>

        <template #payment>
          <InputPayment
            v-model="state.payment"
            :schema="schema"
            :disabled-fields="disabledFields"
          />

          <Alert
            type="info"
            description="Rellenar condiciones y método de pago para agilizar la creación de cotizaciones."
          />
        </template>

        <template #data>
          <AppAccountsFieldsetCustomData v-model="state" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.accounts"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
