<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'

const props = defineProps<{
  data: Partial<User>
  onSubmit: (event: FormSubmitEvent<Partial<User>>) => void
  title?: string
  disabledFields?: (keyof User | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()

const inert = computed(() => props.readonly || props.data?.$inmutable || !['admin', 'edit'].includes(userModule.value?.role))

const state = ref<Partial<User>>(props.data)

const {
  onSubmitCreate,
  onSubmitUpdate,
  onSubmitDelete,
  onSubmitUpdateMultiple,
  slideover,
  slideoverOpenCreate,
  slideoverOpenEdit,
  slideoverOpenInformation,
  slideoverOpenFilePreview,
  modal,
  modalOpenDelete,
  modalOpenDeleteUser,
  schema,
  getDropdownItems,
} = useUsers()

const items: AccordionItem[] = [
  {
    slot: 'general',
    label: 'General',
    icon: 'i-mdi-information',
    defaultOpen: true,
  },
  {
    slot: 'modules',
    label: 'Acceso a Módulos',
    icon: 'i-mdi-hexagon-multiple',
    defaultOpen: true,
  },
]

const loading = ref(false)

const onSubmitUser = async (event: FormSubmitEvent<Partial<User>>) => {
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[30vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.users"
      :schema="schema"
      :validate-on="['submit']"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitUser"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(schema, 'name')"
          >
            <UInput
              v-model="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Email"
            name="email"
            :required="isRequired(schema, 'email')"
          >
            <UInput
              v-model="state.email"
              type="email"
              autocomplete="email"
              :disabled="disabledFields?.includes('email')"
            />
          </UFormGroup>

          <!-- <UFormGroup label="Contraseña" name="password" required>
            <InputPassword v-model="state.password" autocomplete="new-password" />
          </UFormGroup> -->

          <!-- <UFormGroup
            label="Módulos"
            name="modules"
            :required="isRequired(schema, 'modules')"
          >
            <SelectMenuBase
              v-model="state.modules"
              :options="optionsUsers.modules"
              :disabled="disabledFields?.includes('modules')"
            />
          </UFormGroup> -->

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              :query="{ prefix: `users/${state.id}` }"
            />
          </UFormGroup>
        </template>

        <template #modules>
          <div
            v-for="(mod, i) in optionsUsers.modules"
            :key="`users-module-${mod.value}-${i}`"
            :name="`modules.${mod.value}.enabled`"
            class="grid grid-cols-[auto,auto,140px] gap-2 items-center"
          >
            <div>
              {{ mod.label }}
            </div>
            <div class="text-right mr-3">
              <UToggle
                v-model="state.modules[mod.value].enabled"
                label="Activado"
                size="md"
                :disabled="disabledFields?.includes(`modules.${mod.value}.enabled`)"
                :title="state.modules[mod.value]?.enabled ? 'Acceso al módulo activado' : 'Acceso al módulo desactivado'"
              />
            </div>
            <div>
              <SelectMenuBase
                v-model="state.modules[mod.value].role"
                label="Rol"
                :options="optionsUsers.modulesRole"
                :disabled="!state.modules[mod.value]?.enabled || disabledFields?.includes(`modules.${mod.value}.enabled`)"
              />
            </div>
          </div>
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          label="Cancelar"
          variant="outline"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.users"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
