<script setup lang="ts">
import { useSortable } from '@vueuse/integrations/useSortable'

const model = defineModel<{ resolved: boolean, text: string }[]>({ default: () => [] })

type Task = { resolved: boolean, text: string }

const props = defineProps<{
  placeholder?: string
}>()

// Function to create a task item with resolved state
const toTask = (task: Task) => ({ resolved: task.resolved, text: task.text, id: Math.random().toString(36).substr(2, 9) })

const tasks = ref(model.value?.map(toTask) ?? [])

const removeAtIndex = (index: number) => {
  tasks.value = tasks.value.filter((_, i) => i !== index)
}

watch(tasks, (value) => {
  model.value = value.map((value) => {
    return { resolved: value.resolved, text: value.text }
  })
}, { deep: true })

const onInputLast = async (event) => {
  const value = event.target?.value?.trim()
  if (value) {
    tasks.value.push(toTask(value)) // Add the new entry
    await nextTick() // Wait for the DOM to update
    const index = tasks.value.length - 1 // Get the index of the new entry
    document.getElementById(`entries-text-${index}`)?.focus() // Focus on the new entry
    document.getElementById('last-entry')!.value = '' // Clear the last entry input
  }
}
// sortable:
const el = ref<HTMLElement | null>(null)
useSortable(el, tasks, { handle: '.handle', animation: 100 })
</script>

<template>
  <fieldset>
    <div ref="el">
      <div
        v-for="(task, index) in tasks"
        :key="`entry-${task.id}`"
        class="flex mb-2 text-sm"
      >
        <UButton
          variant="ghost"
          size="sm"
          :icon="!task.resolved ? 'i-mdi-checkbox-blank-outline' : 'i-mdi-checkbox-marked'"
          @click="() => task.resolved = !task.resolved"
        />
        <UTextarea
          :id="`entries-text-${index}`"
          v-model="task.text"
          resize
          :rows="2"
          class="flex-1 ml-2"
        />
        <div class="flex flex-col items-center">
          <UButton
            variant="ghost"
            icon="i-mdi-drag"
            size="xs"
            color="gray"
            class="handle cursor-move"
          />
          <UButton
            variant="ghost"
            color="red"
            size="xs"
            icon="i-mdi-close"
            @click="removeAtIndex(index)"
          />
        </div>
      </div>
    </div>
    <UTextarea
      id="last-entry"
      :rows="1"
      resize
      :placeholder="placeholder ?? 'Ingresa una nueva tarea'"
      class="col-span-2"
      @input="onInputLast"
    />
  </fieldset>
</template>
