<script setup lang="ts">
import type { DropdownItem } from '#ui/types'
import type { BlobObject } from '@nuxthub/core'
import { saveAs } from 'file-saver'
import JSZip from 'jszip'

type Utils = {
  options: Record<string, Option[] | ComputedRef<Option[]>>
  maps: Record<string, Record<string, Option[] | Record<string, Record<string, Option[]>>>>
  getters?: (data: unknown) => unknown
}

const props = defineProps<{
  data: { id: string, files: BlobObject[], [key: string]: unknown }
  refresh: () => Promise<void>
  title?: string
  utils: Utils
}>()

const getDropdownItems = (row: unknown, refresh: () => Promise<void>, utils: Utils): DropdownItem[] => []

// jszip:

const zip = new JSZip()

const downloadAll = async () => {
  const promises = props.data.files.map(async (file) => {
    const response = await fetch(`/api/blob/${file.pathname}`)
    const blob = await response.blob()
    zip.file(file.pathname.split('/').pop(), blob)
  })
  await Promise.all(promises)
  const content = await zip.generateAsync({ type: 'blob' })
  saveAs(content, `archivos-${props.data.id}.zip`)
}
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ body: { base: 'px-0' }, footer: { padding: 'pt-0' } }"
  >
    <div class="flex flex-col h-full gap-3">
      <ViewPlaceholder
        view="cards"
        :rows="data.files"
        icon="i-mdi-file"
        label="No hay archivos para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3 px-4">
          <FilesCardFile
            v-for="(file, i) in data.files"
            :key="`file-${file.pathname}-${i}`"
            :file="file"
            :dropdown-items="getDropdownItems(file, refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </div>

    <template #footer>
      <UButton block @click="downloadAll">
        Descargar {{ data.files.length }} {{ data.files?.length > 1 ? 'archivos' : 'archivo' }}
      </UButton>
    </template>
  </UDashboardSlideover>
</template>
